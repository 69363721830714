import { Classes, Tag, Text } from "@blueprintjs/core";
import { Row } from "../Layout/layouts";
import { ReactComponent as ExtrudeEx } from '../components/svg/3d_extrude_ex.svg';


export const SemifabExtrudeDialogContent: JSX.Element = (
    <div className={Classes.DIALOG_BODY}>
        <Text>
            FabuBlox can offer an extruded "3-D" view of through the  StackSimulator. Please note that this is not a true 3-D simulation. It simply extends the image into the page to offer a 3-D extruded view.
        </Text>

        <Text style={{marginTop: '20pt', marginBottom:'20pt'}}>
            At the moment, there is no control over the viewing angle or extruded depth, but we may support these features in the future. 
        </Text>

        <Row>
            <ExtrudeEx style={{width: '90%', margin: 'auto'}} />
        </Row>
    </div>
);
import { useQuery } from 'react-query';
import { BASE_FABUBLOX_API_URL } from '../../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { Group } from '../../__generated__/Group';

async function fetchGroups(endpoint: string, getAccessTokenSilently: () => void): Promise<Group[]> {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/groups/${endpoint}`, {
        headers: { 'Authorization': `Bearer ${token}` },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch groups from endpoint: ${endpoint}`);
    }
    return response.json();
}

export function useMyGroups() {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Group[], Error>('mygroups', () => fetchGroups('mygroups', getAccessTokenSilently) ,{
        staleTime: 5 * 60 * 1000, // 5 minutes
    });
}

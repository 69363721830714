import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Blox, CreateBloxRequest, UpdateBloxRequest } from '../../__generated__/Blox';
import { BASE_FABUBLOX_API_URL } from '../../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { Intent } from '@blueprintjs/core';
import { showToast } from '../..';

async function fetchBloxes(endpoint: string, getAccessTokenSilently: () => void): Promise<Blox[]> {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/bloxes/${endpoint}`, {
        headers: { 'Authorization': `Bearer ${token}` },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch bloxes from endpoint: ${endpoint}`);
    }
    return response.json();
}

export function useOwnedBloxes() {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Blox[], Error>('ownedBloxes', () => fetchBloxes('owned', getAccessTokenSilently));
}

export function useSharedBloxes() {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Blox[], Error>('sharedBloxes', () => fetchBloxes('shared', getAccessTokenSilently));
}

async function updateBlox(bloxId: string, data: UpdateBloxRequest, getAccessTokenSilently: () => Promise<string>) {
    const token = await getAccessTokenSilently();
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
    };
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/blox/update/${bloxId}`, requestOptions);
    if (!response.ok) {
        throw new Error(`Failed to update blox: ${bloxId}`);
    }
}

export function useUpdateBlox() {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    return useMutation(
        async ({ bloxId, data }: { bloxId: string; data: UpdateBloxRequest }) => 
            updateBlox(bloxId, data, getAccessTokenSilently),
        {
            onSuccess: (_data, _variables, _context) => {
                queryClient.invalidateQueries('ownedBloxes');
            },
            onError: (error: any, _variables, _context) => {
                showToast({
                    message: error instanceof Error ? error.message : "An error occurred",
                    intent: Intent.DANGER,
                    timeout: 3000
                });
                console.error(`Update failed with ${error.message}`);
            },
        }
    );
}

async function createBlox(data: CreateBloxRequest, getAccessTokenSilently: () => Promise<string>) {
    const token = await getAccessTokenSilently();
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
    };
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/blox/create/`, requestOptions);
    if (!response.ok) {
        throw new Error('Save Failed');
    }
    return response.json();
}

export function useCreateBlox() {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    return useMutation(
        (bloxData: CreateBloxRequest) => createBlox(bloxData, getAccessTokenSilently),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('ownedBloxes');
                
                showToast({
                    message: "Blox created successfully",
                    intent: Intent.SUCCESS,
                    timeout: 3000
                });
            },
            onError: (error: any) => {
                showToast({
                    message: error instanceof Error ? error.message : "An error occurred",
                    intent: Intent.DANGER,
                    timeout: 3000
                });
                console.error(`Create failed with ${error.message}`);
            },
        }
    );
}

async function deleteBlox(id: string, getAccessTokenSilently: () => Promise<string>) {
    const token = await getAccessTokenSilently();
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    };
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/blox/delete/${id}`, requestOptions);
    if (!response.ok) {
        throw new Error(`Failed to delete blox: ${id}`);
    }
}

export function useDeleteBlox() {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    
    return useMutation(
        (id: string) => deleteBlox(id, getAccessTokenSilently),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('ownedBloxes');
                
                showToast({
                    message: "Blox deleted successfully",
                    intent: Intent.SUCCESS,
                    timeout: 3000
                });
            },
            onError: (error: any) => {
                // Show error toast
                showToast({
                    message: error instanceof Error ? error.message : "An error occurred",
                    intent: Intent.DANGER,
                    timeout: 3000
                });
                console.error(`Delete failed with ${error.message}`);
            },
        }
    );
}

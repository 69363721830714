import { useEffect } from "react";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import './App.css';
import './index.css';
import "normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import { Column } from "./Layout/layouts";
import { FabuRoutes } from "./FabuRoutes";
import { SvgServiceProvider } from "./hooks/state/svg-service-provider";
import { UnsavedChangesProvider } from "./hooks/state/unsaved-changes-provider";
import { ParameterContextProvider } from "./hooks/state/parameter-context";
import { useFabuStateProvider } from "./hooks/state/fabu-state-provider";
import { useQueryClient } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchProcesses } from "./hooks/DataFetching/use-fetch-process";
import * as Sentry from "@sentry/react";

const App = () => {
  useEffect(() => {
    document.title = "FabuBlox"
  }, []);

  const { user } = useAuth0();
  useEffect(() => {
    if (user?.email && user?.sub) {
      Sentry.setUser({
        email: user.email,
        id: user.sub
      });
    }
  }, [user])

  const fabuState = useFabuStateProvider();

  const queryClient = useQueryClient();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) return;
    const prefetchPublicProcesses = async () => {
      await queryClient.prefetchQuery('publicProcesses', () => fetchProcesses('public', getAccessTokenSilently));
    };

    prefetchPublicProcesses();
  }, [queryClient, getAccessTokenSilently, isAuthenticated]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Column style={{ height: "100%" }}>
          <ParameterContextProvider providers={fabuState}>
            <UnsavedChangesProvider>
              <SvgServiceProvider>
                <FabuRoutes />
              </SvgServiceProvider>
            </UnsavedChangesProvider>
          </ParameterContextProvider>
      </Column>
    </DndProvider>
  );
};

export default App;

import { AllBloxes } from "../Data/BloxSchema/base-blox";

export function setProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]) {
    obj[key] = value;
  }

export function getProperty<T, K extends keyof T>(obj: T, key: K) {
    return obj[key]; // Inferred type is T[K]
}

export function deleteProperty<T, K extends keyof T>(obj: T, key: K) {
    delete obj[key];
}

export function hasKey<O extends object>(obj: O, key: PropertyKey): key is keyof O {
    return key in obj
}

export function getValue(property: string, blox: AllBloxes){
    if (blox && hasKey(blox, property))
        return getProperty(blox, property);
    else
        return;
}

export function getCustomValue(id: string, blox: AllBloxes): [string, string]{
    if (blox && blox.customFields && hasKey(blox.customFields, id))
        return getProperty(blox.customFields, id);
    else
        return ["", ""];
}

export function getLayerValue(layerIndex:number, property: string, blox: AllBloxes){
    if (blox && blox.layers && hasKey(blox.layers[layerIndex], property))
        return getProperty(blox.layers[layerIndex], property);
    else
        return;
}
import { useCallback } from "react";
import { useFabuState } from "../../hooks/state/use-fabu-state";
import { v4 as uuidv4 } from 'uuid';
import { BloxTypes } from "../../Data/BloxSchema/base-blox";
import { getNewBlox } from "../../hooks/new-blox-data";

export function useClearProcess() {
    const [, setProcessBloxes] = useFabuState('processBloxes');
    const [, setProcessId] = useFabuState('processId');
    const [, setProcessName] = useFabuState('processName');
    const [, setProcessUsername] = useFabuState('processUsername');
    const [, setProcessIsPrivate] = useFabuState('processIsPrivate');
    const [, setProcessIsReadOnly] = useFabuState('processIsReadOnly');
    const [, setProcessAdditionalOwners] = useFabuState('processAdditionalOwners');
    const [, setProcessDesc] = useFabuState('processDesc');
    const [, setProcessReference] = useFabuState('processReference');
    const [, setProcessGroups] = useFabuState('processGroups');
    const [, setProcessSections] = useFabuState('processSections');
    const startBlox = getNewBlox(BloxTypes.StartBlox, 1)

    return useCallback(() => {
        setProcessBloxes([startBlox]);
        setProcessIsPrivate(true);
        setProcessIsReadOnly(false);
        setProcessName('');
        setProcessUsername('');
        setProcessId('');
        setProcessAdditionalOwners([]);
        setProcessDesc('');
        setProcessReference('');
        setProcessGroups([]);
        setProcessSections([{
            sectionId: uuidv4(),
            bloxIds: [startBlox].map(blox => blox.id)
        }]);
    }, []);
}

import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";


export interface IonMilling extends BaseBlox {
    etchDepth: number | null;
    etchDepthUnit: Units | null;
    gas: string | null;
    gasFlow: string | null;
    etchTime: number | null;
    etchTimeUnit: Units | null;
    etchRate: number | null;
    etchRateUnit: Units | null;
    etchAngle: number | null;
    etchAngleUnit: Units | null;
    pressure: number | null;
    pressureUnit: Units | null;

    layerLabelsToEtch: string[] | null;
    percentToEtch: number | null;

    sidewallAngle: number | null;
    sidewallAngleUnit: Units | null;
}

export const ionMillingDisplay: DisplayMap = {
    // DISPLAY
    layerLabelsToEtch: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Etch",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    percentToEtch: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Etch Depth",
        order: 1,
        tabs: [Tab.DISPLAY],
        defaultValue: 100,
        stepSize: 2
    },

    // EXPERIMENTAL
    etchDepth: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Depth",
        placeholder: "Enter etch depth",
        isNumber: true,
        order: 3,
        units: [Units.ANG,Units.NM,Units.MICRON,Units.MM],
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    gas: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Gas Composition",
        placeholder: "e.g. He/O2",
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    gasFlow: {
        fieldType: DisplayFieldTypes.Input,
        label: "Gas Flows and Ratios",
        placeholder: "e.g. 2/1 or 20/10 sccm",
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },
    etchTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Time",
        isNumber: true,
        placeholder: "Enter etch time",
        order: 6,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        tabs: [Tab.EXPERIMENTAL],
    },
    etchRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Rate",
        placeholder: "Enter etch rate",
        isNumber: true,
        order: 7,
        units: [Units.ANGPERSEC,Units.NMPERSEC,Units.NMPERMIN,Units.UMPERMIN,Units.UMPERHOUR],
        tabs: [Tab.EXPERIMENTAL],
    },
    etchAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Angle",
        placeholder: "Enter etch angle",
        isNumber: true,
        order: 8,
        units: [Units.DEG],
        tabs: [Tab.EXPERIMENTAL],
    },
    pressure: {
        fieldType: DisplayFieldTypes.Input,
        label: "Chamber Pressure",
        placeholder: "Enter chamber pressure",
        isNumber: true,
        units: [Units.TORR,Units.PASCAL,Units.MILLIBAR,Units.BAR],
        order: 9,
        tabs: [Tab.EXPERIMENTAL],
    }, 
    // SEMIFAB
    sidewallAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sidewall Angle",
        placeholder: "Enter etch sidewall angle",
        isNumber: true,
        order: 6,
        units: [Units.DEG],
        tabs: [Tab.SEMIFAB],
        infoContent: "In FabuBlox, 0° refers to a perfectly vertical sidewall. Use negative values to slope outwards, and positive values to slope inwards."
    },
}

export const getIonMilling = ({stepNumber}: BloxArgs): IonMilling => ({
        name: `Ion Milling ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.IonMilling,
        etchDepth: null,
        etchDepthUnit: Units.NM,
        gas: null,
        gasFlow: null,
        etchRate: null,
        etchRateUnit: Units.NMPERSEC,
        etchTime: null,
        etchTimeUnit: Units.SEC,
        etchAngle: null,
        etchAngleUnit: Units.DEG,
        pressure: null,
        pressureUnit: Units.TORR,
        layerLabelsToEtch: [],
        percentToEtch: 100,
        commentField: null,
        customFields: {},
        sidewallAngle: 0,
        sidewallAngleUnit: Units.DEG,
         // to do: user needs to select up to which layer to etch
});

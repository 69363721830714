export interface MaterialParameters {
    materialFieldName: PropertyKey // which field has the material name
    unknownLabel: string; // how to call a material if the field is empty
    isResist?: boolean;
}

export interface MaterialMap {
    [key: string]: MaterialParameters | null
}

// maps between bloxes that deposit materials to the name of the field which has the material name
export const materialFieldMap: MaterialMap = {

    "STARTBLOX": null, // STARTBLOX is special, has multiple layers with multiple materials

    "CUSTOMABLOX": null,
    "BAKE": null,
    "CUSTOMCLEAN": null,
    "DEVELOP": null,
    "MASKALIGN": null,
    "STEPPERLITHO": null,
    "DIRECTWRITELITHO": null,
    "INTERFERENCE-LITHO": null,
    "IMPRINTLITHO": null,
    "SCANNINGPROBELITHO": null,
    "FLOODEXPOSE": null,
    "DOPEDIFFUSION": null,
    "EBL": null,
    "FLIPSTACK": null,
    "ICPRIE": null,
    "IONIMPLANT": null,
    "LIFTOFF": null,
    "PLASMA-ASH": null,
    "PLASMA-DESCUM": null,
    "RCACLEAN": null,
    "RIE": null,
    "SUBSTACK": null,
    "STRIPRESIST": null,
    "WETETCH": null,
    "RTA": null,
    "IONMILL": null,

    "SPINCOATRESIST": {
        materialFieldName: "resist",
        unknownLabel: "Unnamed Resist",
        isResist: true
    },
    "SPINCOAT": {
        materialFieldName: "material",
        unknownLabel: "Unnamed Spin-Coated Layer",
        isResist: false
    },
    "SPINDOPANT": {
        materialFieldName: "material",
        unknownLabel: "Unnamed Spin-On Dopant",
        isResist: false
    },
    "HMDSVAPOR":{
        materialFieldName: "material",
        unknownLabel: "HMDS",
        isResist: false
    },
    "SPUTTER":{
        materialFieldName: "sputterMaterial",
        unknownLabel: "Unnamed Sputter Layer",
        isResist: false
    },
    "THERMALEVAP":{
        materialFieldName: "material",
        unknownLabel: "Unnamed Thermal Evap Layer",
        isResist: false
    },
    "EBEAMEVAP":{
        materialFieldName: "material",
        unknownLabel: "Unnamed E-beam Evap Layer",
        isResist: false
    },
    "MOCVD":{
        materialFieldName: "material",
        unknownLabel: "Unnamed MOCVD Layer",
        isResist: false
    },
    "MBE":{
        materialFieldName: "material",
        unknownLabel: "Unnamed MBE Layer",
        isResist: false
    },
    "ALD":{
        materialFieldName: "material",
        unknownLabel: "Unnamed ALD Layer",
        isResist: false
    },
    "PECVD":{
        materialFieldName: "material",
        unknownLabel: "Unnamed CVD Layer",
        isResist: false
    },
    "LPCVD":{
        materialFieldName: "material",
        unknownLabel: "Unnamed CVD Layer",
        isResist: false
    },
    "APCVD":{
        materialFieldName: "material",
        unknownLabel: "Unnamed CVD Layer",
        isResist: false
    },
    "DROPCAST":{
        materialFieldName: "material",
        unknownLabel: "Unnamed Drop-Cast Layer",
        isResist: false
    },
    "PARYLENEDEP":{
        materialFieldName: "paryleneType",
        unknownLabel: "Unnamed Parylene film",
        isResist: false
    },
    "WETGROWTH":{
        materialFieldName: "material",
        unknownLabel: "Unnamed Wet-Grown Layer",
        isResist: false
    },
    "WAFERBONDING":{
        materialFieldName: "material",
        unknownLabel: "Unnamed Wafer",
        isResist: false
    },
    "THERMALOXIDATION":{
        materialFieldName: "oxide",
        unknownLabel: "Unnamed Oxide",
        isResist: false
    },
    "ELECTROPLATE":{
        materialFieldName: "material",
        unknownLabel: "Unnamed Electroplated Layer",
        isResist: false
    }


}
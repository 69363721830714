import { Classes, Tag, Text } from "@blueprintjs/core";
import { Row } from "../Layout/layouts";
import { ReactComponent as RayDepExOne } from '../components/svg/ray_deposit_ex1.svg';
import { ReactComponent as RayDepAngDist } from '../components/svg/ray_deposit_ang_dist_ex1.svg';
import { ReactComponent as RayDepStepSize } from '../components/svg/ray_deposit_step_size_ex1.svg';
import { ReactComponent as RayDepInterp } from '../components/svg/ray_deposit_interp_ex1.svg';
import { ReactComponent as RayDepTargetAngle } from '../components/svg/ray_deposit_target_angle_ex1.svg';
import { ReactComponent as RayDepIsoRatio } from '../components/svg/ray_deposit_iso_ratio.svg';


export const SputterDialogContent: JSX.Element = (
    <div className={Classes.DIALOG_BODY}>
        <Text>
            The sputter algorithm is among the most complex models available in FabuBlox. While this offers more realistic depositions, it requires more tuning from the user to match the desired tool and may need additional computation time.
        </Text>

        <Text style={{marginTop: '20pt', marginBottom:'20pt'}}>
             For users that prefer a simpler algorithm, we offer a "conformal" option, which does not take any shadowing into account. For relatively flat samples, this should be sufficient. 
        </Text>

        <Row>
            <RayDepExOne style={{width: '90%', margin: 'auto'}} />
        </Row>
    </div>
);

export const SputterAngularDistDialogContent: JSX.Element = (
    <div className={Classes.DIALOG_BODY}>
        <Text>
            The sputter algorithm is among the most complex models available in FabuBlox. While this offers more realistic depositions, it requires more tuning from the user to match the desired tool.
        </Text>

        <Text style={{marginTop: '20pt', marginBottom:'20pt'}}>
            <strong> Maximum Deposition Angle</strong> is the largest angle of incident material. The angular distribution follows a cosine squared pattern which is clipped to the maximum angle. Larger values will lead to more sidewall deposition when not shadowed, but will make bread-loafing more prominent. 
        </Text>

        <Row>
            <RayDepAngDist style={{width: '90%', margin: 'auto'}} />
        </Row>
    </div>
);

export const SputterStepSizeDialogContent: JSX.Element = (
    <div className={Classes.DIALOG_BODY}>
        <Text>
            The sputter algorithm is among the most complex models available in FabuBlox. While this offers more realistic depositions, it requires more tuning from the user to match the desired tool.
        </Text>

        <Text style={{marginTop: '20pt', marginBottom:'20pt'}}>
            To accurately capture effects of shadowing as non-uniform growth occurs, the sputter algorithm is divided in to several steps. The <strong> Step Size</strong> is the amount of material deposited in each step. Typically, the step size should be between 20-50% of the total thickness. Leaving the default value of 0 will let FabuBlox decide the step size for you.   
        </Text>

        <Row>
            <RayDepStepSize style={{width: '90%', margin: 'auto'}} />
        </Row>
    </div>
);

export const SputterInterpDialogContent: JSX.Element = (
    <div className={Classes.DIALOG_BODY}>
        <Text>
            The sputter algorithm is among the most complex models available in FabuBlox. While this offers more realistic depositions, it requires more tuning from the user to match the desired tool.
        </Text>

        <Text style={{marginTop: '20pt', marginBottom:'20pt'}}>
            Sputter deposition is a line-of-sight technique that requires calculating the material to hit each point on the surface. <strong> Surface Interpolation </strong> is the maximum distance between interpolated points on the surface. FabuBlox uses a smart interpolation algorithm to automatically interpolate edges and shadows. Since low interpolation distances can increase simulation times, users should start with a coarse value and only make the interpolation finer if needed. Leaving the default value of 0 will let FabuBlox decide the interpolation value for you. 
        </Text>

        <Row>
            <RayDepInterp style={{width: '90%', margin: 'auto'}} />
        </Row>
    </div>
);

export const SputterTargetAngleDialogContent: JSX.Element = (
    <div className={Classes.DIALOG_BODY}>
        <Text>
            The sputter algorithm is among the most complex models available in FabuBlox. While this offers more realistic depositions, it requires more tuning from the user to match the desired tool.
        </Text>

        <Text style={{marginTop: '20pt', marginBottom:'20pt'}}>
            <strong> Target Angle </strong> allows users to change the location of the target relative to the substrate so that the most material comes from the specified angle. This means that more material will be deposited along isolated sidewalls, but less will be deposited into trenches.
        </Text>

        <Row>
            <RayDepTargetAngle style={{width: '90%', margin: 'auto'}} />
        </Row>
    </div>
);

export const SputterIsoRatioDialogContent: JSX.Element = (
    <div className={Classes.DIALOG_BODY}>
        <Text>
            The sputter algorithm is among the most complex models available in FabuBlox. While this offers more realistic depositions, it requires more tuning from the user to match the desired tool.
        </Text>

        <Text style={{marginTop: '20pt', marginBottom:'20pt'}}>
            For conformal depositions, the <strong> Isotropy ratio </strong> is simply the ratio of sidewall thickness to the deposited thickness. For non-conformal depositions, the isotropy ratio dictates if the material is deposited perpendicular to the surface (100%), or relative to the incoming material (0%).
        </Text>

        <Row>
            <RayDepIsoRatio style={{width: '90%', margin: 'auto'}} />
        </Row>
    </div>
);
import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { PatternDialogContent } from "../../dialogs/PatternDialogContent";

export interface ImprintLitho extends BaseBlox {
    //EXPERIMENTAL
    laserWavelength: string | null;
    expDose: number | null;
    expDoseUnit: Units | null;

    // DISPLAY
    layerPattern: string | null;
    layerInvertPattern: boolean;
    percentToImprint: number | null;

    // SEMIFAB
    layerLabelsToImprint: string[] | null;
    sidewallAngle: number | null;
    sidewallAngleUnit: Units | null;
    imprintDepth: number | null;
    imprintDepthUnit: Units | null;
}

export const imprintLithoDisplay: DisplayMap = {

    // DISPLAY
    layerPattern: {
        fieldType: DisplayFieldTypes.PatternInput,
        label: "Define Pattern",
        order: 1,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: PatternDialogContent
    },
    layerInvertPattern: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Invert Pattern",
        order: 2,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    percentToImprint: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Imprint Depth",
        order: 3,
        tabs: [Tab.DISPLAY],
        defaultValue: 80,
        stepSize: 2
    },
    layerLabelsToImprint: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Imprint",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    sidewallAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sidewall Angle",
        placeholder: "Enter etch sidewall angle",
        isNumber: true,
        order: 6,
        units: [Units.DEG],
        tabs: [Tab.SEMIFAB],
        infoContent: "In FabuBlox, 0° refers to a perfectly vertical sidewall. Use negative values to slope outwards, and positive values to slope inwards."
    },
    imprintDepth: {
        fieldType: DisplayFieldTypes.Input,
        label: "Imprint Depth",
        placeholder: "Enter imprint depth",
        isNumber: true,
        order: 6,
        units: [Units.ANG, Units.NM, Units.MICRON],
        tabs: [Tab.SEMIFAB],
        infoContent:"Depth is measured from the highest point in the cross-section. Leaving a value of 0 will fully remove all material in the imprinted region."
    },
}

export const getImprintLitho = ({stepNumber}: BloxArgs): ImprintLitho => ({
        name: `Imprint Lithography ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.ImprintLitho,
        laserWavelength: null,
        expDose: null,
        expDoseUnit: Units.MILLIJOULPERSQCM,
        layerPattern: "1,2,4,2,1",
        layerInvertPattern: false,
        percentToImprint: 80,
        commentField: null,
        customFields: {},
        // SEMIFAB
        layerLabelsToImprint: [],
        sidewallAngle: 0,
        sidewallAngleUnit: Units.DEG,
        imprintDepth: 0,
        imprintDepthUnit: Units.NM,
});

import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { DisplayFieldTypes, Tab, Units } from "../enums";

export interface Liftoff extends BaseBlox {
    // to do user select number of cleaning steps
    chemical: string | null;
    time: number | null;
    timeUnit: Units | null;
    temp: number | null;
    tempUnit: Units | null;
}

export const liftoffDisplay: DisplayMap = {
    chemical: {
        fieldType: DisplayFieldTypes.Input,
        label: "Lift-Off Chemical(s)",
        placeholder: "Enter chemical name(s)",
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
      },
    time: {
        fieldType: DisplayFieldTypes.Input,
        label: "Lift-Off Time",
        placeholder: "Enter lift-off time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    temp: {
        fieldType: 1,
        label: "Lift-Off Temperature",
        placeholder: "Enter lift-off temperature",
        isNumber: true,
        units: [Units.CELSIUS,Units.KELVIN],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    }
}

export const getLiftOff = ({stepNumber}: BloxArgs): Liftoff => ({
        name: `Lift-Off ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.LiftOff,
        chemical: null,
        time: null,
        timeUnit: Units.MIN,
        temp: null,
        tempUnit: Units.CELSIUS,
        commentField: null,
        customFields: {}
    }
);

import React, { createContext, useState, ReactNode } from 'react';
import { noOp } from '../../utils/empty-arrow-function-helper';

interface UnsavedChangesProviderProps {
    children: ReactNode;
 }

// There are two separate pieces of state that we need to manage for unsaved changes:
// 1. If the page is changing from within fabublox we can use a custom dialog and allow the action to continue if desired
// 2. If the page is change from the browser (e.g. back button or closing tab) we need to use the browser's built-in dialog
interface UnsavedChangesContextValue {
    showSaveYourChangesDialog: boolean;
    setShowSaveYourChangesDialog: React.Dispatch<React.SetStateAction<boolean>>;
    editorUnsavedChanges: boolean;
    setEditorUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValue = {
    showSaveYourChangesDialog: false,
    setShowSaveYourChangesDialog: noOp,
    editorUnsavedChanges: false,
    setEditorUnsavedChanges: noOp
};

export const UnsavedChangesContext = createContext<UnsavedChangesContextValue>(defaultValue);

export const UnsavedChangesProvider: React.FC<UnsavedChangesProviderProps> = ({ children }) => {
    const [showSaveYourChangesDialog, setShowSaveYourChangesDialog] = useState(false);
    const [editorUnsavedChanges, setEditorUnsavedChanges] = useState(false);
    return (
        <UnsavedChangesContext.Provider value={{ showSaveYourChangesDialog, setShowSaveYourChangesDialog, editorUnsavedChanges, setEditorUnsavedChanges }}>
            {children}
        </UnsavedChangesContext.Provider>
    );
};

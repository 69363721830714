import { Dialog, Button, Intent, Classes } from '@blueprintjs/core';
import { Row } from '../Layout/layouts';
import { useContext } from 'react';
import { UnsavedChangesContext } from '../hooks/state/unsaved-changes-provider';

export function SaveYourChangesDialog() {
    const { showSaveYourChangesDialog } = useContext(UnsavedChangesContext);

    return <>
        <Dialog isOpen={showSaveYourChangesDialog} title='Warning' icon='warning-sign' isCloseButtonShown={false}>
            <div className={Classes.DIALOG_BODY}>
                <p>
                    <strong>
                        Any unsaved changes will be lost.
                    </strong>
                </p>
                <p>
                    Do you want to continue?
                </p>
                <Row style={{ margin: "0 20px" }}>
                    <Button id='dialog-cancel-button' style={{ margin: 'auto 5px auto auto' }}>Cancel</Button>
                    <Button id='dialog-confirm-button' style={{ margin: 'auto 0px auto 0px' }} intent={Intent.DANGER}>Continue</Button>
                </Row>
            </div>
        </Dialog>
    </>
}

export async function openDialogAndWaitForInput(setDialogOpen: (isOpen: boolean) => void, setEditorUnsavedChanges?: (isOpen: boolean) => void): Promise<boolean> {

    return new Promise((resolve) => {
        setDialogOpen(true);

        function handleDialogResult(result: boolean) {
            setDialogOpen(false);

            resolve(result);
        }
        setTimeout(() => {
            // Attach event listener to dialog buttons after they are rendered
            document?.getElementById('dialog-confirm-button')?.addEventListener('click', () => {
                if (setEditorUnsavedChanges) setEditorUnsavedChanges(false);
                handleDialogResult(true);
            });
            document?.getElementById('dialog-cancel-button')?.addEventListener('click', () => {
                handleDialogResult(false);
            });
        }, 200);

    });
}
import { Dialog, Button, Intent, Classes, FormGroup, Label, HTMLSelect } from '@blueprintjs/core';
import { Row } from '../Layout/layouts';
import { useCallback, useContext, useState } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { AllBloxes } from '../Data/BloxSchema/base-blox';
import { SVGDisplayMode } from '../Services/SVGEngine';
import { SvgServiceContext } from '../hooks/state/svg-service-provider';
import { showToast } from '..';

interface ExportSVGDialogInterface {
    filename: string,
    isOpen: boolean,
    closeDialog: () => void,
    bloxes: AllBloxes[]
}

enum SaveMode {
    CopyToClipboard,
    DownloadFile
}

export function ExportSVGDialog({filename, isOpen, closeDialog, bloxes}:ExportSVGDialogInterface) {

    const [fileFormat, setFileFormat] = useState("PNG");
    const [resolution, setResolution] = useState("Normal");

    const { generateSvgs } = useContext(SvgServiceContext);

    const triggerDownload = useCallback((url: string, ext: string) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = filename + ext;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }, [filename]);

    const generatePNG = useCallback((svgElement: Element, source: string, saveMode: SaveMode) => {
        const canvas = document.createElement('canvas');
        const svgSize = svgElement.getBoundingClientRect();

        const width = resolution === "High" ? 2048 : 512;
        const height = width * svgSize.height / svgSize.width
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');

        const DOMURL = window.URL || window.webkitURL || window;

        const img = new Image();
        const svgBlob = new Blob([source], {type: 'image/svg+xml;charset=utf-8'});

        const url = DOMURL.createObjectURL(svgBlob);

        img.onload = function () {
            if (ctx) {
                ctx.drawImage(img, 0, 0, width, height);
                try {
                    if (saveMode === SaveMode.CopyToClipboard) {
                        canvas.toBlob(function (blob) {
                            if (blob) {
                                const item = new ClipboardItem({ "image/png": blob });
                                navigator.clipboard.write([item]);
                            }
                        });


                    } else if (saveMode === SaveMode.DownloadFile) {
                        DOMURL.revokeObjectURL(url);

                        const imgURI = canvas
                            .toDataURL('image/png')
                            .replace('image/png', 'image/octet-stream');

                        triggerDownload(imgURI, ".png");
                    }
                } catch (error) {
                    showToast({
                        message: `Make sure you have granted permission to ${saveMode === SaveMode.CopyToClipboard ? 'access clipboard.' : 'download file.'}.`,
                        intent: Intent.WARNING,
                        timeout: 3000
                    });
                }
            }
        };

        img.src = url;
    }, [triggerDownload, resolution]);

    const generateSVG = useCallback((source: string, saveMode: SaveMode) => {
        //add name spaces.
        if(!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)){
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if(!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)){
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        //add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
        
        if (saveMode === SaveMode.CopyToClipboard) {
            console.warn("SVG to Clipboard not supported!");
        } else if (saveMode === SaveMode.DownloadFile) {
            //convert svg source to URI data scheme.
            const svgUrl = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
            triggerDownload(svgUrl, ".svg");
        }
    }, [triggerDownload]);

    const handleClose = useCallback(() => {
        closeDialog();
    }, [closeDialog]);

    const handleCopy = useCallback(() => {
        const svg = document.querySelector(".bloxSVGPreview");

        if (svg) {
             //get svg source.
             const serializer = new XMLSerializer();
             const source = serializer.serializeToString(svg);

            if (fileFormat === "PNG") {
                generatePNG(svg, source, SaveMode.CopyToClipboard)
            } else {
                generateSVG(source, SaveMode.CopyToClipboard);
            }  
        }
    }, [generatePNG, generateSVG, fileFormat]);

    const handleDownload = useCallback(() => {
        const svg = document.querySelector(".bloxSVGPreview");

        if (svg) {
             //get svg source.
             const serializer = new XMLSerializer();
             const source = serializer.serializeToString(svg);

            if (fileFormat === "PNG") {
                generatePNG(svg, source, SaveMode.DownloadFile)
            } else {
                generateSVG(source, SaveMode.DownloadFile);
            }  
        }
        
    }, [generatePNG, generateSVG, fileFormat]);

    const svgPreview = useCallback(() => {
        if (isOpen) {
            const svgs = generateSvgs(bloxes, false, SVGDisplayMode.ExportPreview);
            if (svgs.length > 0) {
                return svgs[svgs.length - 1];
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    }, [bloxes, isOpen, generateSvgs])

    return <>
        <Dialog isOpen={isOpen} title='Export Image' icon='export' onClose={handleClose} style={{"paddingBottom" : 0}}>
            <div className={Classes.DIALOG_BODY}>
            
            <Row>
            <FormGroup >
                <Label style={{"width" :"80px", display: "inline-block", marginRight: "20px"}}>
                    {"Format: "}
                    <HTMLSelect  value={fileFormat} options={["PNG", "SVG"]} onChange={(event) => setFileFormat(event.target.value)}/>
                </Label>
                { fileFormat === "PNG" && <Label style={{"width" :"100px", display: "inline-block"}}>
                    {"Resolution: "}
                    <HTMLSelect  value={resolution} options={["Normal", "High"]} onChange={(event) => setResolution(event.target.value)}/>
                </Label>}
            </FormGroup>
            </Row>
            <Row style={{ margin: "12px 0" }}>
                <div className="bloxExport">
                { svgPreview() }
                </div>
            </Row>
            <Row >
                { fileFormat === "PNG" && <Button onClick={handleCopy} icon={IconNames.CLIPBOARD} text={'Copy to Clipboard'} style={{ margin: 'auto auto auto 5px' }} /> }
                <Button onClick={handleDownload} icon={IconNames.DOWNLOAD} style={{ margin: 'auto 5px auto auto' }} intent={Intent.SUCCESS}>Download</Button>
            </Row>
            </div>
        </Dialog>
    </>
}
import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { PatternDialogContent } from "../../dialogs/PatternDialogContent";

export interface DirectWriteLitho extends BaseBlox {
    //EXPERIMENTAL
    laserWavelength: string | null;
    expDose: number | null;
    expDoseUnit: Units | null;

    // DISPLAY
    layerPattern: string | null;
    layerInvertPattern: boolean;

    // SEMIFAB
    // maskMethod: string | null;
    // gdsLayer: number | null;
    // coordPattern: number | null;
    // coordPatternUnit: Units | null;
    // SEMIFAB
    layerLabelsToExpose: string[] | null;
    sidewallAngle: number | null;
    sidewallAngleUnit: Units | null;
}

export const directWriteLithoDisplay: DisplayMap = {
    laserWavelength: {
        fieldType: DisplayFieldTypes.Dropdown,
        label: "Laser Wavelength",
        options: ["Select Laser Wavelength","13.5nm (EUV)","193nm (DUV)","247nm (DUV)","365 nm (i-line)","375 nm", "405 nm (h-line)", "435 nm (g-line)"],
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    expDose: {
        fieldType: DisplayFieldTypes.Input,
        label: "Exposure Dose",
        placeholder: "Enter exposure dose",
        isNumber: true,
        units: [Units.MILLIJOULPERSQCM,Units.UCOULPERSQCM],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    
    // DISPLAY
    layerPattern: {
        fieldType: DisplayFieldTypes.PatternInput,
        label: "Define Pattern",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: PatternDialogContent
    },
    layerInvertPattern: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Invert Pattern",
        order: 1,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    // SEMIFAB
    // FUTURE RELEASE
    // maskMethod: {
    //     fieldType: DisplayFieldTypes.Dropdown,
    //     label: "Pattern Input Method",
    //     options: ["Ratios", "Coordinates", "GDS"],
    //     order: 1,
    //     tabs: [Tab.SEMIFAB],
    // },
    layerLabelsToExpose: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Expose",
        order: 0,
        materialFilter: "ONLY_RESIST",
        tabs: [Tab.SEMIFAB],
        infoContent: "Select which resists to expose. Only resists deposited using a 'Spin-Resist' step can be exposed. Leaving this empty will expose all resists that are present."
    },
    sidewallAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sidewall Angle",
        placeholder: "Enter etch sidewall angle",
        isNumber: true,
        order: 6,
        units: [Units.DEG],
        tabs: [Tab.SEMIFAB],
        infoContent: "In FabuBlox, 0° refers to a perfectly vertical sidewall. Use negative values to slope outwards, and positive values to slope inwards."
    },
    // TODO: Add DisplayFieldTypes.GDSLayers
    // TODO: Add DisplayFieldTypes.CoordinateInput
}

export const getDirectWriteLitho = ({stepNumber}: BloxArgs): DirectWriteLitho => ({
        name: `Direct-Write Lithography ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.DirectWriteLitho,
        laserWavelength: null,
        expDose: null,
        expDoseUnit: Units.MILLIJOULPERSQCM,
        layerPattern: "1,2,4,2,1",
        layerInvertPattern: false,
        commentField: null,
        customFields: {},
        // SEMIFAB
        // maskMethod: "Ratios",
        // gdsLayer: null,
        // coordPattern: null,
        // coordPatternUnit: Units.NM,
        layerLabelsToExpose: [],
        sidewallAngle: 0,
        sidewallAngleUnit: Units.DEG,
});

import { useCallback } from "react";
import { AllBloxes, BloxTypes } from "../../Data/BloxSchema/base-blox";
import { getNewBlox } from "../../hooks/new-blox-data";
import { Intent, ToastProps } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { BASE_FABUBLOX_API_URL } from "../../utils/constants";
import { v4 as uuidv4 } from 'uuid';
import { useQueryClient } from "react-query";
import { StateAndSetter } from "../../hooks/state/parameter-context";
import { showToast } from "../..";

export interface HandlersArgs {
  selectedBloxIdState: StateAndSetter<string>;
  bloxesState: StateAndSetter<AllBloxes[]>;
}

export function useHandlers(args: HandlersArgs) {
  const {
    selectedBloxIdState: [, setSelectedBloxId],
    bloxesState: [bloxes, setBloxes],
  } = args; 


  const queryClient = useQueryClient();

  const handleDrop = useCallback((item: {bloxType: BloxTypes, id?: string, data?: AllBloxes}, stepNumber: number) => {
    const updateBloxes = [...bloxes]
    let bloxId = item.id;
    let data: AllBloxes | null = null;

    // reorder existing blox
    if(item.id) {
      const toMoveIdx = updateBloxes.findIndex(blox => blox.id === item.id);
      if (toMoveIdx === stepNumber || toMoveIdx === stepNumber + 1) return bloxId;
      const toMoveBlox = updateBloxes[toMoveIdx]; 
     
      // add to new position
      updateBloxes.splice(stepNumber + 1, 0, toMoveBlox);
      //if dropping before, the toRemoveBlox is in a new position
      const toRemoveIdx = stepNumber < toMoveIdx ? toMoveIdx + 1 : toMoveIdx;
      // remove old blox
      updateBloxes.splice(toRemoveIdx, 1);
    } else { 
      // add new blox

      // blox has data already if it is a MyBlox
      if (item.data) {
        data = {...item.data};
        bloxId = uuidv4();
        data.id = bloxId;
      }

      const newBlox = data ?? getNewBlox(item.bloxType, '');
      bloxId = newBlox.id;
      updateBloxes.splice(stepNumber + 1, 0, newBlox);
    }
     
    setBloxes([...updateBloxes]);

    if (bloxId) {
      setSelectedBloxId(bloxId);
    }
    
    return bloxId;
  }, [ bloxes, setBloxes, setSelectedBloxId]); 

  const handleDelete = useCallback((id: string) => {
    const updateBloxes = [...bloxes];

    const toRemoveIdx = updateBloxes.findIndex((blox) => blox.id === id);
    if (toRemoveIdx === 0 || toRemoveIdx < 0) return;
    updateBloxes.splice(toRemoveIdx, 1);

    setBloxes([...updateBloxes]);
  }, [bloxes, setBloxes]);
 
  const handleClickBlox = useCallback((bloxId: string) => {
      if (bloxes.findIndex(blox => blox.id === bloxId) < 0) return;
      setSelectedBloxId(bloxId);
      console.log("Selected Blox: ", bloxId);
  }, [bloxes, setSelectedBloxId]);

  const handleSave = useCallback((id: string, path: string, toast: ToastProps, body: object, token?: string, isModule?: boolean) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: JSON.stringify(body)
    };

    fetch(`${BASE_FABUBLOX_API_URL}${path}${id}`, requestOptions)
      .then(res => {
        if (res.ok) {
          showToast(toast)
          queryClient.invalidateQueries([`${isModule ? 'module' : 'process'}`, id]);
        } else {
          showToast({
            message: "Save Failed",
            intent: Intent.DANGER,
            timeout: 3000
          });

      }})
      .catch((error: any) => console.error(`Save failed with ${error.message}`));
  }, [queryClient]);

  const history = useHistory();

  const handleCreate = useCallback((path: string, redirectPath: string, toast: ToastProps, body: object, token?: string) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
      body: JSON.stringify(body)
    };

    fetch(`${BASE_FABUBLOX_API_URL}${path}`, requestOptions)
      .then(res => {
        if (res.ok)
          showToast(toast)
        else
          showToast({
            message: "Save Failed",
            intent: Intent.DANGER,
            timeout: 3000
          });

        return res.json();
      })
      .then(res => {
        const {id} = res;
        history.push(`${redirectPath}/${id}`);
      })
      .catch((error: any) => console.error(`Create failed with ${error.message}`));
  }, [history]);

  return {
    handleClickBlox,
    handleDelete,
    handleDrop,
    handleSave,
    handleCreate,
  };
}
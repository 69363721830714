import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";

export interface IonImplantation extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    implantEnergy: number | null;
    implantEnergyUnit: Units | null;
    dose: number | null;
    doseUnit: Units | null; 
    angle: number | null;
    angleUnit: Units | null;
    temperature: number | null;
    temperatureUnit: Units | null;

    // DISPLAY
    layerColor: IColor | null;
    layerThickness: number | null;
    layerLabel: string | null;
    layerLabelsToImplant: string[] | null;

    // SEMIFAB
    depth: number | null;
    depthUnit: Units | null;
}

export const ionImplantationDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.Input,
        label: "Ion Species",
        placeholder: "Enter dopant species",
        order: 2,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    implantEnergy: {
        fieldType: DisplayFieldTypes.Input,
        label: "Implantation Energy",
        placeholder: "Enter energy",
        isNumber: true,
        units: [Units.KILOELECVOLT,Units.MEGAELECVOLT],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    dose: {
        fieldType: DisplayFieldTypes.Input,
        label: "Implantation Dose",
        placeholder: "Enter dose",
        isNumber: true,
        units: [Units.PERSQCM],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    angle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Implantation Angle",
        placeholder: "Enter angle",
        isNumber: true,
        units: [Units.DEG],
        order: 5,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
        infoContent: "0° refers to a perfectly vertical implantation. Positive angles will implant from the left, and negative angles will implant from the right."
    },
    temperature: {
        fieldType: DisplayFieldTypes.Input,
        label: "Implantation Temperature",
        placeholder: "Enter temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 6,
        tabs: [Tab.EXPERIMENTAL],
    },
    
    
    // DISPLAY
    layerLabelsToImplant: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Implant",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 4,
        tabs: [Tab.DISPLAY],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Implantation Depth",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 1,
        tabs: [Tab.DISPLAY],
    },

    // SEMIFAB
    depth: {
        fieldType: DisplayFieldTypes.Input,
        label: "Implant Depth",
        placeholder: "Enter depth",
        isNumber: true,
        units: [Units.ANG, Units.NM, Units.MICRON],
        order: 5,
        tabs: [Tab.SEMIFAB],
    },

}

export const getIonImplantation = ({stepNumber}: BloxArgs): IonImplantation => ({
        name: `Ion Implantation ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.IonImplantation,
        material: null,
        implantEnergy: null,
        implantEnergyUnit: Units.KILOELECVOLT,
        dose: null,
        doseUnit: Units.PERSQCM,
        angle: null,
        angleUnit: Units.DEG,
        temperature: null,
        temperatureUnit: Units.CELSIUS,
        layerColor: {R: 0, G: 0, B: 0, A: 0.25},
        layerThickness: 10,
        layerLabel: null,
        commentField: null,
        customFields: {},
        layerLabelsToImplant: [],
        depth: null,
        depthUnit: Units.NM,
});

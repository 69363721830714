import {  Colors, Icon } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import { Tooltip2 } from "@blueprintjs/popover2"
import { useCallback } from "react"
import { useFabuState } from "../hooks/state/use-fabu-state"
import { Row } from "../Layout/layouts"

export const iconBtnStyle = {
    display: 'flex',
    height: '25px',
    width: '25px',
    borderRadius: '4px 4px 4px 4px'
}

export const SharingPicker: React.FC = () => {
    const [processIsPrivate, setProcessIsPrivate] = useFabuState('processIsPrivate');

    const onClickGlobe = useCallback(() => setProcessIsPrivate(!processIsPrivate), [processIsPrivate, setProcessIsPrivate])

    return (
        <Tooltip2 content={`${processIsPrivate ? 'Private' : 'Public'} process`} placement={'bottom'}>
            <Row style={{ height: '40px', paddingLeft: '15px', cursor: 'pointer'}}>
                <div style={{ margin: 'auto', ...iconBtnStyle, background: processIsPrivate ? Colors.ORANGE1 : Colors.BLUE1 }} onClick={onClickGlobe}>
                    <Icon style={{margin: 'auto'}} size={15} color='white' icon={processIsPrivate ? IconNames.LOCK : IconNames.GLOBE} />
                </div>
            </Row>
        </Tooltip2>
    )
}
import {
  Divider,
  EditableText,
} from '@blueprintjs/core'
import { Row } from '../Layout/layouts'

export interface SearchHeaderProps {
  filterBy?: string
  placeholder: string
  handleChangeFilter: (val: string) => void
}

export const SearchHeader: React.FC<SearchHeaderProps> = (props) => {
  const { filterBy, handleChangeFilter, placeholder } = props

  return (
    <Row>
      <div className={'searchBarContainer'}>
        <EditableText
          className={'searchBarText'}
          value={filterBy}
          placeholder={placeholder}
          onChange={(val) => handleChangeFilter(val)}
        />
      </div>
      <Divider />
    </Row>
  )
}

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Intent } from "@blueprintjs/core";

const LogoutButton = () => {
    const { logout } = useAuth0();
    return (
        <Button
            style={{margin: 'auto'}}
            intent={Intent.DANGER}
            large={true}
            onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
            }
        >
            Log Out
        </Button>
    );
};

export default LogoutButton;

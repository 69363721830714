import { Row } from "../../Layout/layouts";
import { ProcessBlox } from "./../ProcessBlox";
import { AllBloxes, BloxTypes } from "../../Data/BloxSchema/base-blox";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFabuState } from "../../hooks/state/use-fabu-state";
import { useProcessHandlers } from "./../hooks/use-process-handlers";
import { SvgServiceContext } from "../../hooks/state/svg-service-provider";
import { SVGDisplayMode } from "../../Services/SVGEngine";
import { ProcessSettingsContext } from "../../hooks/state/process-settings-provider";
import { BetweenBlox } from "../BetweenBlox";

export function ModuleEditor() {
    const { processHandleDrop, processHandleDelete, processHandleClickBlox } = useProcessHandlers();
    const [selectedBloxId,] = useFabuState('selectedBloxIdState');
    const [moduleBloxes] = useFabuState('processBloxes');
    const [scrollToSelectedBlox, setScrollToSelectedBlox] = useFabuState('scrollToSelectedBlox');
    const filteredModuleBloxes = useMemo(() => {
        return moduleBloxes.filter(blox => blox.bloxType !== BloxTypes.StartBlox);
    }, [moduleBloxes])
    const { isStack3D, isStackHidden } = useContext(ProcessSettingsContext);
    const { generateSvgs } = useContext(SvgServiceContext);
    const bloxSVGs = generateSvgs(moduleBloxes, isStack3D, SVGDisplayMode.Process);
    const copiedBloxState = useState<AllBloxes | null>(null);

    useEffect(() => {
        if (!scrollToSelectedBlox) return;

        // Hack, use timeout to allow new scrollwidth to be set after drop.
        // There is a first setTimeout with no wait when calling setScrollToSelectedBlox
        // this second setTimeout is to allow the first to complete before scrolling
        setTimeout(() => {
            const sectionContainer = document.getElementById('sectionContainer');
            if (!sectionContainer) return;
            sectionContainer.scroll({ left: sectionContainer.scrollWidth, behavior: "smooth" });
        }, 100);
        setScrollToSelectedBlox(false);

    }, [scrollToSelectedBlox, selectedBloxId, setScrollToSelectedBlox]);



    const getEmptyModuleTarget = () => {
        return <div style={{margin: 'auto'}}><BetweenBlox
                copiedBlox={copiedBloxState[0]}
                dropOnly={true} 
                showDropTarget={true}
                handleInsertBlox={processHandleDrop ? (item: any) => processHandleDrop(item, 0) : undefined}/></div>;
    }

    const scrollableContainerStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
        margin: 'auto',
        overflowY: 'auto'
    };

    if (filteredModuleBloxes.length === 0)
        return <div id={'sectionContainer'} style={{ ...scrollableContainerStyle }}>
            <Row className="section" style={{ height: '100%' }}>
                {getEmptyModuleTarget()}
            </Row>
        </div>

    return <div id={'sectionContainer'} style={{ ...scrollableContainerStyle }}>
        <Row className="section" style={{ height: '100%' }}>
            {filteredModuleBloxes.map((blox, index) => {
                // account for filtering out the start blox from the user's view
                const indexOffsetStartBlox = index + 1;
                return <ProcessBlox
                    copiedBloxState={copiedBloxState}
                    onClickBlox={() => processHandleClickBlox(blox.id)}
                    processBloxes={moduleBloxes.slice(0, indexOffsetStartBlox + 1)}
                    bloxData={blox}
                    bloxSVG={indexOffsetStartBlox < bloxSVGs.length ? bloxSVGs[indexOffsetStartBlox] : <></>}
                    handleInsertBlox={processHandleDrop ? (item: { bloxType: BloxTypes, id?: string }) => processHandleDrop(item, indexOffsetStartBlox) : undefined}
                    onDelete={processHandleDelete}
                    hideStack={isStackHidden}
                    key={blox.id + index}
                    leftMargin={index === 0}
                    showDropTarget={index === filteredModuleBloxes.length - 1}
                    isSelected={selectedBloxId === blox.id} />
            })}
        </Row>
    </div>
}
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'

import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { useCallback, useMemo, useRef, useState } from 'react'
import { ColDef, ModuleRegistry } from '@ag-grid-community/core'
import { Link } from 'react-router-dom'
import { SearchHeader } from '../components/SearchHeader'
import { Column } from '../Layout/layouts'
import { ProcessPreviewRenderer } from '../list/ProcessPreviewRenderer'
import { searchProcessAndBlox } from '../utils/search-process-and-blox'
import { useGroupProcesses } from '../hooks/DataFetching/use-fetch-process'
import { Loading } from '../components/Loading'
import { BloxesAndThumbnail } from './SearchPage'

interface ProcessListResult {
  processName: string
  processId: string
  bloxesInfo: BloxesAndThumbnail
  createdAt: string
  lastUpdatedAt: string
  groups: { id: string, groupName: string }[]
}

export const GroupProcesses: React.FC<{isModule?: boolean}> = ({isModule}) => {
  const resourceName = isModule ? 'module' : 'process';
  const upperResourceName = resourceName.charAt(0).toUpperCase() + resourceName.slice(1);
  const [filterBy, setFilterBy] = useState<string>();
  const { data, isLoading, error } = useGroupProcesses(isModule);
  const rowData: ProcessListResult[] | undefined = useMemo(() => {
    if (!data) return undefined;
    return data.map((process) => {
      return {
        processName: process.processName,
        processId: process.processId,
        bloxesInfo: {bloxes: process.bloxes, thumbnail: process.thumbnail},
        username: process.username,
        createdAt: process.createdAt,
        lastUpdatedAt: process.lastUpdatedAt,
        groups: process.groups,
      }
    })
  }, [data]);

  ModuleRegistry.registerModules([ClientSideRowModelModule])

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: '',
      field: 'bloxesInfo',
      minWidth: 90,
      width: 90,
      maxWidth: 90,
      cellRenderer: ProcessPreviewRenderer,
      resizable: true,
      sortable: false,
      filter: false,
    },
    {
      headerName: `${upperResourceName} Name`,
      field: 'processName',
      width: 500,
      cellRenderer: (props: any) => <Link to={`/${resourceName}-editor/${props?.node?.data?.processId}`}>{props.value}</Link>
    },
    {
      headerName: 'Creator',
      field: 'username'
    },
    {
      headerName: 'Groups',
      field: 'groups',
      width: 300,
      valueGetter: (props) => {
        if (!props?.node?.data?.groups?.length) {
          return '';
        }
        const groupNames = props.node.data.groups.map((g: { id: string, groupName: string }) => g.groupName);
        return groupNames.join(', ');
      }
    },
    {
      headerName: 'No. of Steps',
      minWidth: 70,
      width: 135,
      valueGetter: (props) => {
        const bloxes = props?.node?.data?.bloxesInfo?.bloxes;
        return bloxes ? bloxes.length : 0;
      },
      type: 'numericColumn'
    },
    {
      headerName: 'Last Updated',
      field: 'lastUpdatedAt',
      width: 200,
      valueFormatter: ({ value }) => value.substring(0, 10),
      sort: 'desc',
    },
    {
      headerName: 'Created On',
      field: 'createdAt',
      width: 200,
      valueFormatter: ({ value }) => value.substring(0, 10)
    }
  ]);

  const gridRef = useRef(null);

  const filterFunc = useCallback((row: ProcessListResult) => {
    return searchProcessAndBlox(row, filterBy);
  }, [filterBy]);

  if (isLoading) return <Loading />;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <Column className={'searchContainer'}>
      <SearchHeader
        placeholder={`Search shared processes...`}
        filterBy={filterBy}
        handleChangeFilter={(val: string) => {


          setFilterBy(val)
        }}
      />
      <AgGridReact
        className='ag-theme-alpine'
        ref={gridRef}
        rowData={(rowData ?? []).filter(filterFunc)}
        columnDefs={columnDefs}
        rowHeight={50}
        defaultColDef={{
          editable: false,
          sortable: true,
          minWidth: 200,
          filter: true,
          resizable: true,
        }}
      />
    </Column>
  );
}

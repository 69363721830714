import { useAuth0 } from '@auth0/auth0-react';
import { useReadUser, useUpdateUser } from './DataFetching/use-fetch-user';

export const useEnableStackSimulator = () => {
    const { user } = useAuth0();
    const userId = user?.sub;

    const { data: userData, isLoading: userDataIsLoading } = useReadUser(userId);
    const updateUserMutation = useUpdateUser();

    const enableStackSimulator = async (isEnabled: boolean) => {
        if (!userData || !userId) return;

        const updatedFeatures = { 
            ...userData.enabledFeatures, 
            stackSimulator: isEnabled 
        };
        
        updateUserMutation.mutate({ 
            userId, 
            data: { enabledFeatures: updatedFeatures } 
        });
    };

    return {
        userData,
        userDataIsLoading,
        enableStackSimulator
    };
};

import { Input, InputProps } from './Input';

export const CsvInput: React.FC<InputProps> = (props) => {
    return <Input
        {...props}
        warningText={"Invalid pattern format."}
        warningTitle={"Pattern must be comma-separated integers between 1-3 digits"}
        validation={(val: string) => {
            const matches = val.match(/^(?:\d{1,3}(?:(,?)\d{1,3})?(?:\1\d{1,3})*)$/g)
            return val === '' || matches?.length === 1;
        }}
    />
}
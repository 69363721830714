import {
    Label, 
    EditableText, 
    Dialog, 
    Button,
    Slider,
    Classes
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Tooltip2 } from "@blueprintjs/popover2";
import {useState, isValidElement} from 'react';

export interface SliderProps {
    id: string;
    label?: string;
    onChange?: (val: number) => void;
    inputButton?: JSX.Element;
    onChangeLabel?: (val: string) => void;
    infoContent?: string | JSX.Element;
    value?: number;
    maxSliderValue: number;
    stepSize: number;
    disabled?: boolean;
}


export const PercentageSlider: React.FC<SliderProps> = ({
    id,
    label, 
    onChange,
    inputButton,
    onChangeLabel,
    infoContent,
    value,
    maxSliderValue,
    stepSize,
    disabled
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    
    const toggleDialog = () => setIsDialogOpen(prev => !prev);

    let infoRender: JSX.Element = <></>;
    if (typeof infoContent === 'string') {
        infoRender = (
            <Tooltip2 content={<div style={{ width: '400px', whiteSpace: 'normal' }}>{infoContent}</div>}>
                <Button minimal icon={IconNames.INFO_SIGN} className={Classes.INPUT_ACTION}/>
            </Tooltip2>
        );
    } else if (isValidElement(infoContent)) {
        infoRender = (
            <>
                <Button 
                    minimal 
                    icon={IconNames.InfoSign}
                    className={Classes.INPUT_ACTION}
                    onClick={(e) => {
                        e.stopPropagation();  // This stops the click event from propagating to parent elements
                        toggleDialog();
                    }} 
                />
                <Dialog 
                    isOpen={isDialogOpen} 
                    onClose={toggleDialog}
                    title="Information"
                >
                    {infoContent}
                </Dialog>
            </>
        );
    }

    let pVal = Number(value);
    pVal = typeof pVal !== 'number' ? 0 : pVal;

    const maxValue = maxSliderValue ?? 100;
    const labelStepSize = maxValue / 2;
    const labelRenderer = (val: number) => Math.round(val).toString();

    return (
        <div>
            <Label key={id} className="righPanelLabel" style={{marginBottom: '5px'}}>
                {inputButton}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', }}>
                    {!onChangeLabel ? label : <EditableText value={label} onChange={(val) => {onChangeLabel(val)}} />}
                    <div style={{ display: 'flex', alignItems: 'center'}}>{infoRender}</div>
                </div>
            </Label>
            <Slider
                min={0}
                max={maxValue}
                stepSize={stepSize ?? 5}
                labelStepSize={labelStepSize}
                labelRenderer={labelRenderer}
                value={pVal}
                disabled={disabled}
                onChange={onChange}
            />
        </div>
    );
}

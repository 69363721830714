import { LayerProperties } from "../../Data/BloxSchema/base-blox";
import { Process, AdditionalOwnerUser } from "../../__generated__/Process";
import { v4 as uuidv4 } from 'uuid';
import { useFabuState } from "../../hooks/state/use-fabu-state";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

export function useSetProcess() {
    const [, setProcessBloxes] = useFabuState('processBloxes');
    const [, setProcessId] = useFabuState('processId');
    const [, setProcessName] = useFabuState('processName');
    const [, setProcessIsPrivate] = useFabuState('processIsPrivate');
    const [, setProcessAdditionalOwners] = useFabuState('processAdditionalOwners');
    const [, setProcessDesc] = useFabuState('processDesc');
    const [, setProcessReference] = useFabuState('processReference');
    const [, setProcessSections] = useFabuState('processSections');
    const [, setProcessIsReadOnly] = useFabuState('processIsReadOnly');
    const [, setProcessUsername] = useFabuState('processUsername');
    const [, setProcessGroups] = useFabuState('processGroups');
    const { user } = useAuth0();

    return useCallback((process: Process, isReadOnly?: boolean) => {
        const isOriginalOwner = process.userId && process.userId === user?.sub;
        const isAdditionalOwner = ((process.additionalOwners ?? []) as AdditionalOwnerUser[]).some(owner => owner.userId === user?.sub);
        const readOnly = !(isOriginalOwner || isAdditionalOwner) || isReadOnly;

        // backward comp
        if (process.bloxes[0].layers) {
            process.bloxes[0].layers = process.bloxes[0].layers.map((layer: LayerProperties) => {
                if (!layer.materialId) {
                    layer.materialId = uuidv4();
                }
                return layer;
            })
        }

        setProcessSections(process.sections);
        setProcessIsReadOnly(!!readOnly);
        setProcessBloxes(process.bloxes);
        setProcessName(process.processName);
        setProcessUsername(process.username);
        setProcessId(process.processId);
        setProcessIsPrivate(process.isPrivate);
        setProcessAdditionalOwners(process.additionalOwners ?? []);
        setProcessDesc(process.desc ?? '');
        setProcessReference(process.reference ?? '');
        setProcessGroups(process.groups ?? []);
    }, [user]);
}

import { useAuth0 } from '@auth0/auth0-react'
import { ReactElement, ReactNode, useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { Loading } from '../components/Loading'

interface ProtectedRouteProps extends RouteProps {
  children: ReactNode
  path: string
}

export const ProtectedRoute = ({ children, ...args }: ProtectedRouteProps): ReactElement => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return
    }

    (async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      })
    })()
  }, [isLoading, isAuthenticated, loginWithRedirect])

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    isAuthenticated ? <Route {...args}>{children}</Route> : <Loading/>
  )
}
import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";

export interface SubstrateStack extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    thickness: number | null;
    thicknessUnit: Units | null;

    // DISPLAY
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;
}

export const substrateStackDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposited Material",
        placeholder: "Enter material to deposit",
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Film thickness",
        placeholder: "Enter film thickness",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    
    // DISPLAY
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 4,
        tabs: [Tab.DISPLAY],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    sidewallThickness: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Side Wall Thickness",
        order: 3,
        tabs: [Tab.DISPLAY],
        defaultValue: 0
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 0,
        tabs: [Tab.DISPLAY],
    }
}

export const geteSubstrateStack = ({stepNumber}: BloxArgs): SubstrateStack => ({
        name: `Substrate Stack ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.SubstrateStack,
        material: null,
        thickness: null,
        thicknessUnit: Units.ANG,
        commentField: null,
        layerColor: {R: 10, G: 30, B: 210, A: 1},
        layerThickness: 10,
        sidewallThickness: 0,
        layerLabel: null,
        customFields: {},
});

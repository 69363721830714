import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'

import { Features } from '../components/Features'
import { WelcomeBox } from '../components/WelcomeBox'
import { TeamListing } from '../components/TeamListing'
import { EmailBox, TextBox } from '../components/TextBox'
import { Column } from '../Layout/layouts'
import develop from '../components/svg/samsung_tests/Develop.svg'
import develop2 from '../components/svg/samsung_tests/Develop2.svg'
import develop3 from '../components/svg/samsung_tests/Develop3.svg'
import develop4 from '../components/svg/samsung_tests/Develop4.svg'
import develop5 from '../components/svg/samsung_tests/Develop5.svg'
import develop6 from '../components/svg/samsung_tests/Develop6.svg'
import ALD from '../components/svg/ALD.svg'

export const SVGTestPage: React.FC = () => {
  return (
    <Column className={'searchContainer'}>
      <div style={{ "textAlign": "center", "marginTop": "40px", "marginBottom": "5%" }}>
      <div>SVG 1 (original):</div>
      <div className={"bloxBottom"}>
      <svg className="bloxSVG" viewBox="-1 0 102 102"><g><g><polygon points="-0.1,100 -0.1,87.8 100,87.8 100,100" style={{"fill": "rgb(255, 255, 255)", fillOpacity: 1}}></polygon></g><g><polygon points="-0.1,88 -0.1,77.8 100,77.8 100,88" style={{"fill": "rgb(183, 43, 206)", fillOpacity: 1}}></polygon></g><g><polygon points="-0.1,78 -0.1,67.8 100,67.8 100,78" style={{"fill": "rgb(112, 217, 112)", fillOpacity: 1}}></polygon></g></g><text x="50" y="94.5" fontSize="9" dominantBaseline="middle" fontFamily="sans-serif" textAnchor="middle">Substrate</text><text x="50" y="83.5" fontSize="9" dominantBaseline="middle" fontFamily="sans-serif" textAnchor="middle"></text><text x="50" y="73.5" fontSize="9" dominantBaseline="middle" fontFamily="sans-serif" textAnchor="middle"></text></svg>
      </div>
      </div>
     
      <div style={{ "textAlign": "center", "marginTop": "40px", "marginBottom": "5%" }}>
         <div>SVG 2 (Test #1):</div>
        <img style={{ "width": "10%" }} src={develop} alt="Develop" />
      </div>
      
      <div style={{ "textAlign": "center", "marginTop": "40px", "marginBottom": "5%" }}>
      <div>SVG 2 (Test #2):</div>
        <img style={{ "width": "10%" }} src={develop2} alt="Develop2" />
      </div>
      
      <div style={{ "textAlign": "center", "marginTop": "40px", "marginBottom": "5%" }}>
      <div>SVG 2 (Test #3):</div>
        <img style={{ "width": "10%" }} src={ALD} alt="ALD" />
      </div>
      
      <div style={{ "textAlign": "center", "marginTop": "40px", "marginBottom": "5%" }}>
      <div>SVG 2 (Test #4):</div>
        <img style={{ "width": "10%" }} src={develop3} alt="Develop3" />
      </div>
      
      <div style={{ "textAlign": "center", "marginTop": "40px", "marginBottom": "5%" }}>
      <div>SVG 2 (Test #5):</div>
        <img style={{ "width": "10%" }} src={develop4} alt="Develop4" />
      </div>
      
      <div style={{ "textAlign": "center", "marginTop": "40px", "marginBottom": "5%" }}>
      <div>SVG 2 (Test #6):</div>
        <img style={{ "width": "10%" }} src={develop5} alt="Develop5" />
      </div>
      
      <div style={{ "textAlign": "center", "marginTop": "40px", "marginBottom": "5%" }}>
      <div>SVG 2 (Test #7):</div>
        <img style={{ "width": "10%" }} src={develop6} alt="Develop6" />
      </div>
      
    </Column>
  )
}

import React, { useMemo } from 'react';
import { ItemRendererProps, Select } from "@blueprintjs/select";
import { MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useGroupProcesses, useOwnedProcesses } from '../hooks/DataFetching/use-fetch-process';
import { Process } from '../__generated__/Process';
import { AllBloxes } from '../Data/BloxSchema/base-blox';
import { ModuleInsertItem } from './ModuleDraggable';

interface ModuleItem {
    moduleName: string;
    moduleId: string;
    label: string;
    data: AllBloxes[];
    source: 'owned' | 'group';
}

const toModuleItem = (module: Process, source: 'owned' | 'group'): ModuleItem => {
    const withoutStartBloxLenght = module.bloxes.length - 1;
    const label = withoutStartBloxLenght + ' Blox';
    return {
        moduleName: module.processName,
        moduleId: module.processId,
        label: label,
        data: module.bloxes,
        source
    };
}

const renderModuleItem = (item: ModuleItem, { handleClick, modifiers }: ItemRendererProps) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }

    let icon = undefined;
    if (item.source === 'group') icon = IconNames.People;

    return (
        <MenuItem
            className='popover-menu-item'
            icon={icon}
            key={item.moduleId ?? item.moduleName}
            onClick={handleClick}
            text={item.moduleName}
            label={item.label}
            active={modifiers.active}
            disabled={modifiers.disabled}
        />
    );
};

const filterModuleItem = (query: string, item: ModuleItem) => {
    const lowerQuery = query.toLowerCase();
    return item.moduleName.toLowerCase().indexOf(lowerQuery) >= 0;
};

interface ModuleSelectProps {
    setIsOpen: (isOpen: boolean) => void;
    handleSelectModule: (item: ModuleInsertItem) => void;
    children: React.ReactNode;
}

export const SelectModule: React.FC<ModuleSelectProps> = ({ children, handleSelectModule, setIsOpen }) => {
    const { data: ownedModules } = useOwnedProcesses(true);
    const { data: allGroupModules } = useGroupProcesses(true);

    const groupModules = useMemo(() => {
        if (!allGroupModules || !ownedModules) return undefined;
        return allGroupModules.filter(groupModule => {
            return ownedModules.findIndex(ownedModule => ownedModule.processId === groupModule.processId) === -1;
        });
    }, [allGroupModules, ownedModules]);

    const moduleItems = useMemo(() => {
        const sourceOrder: {[key in ModuleItem['source']]: number} = {
            'owned': 1,
            'group': 2,
          };
          
          const sortModuleItems = (a: ModuleItem, b: ModuleItem): number => {
            if (sourceOrder[a.source] !== sourceOrder[b.source]) {
              return sourceOrder[a.source] - sourceOrder[b.source];
            }
            return a.moduleName.localeCompare(b.moduleName);
          };

        const items: ModuleItem[] = [
            ...(ownedModules ? ownedModules.map(module => toModuleItem(module, 'owned')) : []),
            ...(groupModules ? groupModules.map(module => toModuleItem(module, 'group')) : [])
        ].sort(sortModuleItems);

        return items;
    }, [ownedModules, groupModules]);

    return (
        <div>
            <Select<ModuleItem>
                items={moduleItems}
                itemRenderer={renderModuleItem}
                itemPredicate={filterModuleItem}
                onItemSelect={(item: ModuleItem) => handleSelectModule({ data: item.data, moduleId: item.moduleId, moduleName: item.moduleName })}
                filterable={true}
                popoverProps={{
                    onOpening: () => setIsOpen(true),
                    onClosing: () => setIsOpen(false),
                    minimal: true,
                }}
                inputProps={{ placeholder: 'Filter owned and group modules...' }}
            >
                {children}
            </Select>
        </div>
    );
};

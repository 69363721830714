import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";

export interface SpinCoatResist extends BaseBlox {
    resist: string | null;
    resistType: ResistType;
    spinSpeed: number | null;
    spinSpeedUnit: Units | null;
    spinTime: number | null;
    spinTimeUnit: Units | null;
    filmThickness: number | null;
    filmThicknessUnit: Units | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    layerLabel: string | null;
    fillGaps: boolean | null;
    //SEMIFAB
}

export enum ResistType {
    POSITIVE = "Positive",
    NEGATIVE = "Negative",
    IMAGEREVERSAL = "Image Reversal",
    LOR = "Lift-Off Resist (LOR)",
    ADHESION_PROMOTER = "Adhesion Promoter",
    NONE = "Select Resist Type"
}

export const spinCoatResistDisplay: DisplayMap = {
    
    resist: {
        fieldType: DisplayFieldTypes.Input,
        label: "Resist",
        placeholder: "Enter resist name",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    resistType: {
        fieldType: DisplayFieldTypes.Dropdown,
        label: "Resist Type",
        options: [ResistType.NONE, 
            ResistType.POSITIVE, 
            ResistType.NEGATIVE, 
            ResistType.IMAGEREVERSAL, 
            ResistType.LOR, 
            ResistType.ADHESION_PROMOTER], // if none selected default to positive
        order: 2,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    filmThickness: {
      fieldType: DisplayFieldTypes.Input,
      label: "Film Thickness",
      placeholder: "Enter film thickness",
      isNumber: true,
      order: 3,
      units: [Units.NM, Units.MICRON],
      tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    spinSpeed: {
        fieldType: DisplayFieldTypes.Input,
        label: "Spin Speed",
        placeholder: "Enter spin speed",
        isNumber: true,
        order: 4,
        units: [Units.RPM],
        tabs: [Tab.EXPERIMENTAL],
    },
    spinTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Spin Time",
        placeholder: "Enter spin time",
        isNumber: true,
        order: 5,
        units: [Units.SEC,Units.MIN],
        tabs: [Tab.EXPERIMENTAL],
    },

    //DISPLAY
    fillGaps: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Fill Gaps",
        order: 0,
        tabs: [Tab.DISPLAY,Tab.SEMIFAB],
        defaultValue: true,
        infoContent: "If toggled off, a uniform, conformal coating is used. If toggled on, a planar layer is deposited where the thickness is referenced from the highest point in the cross section."
    },
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 3,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 1,
        tabs: [Tab.DISPLAY],
    },
    //SEMIFAB
    
}

export const getSpinCoatResist = ({stepNumber}: BloxArgs): SpinCoatResist => ({
        name: `Spin Resist ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.SpinCoatResist,
        resist: null,
        resistType: ResistType.POSITIVE,
        spinSpeed: null,
        spinSpeedUnit: Units.RPM,
        spinTime: null,
        spinTimeUnit: Units.SEC,
        filmThickness: null,
        filmThicknessUnit: Units.MICRON,
        commentField: null,
        layerColor: {R: 208, G: 79, B: 79, A: 1},
        layerThickness: 10,
        layerLabel: null,
        fillGaps: true,
        customFields: { },
        //SEMIFAB
});

import {
    Dialog, 
    Button,
    Switch,
    Classes
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Tooltip2 } from "@blueprintjs/popover2";
import {useState, isValidElement} from 'react';

export interface LabeledSwitchProps {
    label?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeLabel?: (val: string) => void;
    infoContent?: string | JSX.Element;
    value?: number;
    disabled?: boolean;
    checked?: boolean;
}


export const LabeledSwitch: React.FC<LabeledSwitchProps> = ({
    label, 
    onChange,
    infoContent,
    disabled,
    checked
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    
    const toggleDialog = () => setIsDialogOpen(prev => !prev);

    let infoRender: JSX.Element = <></>;
    if (typeof infoContent === 'string') {
        infoRender = (
            <Tooltip2 content={<div style={{ width: '400px', whiteSpace: 'normal' }}>{infoContent}</div>}>
                <Button minimal icon={IconNames.INFO_SIGN} className={Classes.INPUT_ACTION}/>
            </Tooltip2>
        );
    } else if (isValidElement(infoContent)) {
        infoRender = (
            <>
                <Button 
                    minimal 
                    icon={IconNames.InfoSign}
                    className={Classes.INPUT_ACTION}
                    onClick={(e) => {
                        e.stopPropagation();  // This stops the click event from propagating to parent elements
                        toggleDialog();
                    }} 
                />
                <Dialog 
                    isOpen={isDialogOpen} 
                    onClose={toggleDialog}
                    title="Information"
                >
                    {infoContent}
                </Dialog>
            </>
        );
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Switch 
                disabled={disabled} 
                checked={checked} 
                label={label} 
                onChange={onChange} 
            />
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>{infoRender}</div>
        </div>
    );
}

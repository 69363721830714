import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";
import { ShadowmaskDialogContent } from "../../dialogs/ShadowMaskDialogContent";

export interface MBE extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    thickness: number | null;
    thicknessUnit: Units | null;
    growthRate: number | null;
    growthRateUnit: Units | null;
    growthTime: number | null;
    growthTimeUnit: Units | null;
    cellFluxes: string | null;
    cellTemps: number | null;
    subTemp: number | null;
    subTempUnit: Units | null;
    chamberPressure: number | null;
    chamberPressureUnit: Units | null;
    annealTime: number | null;
    annealTimeUnit: Units | null;
    annealTemp: number | null;
    annealTempUnit: Units | null;
    annealGasses: string | null;
    // DISPLAY
    selectiveGrowth : boolean | null;
    layerLabelsToGrow: string[] | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;
    hasShadowMask: boolean | null;
    shadowMaskPattern: string | null;
    shadowMaskPatternDisabled: () => boolean | null;
    shadowMaskInvertPattern: boolean;
}

export const mbeDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.Input,
        label: "Growth Material",
        placeholder: "Enter grown material",
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Film thickness",
        placeholder: "Enter grown film thickness",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    growthRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Growth Rate",
        placeholder: "Enter growth rate",
        isNumber: true,
        units: [Units.ANGPERSEC,Units.NMPERSEC,Units.UMPERMIN],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    growthTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Growth Time",
        placeholder: "Enter growth time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    cellTemps: {
        fieldType: DisplayFieldTypes.Input,
        label: "Cell Temperatures",
        placeholder: "List all cell temperatures",
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },
    cellFluxes: {
        fieldType: DisplayFieldTypes.Input,
        label: "Cell Fluxes",
        placeholder: "Enter cell fluxes/pressures",
        order: 6,
        tabs: [Tab.EXPERIMENTAL],
    },
    subTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Substrate Temperature",
        placeholder: "Enter substrate temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 7,
        tabs: [Tab.EXPERIMENTAL],
    },
    chamberPressure: {
        fieldType: DisplayFieldTypes.Input,
        label: "Chamber Base Pressure",
        placeholder: "Enter chamber pressure",
        isNumber: true,
        units: [Units.TORR,Units.PASCAL,Units.MILLIBAR,Units.BAR],
        order: 8,
        tabs: [Tab.EXPERIMENTAL],
    },
    annealTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Annealing Time",
        placeholder: "Enter anneal time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 9,
        tabs: [Tab.EXPERIMENTAL],
    },
    annealTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Annealing Temperature",
        placeholder: "Enter anneal temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 10,
        tabs: [Tab.EXPERIMENTAL],
    },
    annealGasses: {
        fieldType: DisplayFieldTypes.Input,
        label: "Annealing Gas Composition",
        placeholder: "Enter gas composition",
        order: 11,
        tabs: [Tab.EXPERIMENTAL],
    },
    
    // DISPLAY
    selectiveGrowth: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Selective/Epitaxial Growth",
        order: 1,
        tabs: [Tab.DISPLAY]
    },
    layerLabelsToGrow: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Growth Substrate Material",
        order: 2,
        tabs: [Tab.DISPLAY]
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 3,
        tabs: [Tab.DISPLAY],
    },
    sidewallThickness: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sidewall Thickness",
        order: 4,
        tabs: [Tab.DISPLAY],
        defaultValue: 100
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 5,
        tabs: [Tab.DISPLAY],
    },
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 6,
        tabs: [Tab.DISPLAY],
    },
    separator: {
        fieldType: DisplayFieldTypes.Separator,
        order: 10,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        isOptionalSemifab: true,
    },
    hasShadowMask: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Shadow Mask",
        order: 11,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        isOptionalSemifab: true,
    },
    shadowMaskPattern: {
        fieldType: DisplayFieldTypes.PatternInput,
        label: "Shadow Mask Pattern",
        order: 12,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: ShadowmaskDialogContent,
        isOptionalSemifab: true,
    },
    shadowMaskInvertPattern: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Invert Pattern",
        order: 13,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
}

export const getMBE = ({stepNumber}: BloxArgs): MBE => ({
        name: `MBE ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.MBE,
        material: null,
        thickness: null,
        thicknessUnit: Units.NM,
        growthRate: null,
        growthRateUnit: Units.ANGPERSEC,
        growthTime: null,
        growthTimeUnit: Units.MIN,
        cellTemps: null,
        cellFluxes: null,
        subTemp: null,
        subTempUnit: Units.CELSIUS,
        chamberPressure: null,
        chamberPressureUnit: Units.TORR,
        annealTime: null,
        annealTimeUnit: Units.MIN,
        annealTemp: null,
        annealTempUnit: Units.CELSIUS,
        annealGasses: null,
        commentField: null,
        customFields: {},
        layerColor: {R: 0, G: 125, B: 192, A: 1},
        layerThickness: 8,
        sidewallThickness: 20,
        layerLabel: null,
        selectiveGrowth: true,
        layerLabelsToGrow: [],
        hasShadowMask: false,
        shadowMaskPattern: "1,2,4,2,1",
        shadowMaskPatternDisabled: function () {
            return !this.hasShadowMask;
        },
        shadowMaskInvertPattern: false,
});

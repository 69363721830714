import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'

import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { useCallback, useRef, useState } from 'react'
import { BASE_FABUBLOX_API_URL } from '../utils/constants'
import { useAuth0 } from '@auth0/auth0-react'
import { ColDef, ModuleRegistry, ColumnState } from '@ag-grid-community/core'
import { SearchHeader } from '../components/SearchHeader'
import { AllBloxes } from '../Data/BloxSchema/base-blox'
import { Column } from '../Layout/layouts'
import { displayMap } from '../Data/display-mappings'
import { getValue } from '../utils/ts-helpers'
import { DisplayFieldTypes } from '../Data/enums'
import { DeleteRenderer } from '../components/CellRenderers/DeleteRenderer'

interface BloxListResult {
    bloxName: string
    bloxId: string
    bloxDescription: string
    bloxData: AllBloxes
    createdAt: string
    lastUpdatedAt: string
    groups: { id: string, groupName: string }[]
}

export const MyBlox: React.FC = () => {
    const [rowData, setRowData] = useState<BloxListResult[]>();
    const [filterBy, setFilterBy] = useState<string>();

    ModuleRegistry.registerModules([ClientSideRowModelModule])

    const [columnDefs] = useState<ColDef[]>([
        {
            headerName: 'Blox Name',
            field: 'bloxName',
            width: 200
        },
        {
            headerName: 'Groups',
            field: 'groups',
            width: 300,
            valueGetter: (props) => {
                if (!props?.node?.data?.groups?.length) {
                    return '';
                }
                const groupNames = props.node.data.groups.map((g: { id: string, groupName: string }) => g.groupName);
                return groupNames.join(', ');
            }
        },
        {
            headerName: 'Description',
            field: 'bloxDescription',
            flex: 1
        },
        {
            headerName: 'Last Updated',
            field: 'lastUpdatedAt',
            width: 200,
            valueFormatter: ({ value }) => value.substring(0, 10)
        },
        {
            headerName: 'Created On',
            field: 'createdAt',
            width: 200,
            valueFormatter: ({ value }) => value.substring(0, 10)
        },
        {
            headerName: '',
            minWidth: 50,
            maxWidth: 50,
            cellRenderer: DeleteRenderer,
            cellRendererParams: {
                deleteUri: 'blox/delete',
                idField: 'bloxId',
                nameField: 'bloxName'
            }
        }
    ]);

    const gridRef = useRef(null);
    const { getAccessTokenSilently } = useAuth0();

    const onGridReady = async (params: any) => {
        const token = await getAccessTokenSilently()
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }

        fetch(`${BASE_FABUBLOX_API_URL}/api/blox/owned`, { headers })
            .then((res) => res.json())
            .then(
                (results) => {
                    setRowData(results ?? [])
                },
                (error) => {
                    // TODO handle errors
                    console.log('ERROR!')
                    console.log(error)
                }
            )

        const defaultSortModel: ColumnState[] = [
            { colId: 'lastUpdatedAt', sort: 'desc', sortIndex: 0 },
        ]
        params.columnApi.applyColumnState({ state: defaultSortModel });
    }

    const searchInBlox = (blox: BloxListResult, searchKeyword: string): boolean => {

        if (blox.bloxName.toLowerCase().match(searchKeyword)) {
            return true
        }

        if (blox.bloxDescription?.toLowerCase().match(searchKeyword)) {
            return true
        }

        const bloxData = blox.bloxData

        if (bloxData.toolName?.toLowerCase().match(searchKeyword)) {
            return true
        }

        // all parameters that are displayed
        let foundProperty = false;
        Object.keys(bloxData)
            .filter((property) => displayMap[bloxData.bloxType][property])
            .filter(
                (property) =>
                    displayMap[bloxData.bloxType][property].fieldType ===
                    DisplayFieldTypes.Input ||
                    displayMap[bloxData.bloxType][property].fieldType ===
                    DisplayFieldTypes.Dropdown ||
                    displayMap[bloxData.bloxType][property].fieldType ===
                    DisplayFieldTypes.Multiline ||
                    displayMap[bloxData.bloxType][property].fieldType ===
                    DisplayFieldTypes.EditableText
            )
            .forEach((property) => {
                const value = getValue(property, bloxData)
                if (value?.toString().toLowerCase().match(searchKeyword)) {
                    foundProperty = true
                }
            })
        if (foundProperty) {
            return true
        }

        return false
    }

    const filterFunc = useCallback(
        (row: BloxListResult) => {
            if (!filterBy) {
                return true
            }

            // TODO: regex the seperator
            let allKeywordsExist = true
            filterBy
                .trim()
                .toLowerCase()
                .split(' ')
                .forEach((filterVal) => {
                    let keywordExists = false

                    // check blox name
                    keywordExists = keywordExists || !!row.bloxName.toLowerCase().match(filterVal);

                    // check all bloxes and their properties
                    keywordExists = keywordExists || searchInBlox(row, filterVal);

                    allKeywordsExist = allKeywordsExist && keywordExists;
                })

            return allKeywordsExist
        },
        [filterBy]
    );

    return (
        <Column className={'searchContainer'}>
            <SearchHeader
                placeholder={`Search your bloxes...`}
                filterBy={filterBy}
                handleChangeFilter={(val: string) => {
                    setFilterBy(val);
                }}
            />
            <AgGridReact
                className='ag-theme-alpine'
                ref={gridRef}
                rowData={(rowData ?? []).filter(filterFunc)}
                columnDefs={columnDefs}
                rowHeight={50}
                defaultColDef={{
                    editable: false,
                    sortable: true,
                    minWidth: 200,
                    filter: true,
                    resizable: true,
                }}
                onGridReady={onGridReady}
            />
        </Column>
    );
}

import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";

export type CustomBlox = BaseBlox

export const customBloxDisplay: DisplayMap = {
}

export const getCustomBlox = ({stepNumber}: BloxArgs): CustomBlox => ({
        name: `Custom Step ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.CustomBlox,
        commentField: null,
        customFields: {}
    }
);

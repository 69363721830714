import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";

export interface MOCVD extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    thickness: number | null;
    thicknessUnit: Units | null;
    growthRate: number | null;
    growthRateUnit: Units | null;
    growthTime: number | null;
    growthTimeUnit: Units | null;
    subTemp: number | null;
    subTempUnit: Units | null;
    precursors: string | null;
    precursorFlow: string | null;
    chamberPressure: number | null;
    chamberPressureUnit: Units | null;
    annealTime: number | null;
    annealTimeUnit: Units | null;
    annealTemp: number | null;
    annealTempUnit: Units | null;
    annealGasses: string | null;
    // DISPLAY
    doubleSided: boolean | null;
    selectiveGrowth : boolean | null;
    layerLabelsToGrow: string[] | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;
    // SEMIFAB
    isConformal: boolean | null;
    angularDist: number | null;
    angularDistUnit: Units | null;
    stepSize: number | null;
    stepSizeUnit: Units | null;
    surfaceInterp: number | null;
    surfaceInterpUnit: Units | null;
    targetAngle: number | null;
    targetAngleUnit: Units | null;
    isotropyRatio: number | null;
    maxGap: number | null;
    maxGapUnit: Units | null;
}

export const moCVDDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.Input,
        label: "Growth Material",
        placeholder: "Enter material to deposit",
        order: 2,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Film thickness",
        placeholder: "Enter grown film thickness",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 3,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    growthRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Growth Rate",
        placeholder: "Enter growth rate",
        isNumber: true,
        units: [Units.ANGPERSEC,Units.NMPERSEC,Units.UMPERMIN],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    growthTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Growth Time",
        placeholder: "Enter growth time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },
    subTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Substrate Temperature",
        placeholder: "Enter substrate temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 6,
        tabs: [Tab.EXPERIMENTAL],
    },
    precursors: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursors",
        placeholder: "Enter precursor names",
        order: 7,
        tabs: [Tab.EXPERIMENTAL],
    },
    precursorFlow: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursor Flows",
        placeholder: "Enter precursor flows",
        order: 8,
        tabs: [Tab.EXPERIMENTAL],
    },
    chamberPressure: {
        fieldType: DisplayFieldTypes.Input,
        label: "Chamber Base Pressure",
        placeholder: "Enter chamber pressure",
        isNumber: true,
        units: [Units.TORR,Units.PASCAL,Units.MILLIBAR,Units.BAR],
        order: 9,
        tabs: [Tab.EXPERIMENTAL],
    },
    annealTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Annealing Time",
        placeholder: "Enter anneal time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 10,
        tabs: [Tab.EXPERIMENTAL],
    },
    annealTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Annealing Temperature",
        placeholder: "Enter anneal temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 11,
        tabs: [Tab.EXPERIMENTAL],
    },
    annealGasses: {
        fieldType: DisplayFieldTypes.Input,
        label: "Annealing Gas Composition",
        placeholder: "Enter gas composition",
        order: 12,
        tabs: [Tab.EXPERIMENTAL],
    },
    
    // DISPLAY
    doubleSided: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Two-Sided Deposition",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    selectiveGrowth: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Selective/Epitaxial Growth",
        order: 1,
        tabs: [Tab.DISPLAY]
    },
    layerLabelsToGrow: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Growth Substrate Material",
        order: 2,
        tabs: [Tab.DISPLAY]
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 3,
        tabs: [Tab.DISPLAY],
    },
    sidewallThickness: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sidewall Thickness",
        order: 4,
        tabs: [Tab.DISPLAY],
        defaultValue: 100
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 5,
        tabs: [Tab.DISPLAY],
    },
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 6,
        tabs: [Tab.DISPLAY],
    },
      // SEMIFAB
      isConformal: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Conformal",
        order: 0,
        tabs: [Tab.SEMIFAB],
    },
    angularDist: {
        fieldType: DisplayFieldTypes.Input,
        label: "Maximum Deposition Angle",
        placeholder: "Enter maximum deposition angle",
        isNumber: true,
        units: [Units.DEG],
        order: 7,
        tabs: [Tab.SEMIFAB],
    },
    stepSize: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Step Size",
        placeholder: "Enter deposition step size",
        isNumber: true,
        units: [Units.ANG, Units.NM, Units.MICRON],
        order: 7,
        tabs: [Tab.SEMIFAB],
    },
    surfaceInterp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Surface Interpolation",
        placeholder: "Enter surface interpolation distance",
        isNumber: true,
        units: [Units.ANG, Units.NM, Units.MICRON],
        order: 7,
        tabs: [Tab.SEMIFAB],
    },
    targetAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Target Angle",
        placeholder: "Enter target angle",
        isNumber: true,
        units: [Units.DEG],
        order: 7,
        tabs: [Tab.SEMIFAB],
    },
    isotropyRatio: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Isotropy Ratio",
        order: 6,
        tabs: [Tab.SEMIFAB],
        defaultValue: 0
    },
    maxGap: {
        fieldType: DisplayFieldTypes.Input,
        label: "Maximum Gap to Fill",
        placeholder: "Enter the maximum gap to fill",
        isNumber: true,
        units: [Units.ANG, Units.NM, Units.MICRON],
        order: 7,
        tabs: [Tab.SEMIFAB],
    },
}

export const getMOCVD = ({stepNumber}: BloxArgs): MOCVD => ({
        name: `MOCVD/MOVPE ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.MOCVD,
        material: null,
        thickness: null,
        thicknessUnit: Units.NM,
        growthRate: null,
        growthRateUnit: Units.ANGPERSEC,
        growthTime: null,
        growthTimeUnit: Units.MIN,
        precursors: null,
        precursorFlow: null,
        subTemp: null,
        subTempUnit: Units.CELSIUS,
        chamberPressure: null,
        chamberPressureUnit: Units.TORR,
        annealTime: null,
        annealTimeUnit: Units.MIN,
        annealTemp: null,
        annealTempUnit: Units.CELSIUS,
        annealGasses: null,
        commentField: null,
        customFields: {},
        layerColor: {R: 255, G: 127, B: 80, A: 1},
        layerThickness: 8,
        sidewallThickness: 80,
        layerLabel: null,
        doubleSided: false,
        selectiveGrowth: false,
        layerLabelsToGrow: [],
        // SEMIFAB
        isConformal: true,
        angularDist: 80,
        angularDistUnit: Units.DEG,
        stepSize: null,
        stepSizeUnit: Units.NM,
        surfaceInterp: null,
        surfaceInterpUnit: Units.NM,
        targetAngle: 0,
        targetAngleUnit: Units.DEG,
        isotropyRatio: 0,
        maxGap: null,
        maxGapUnit: Units.NM
});

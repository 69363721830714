import { ICellRendererParams } from '@ag-grid-community/core';
import { Button, Intent, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useState, useCallback } from 'react';
import { DeleteDialog } from '../../dialogs/DeleteDialog';

export interface DeleteCellRendererParams extends ICellRendererParams {
    deleteUri: string,
    idField: string,
    nameField: string
}

export const DeleteRenderer: React.FC<DeleteCellRendererParams> = ({ data, api, node, deleteUri, idField, nameField }) => {
    const name = node.data[nameField];
    const id = node.data[idField];

    const [isOpen, setIsOpen] = useState(false);
    const setIsOpenCallback = useCallback(() => setIsOpen(true), []);
    const closeDialogCallback = useCallback(() => setIsOpen(false), []);
    const deleteCallback = useCallback(() => api.applyTransaction({ remove: [data] }), [data, api]);

    const deleteDialogProps = {
        deleteUri,
        name,
        id,
        isOpen,
        onDelete: deleteCallback,
        closeDialog: closeDialogCallback
    };

    return <>
        <Button onClick={setIsOpenCallback} intent={Intent.DANGER} minimal={true}><Icon icon={IconNames.CROSS}></Icon></Button>
        <DeleteDialog {...deleteDialogProps} />
    </>
};
import React from 'react';

export interface LayoutProps {
    id?: string;
    ref?: any,
    width?: string;
    padding?: string;
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;  
    onDoubleClick?: () => void;  
    children?: React.ReactNode;
}

export const Row: React.FC<LayoutProps> = (props) => {
    return <Cell {...props} className={`${props.className ? props.className + " " : ""}Row`} />
}

export const Column: React.FC<LayoutProps> = (props) => {
    return <Cell {...props} className={`${props.className ? props.className + " " : ""}Column`}  />
}

export const Cell: React.FC<LayoutProps> = (props) => {
    return <div
        id={props.id}
        onClick={props.onClick}
        onDoubleClick={props.onDoubleClick}
        style={{
            width: props.width,
            padding: props.padding,
            ...props.style
        }}
        className={props.className ?? ""}>
        {props.children}
    </div>
}
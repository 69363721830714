import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory: React.FC<{children: ReactNode}> = (props) => {
    const { children } = props;
    const history = useHistory();
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    if (!domain || !clientId)
        throw new Error("Missing auth configuration");

    const onRedirectCallback = (appState: any) => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          redirectUri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
          audience={audience}
          scope="openid profile email"
        >
          {children}
        </Auth0Provider>
      );
    };

export default Auth0ProviderWithHistory;

import { useState } from 'react';
import { BloxTypes } from '../Data/BloxSchema/base-blox';
import { Column, Row } from '../Layout/layouts';
import { BloxSvg } from './svg/BloxSvg';
import { Icon, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

export interface BloxImageProps {
    bloxType: BloxTypes;
    selected: boolean;
    noDrag: boolean;
    stepNumber: number;
    displayName?: string;
    toolName?: string;
    moduleName?: string;
    moduleId?: string;
}

export const BloxImage: React.FC<BloxImageProps> = (props) => {
    const { bloxType, displayName, toolName, selected, noDrag, moduleName, moduleId, stepNumber } = props;
    const [inTooltip, setInTooltip] = useState(false);
    const [inTarget, setInTarget] = useState(false);

    const labelToolTip = (
        <div onMouseEnter={() => setInTooltip(true)} onMouseLeave={() => setInTooltip(false)}>
            Blox Name: {displayName}
            <br />
            Tool Name: {toolName ?? <i>No tool selected</i>}
            {moduleName && moduleId && (
                <>
                    <br/>
                    <a href={`/module-editor/${moduleId}`} target="_blank" rel="noopener noreferrer"><Icon icon={IconNames.Share}/>{' ' + moduleName}</a>
                </>
            )}
        </div>
    );

    const spaceForStepNumber = displayName && displayName.length > 15 ? 18 : 0;
  

    return (

        <Column className={`bloxTop ${selected ? 'selected' : ''} ${noDrag ? 'noDrag' : ''}`}>
            { bloxType === BloxTypes.StartBlox ? (
                <Column style={{height: '100%', width: '100%'}}>
                    <Column style={{ margin: 'auto' }}>
                        <BloxSvg bloxType={bloxType} className='blox-library-image'/>
                        <span className='processStart'>Start</span>
                    </Column>
                </Column>
            ) : (<>
                <Tooltip isOpen={inTooltip || inTarget} content={labelToolTip} hoverOpenDelay={200} position={Position.TOP}>
                    <div onMouseEnter={() => setInTarget(true)} onMouseLeave={() => setTimeout(() => setInTarget(false), 500)}>
                        <Row><span style={{fontSize: 'smaller'}}>{stepNumber}</span><span style={{paddingLeft: spaceForStepNumber}} className='library-label'>{displayName}</span></Row>
                        {toolName && (
                                <strong className={"library-label"}>{toolName}</strong>
                        )}
                    </div>
                </Tooltip>

                <BloxSvg bloxType={bloxType} className='blox-library-image'/>
                </>
            ) }
        </Column>
        );
};


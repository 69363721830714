import { ICellRendererParams } from '@ag-grid-community/core';
import { stackChangeMap } from '../Data/stack-change-mappings';
import { SVGDisplayMode } from '../Services/SVGEngine';
import { useContext, useEffect, useState } from 'react';
import { SvgServiceContext } from '../hooks/state/svg-service-provider';
import { Loading } from '../components/Loading';
import { BloxesAndThumbnail } from '../pages/SearchPage';

export const ProcessPreviewRenderer = (props: ICellRendererParams) => {
    const {bloxes, thumbnail} = props.value as BloxesAndThumbnail;
    const threeDim = false;
    let stackChangeCount = 0;

    for (const { bloxType } of bloxes) {
        const stackChange = stackChangeMap[bloxType];
        if (stackChange) stackChangeCount += stackChange.length;
    }

    const { generateSvgs } = useContext(SvgServiceContext);
    const [loading, setLoading] = useState(true);
    const [svgIcon, setSvgIcon] = useState<JSX.Element | null>(null);

    const rowIndex = props.rowIndex;
    useEffect(() => {
        setLoading(true);

        const timer = setTimeout(() => {
            let svg = <></>;
            if (!thumbnail) {
                const svgs = generateSvgs(bloxes, threeDim, SVGDisplayMode.Thumbnail);
                const hasSvgs = stackChangeCount > 0 && svgs.length > 0;
                svg = hasSvgs ? svgs[svgs.length - 1] : <></>;
            } else {
                svg = <div dangerouslySetInnerHTML={{ __html: thumbnail }} />

            }
            setSvgIcon(svg);
            setLoading(false);
         }, 0);

        return () => clearTimeout(timer); // Clear the timeout if the component unmounts
    }, [bloxes, generateSvgs, stackChangeCount, threeDim, rowIndex, thumbnail]);

    return (
        <div style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {loading ? <Loading size={5} /> : svgIcon}
        </div>
    );
};

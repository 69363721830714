import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { DisplayFieldTypes, Tab, Units } from "../enums";

export interface Wetetch extends BaseBlox {
    // to do user select number of cleaning steps
    doubleSided: boolean | null;
    layerLabelsToEtch: string[] | null;
    etchChemical: string | null;
    etchDepth: number | null;
    etchDepthUnit: Units | null;
    etchRate: number | null;
    etchRateUnit: Units | null;
    etchTime: number | null;
    etchTimeUnit: Units | null;
    etchTemp: number | null;
    etchTempUnit: Units | null;
    percentToEtch: number | null;
    isotropicEtch: boolean | null;
    sidewaysEtch: number | null;
    keepFloatingStructures: boolean | null;
    etchBuriedLayers: boolean | null;
    // SEMIFAB
    sidewaysEtchDepth: number | null;
    sidewaysEtchDepthUnit: Units | null;
    sidewaysEtchDepthDisabled: () => boolean | null;
    layerLabelsToFloat: string[] | null;
    layerLabelsToFloatDisabled: () => boolean | null;
}

export const wetetchDisplay: DisplayMap = {
    // DISPLAY
    doubleSided: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Two-Sided Etch",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerLabelsToEtch: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Etch",
        order: 1,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    percentToEtch: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Etch Depth",
        order: 2,
        tabs: [Tab.DISPLAY],
        defaultValue: 100,
        stepSize: 2
    },
    sidewaysEtch: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sideways Etch",
        order: 3,
        tabs: [Tab.DISPLAY],
        defaultValue: 0,
        stepSize: 2
    },
    isotropicEtch: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Isotropic Etch",
        order: 4,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB, Tab.EXPERIMENTAL],
        defaultValue: false
    },
    keepFloatingStructures: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Keep Floating Structures",
        order: 5,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        defaultValue: false
    },
    etchBuriedLayers: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Etch Buried Layers",
        order: 6,
        tabs: [Tab.DISPLAY],
        defaultValue: false
    },

    // EXPERIMENTAL
    etchChemical: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etching Agent(s)",
        placeholder: "Enter chemical name(s)",
        order: 0,
        tabs: [Tab.EXPERIMENTAL],
    },
    etchDepth: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Depth",
        placeholder: "Enter etch depth",
        isNumber: true,
        order: 1,
        units: [Units.ANG,Units.NM,Units.MICRON,Units.MM],
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    etchRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Rate",
        placeholder: "Enter etch rate",
        isNumber: true,
        units: [Units.ANGPERSEC,Units.NMPERSEC,Units.NMPERMIN,Units.UMPERMIN,Units.UMPERHOUR],
        order: 9,
        tabs: [Tab.EXPERIMENTAL],
    },
    etchTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Time",
        placeholder: "Enter cleaning time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 10,
        tabs: [Tab.EXPERIMENTAL],
    },
    etchTemp: {
        fieldType: 1,
        label: "Etch Temperature",
        placeholder: "Enter cleaning temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 11,
        tabs: [Tab.EXPERIMENTAL],
    },
    // SEMIFAB
    sidewaysEtchDepth: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sideways Etch Distance",
        placeholder: "Enter sidways etch distance",
        isNumber: true,
        order: 8,
        units: [Units.ANG,Units.NM,Units.MICRON,Units.MM],
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    layerLabelsToFloat: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Float",
        order: 9,
        tabs: [Tab.SEMIFAB],
        isOptionalSemifab: true,
    },
}

export const getWetEtch = ({stepNumber}: BloxArgs): Wetetch => ({
        name: `Wet-Etch ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.Wetetch,
        doubleSided: false,
        etchChemical: null,
        etchDepth: null,
        etchDepthUnit: Units.NM,
        etchRate: null,
        etchRateUnit: Units.NMPERSEC,
        etchTime: null,
        etchTimeUnit: Units.SEC,
        etchTemp: null,
        etchTempUnit: Units.CELSIUS,
        commentField: null,
        customFields: {},
        layerLabelsToEtch: [],
        percentToEtch: 30,
        isotropicEtch: true,
        sidewaysEtch: 0,
        keepFloatingStructures: false,
        etchBuriedLayers: false,
        // SEMIFAB
        sidewaysEtchDepth: 0,
        sidewaysEtchDepthUnit: Units.NM,
        sidewaysEtchDepthDisabled: function () {
            return this.isotropicEtch ?? false;
        },
        layerLabelsToFloat: [],
        layerLabelsToFloatDisabled: function () {
            return !this.keepFloatingStructures ?? false;
        },
    }
);

import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { PatternDialogContent } from "../../dialogs/PatternDialogContent";


export interface MaskAlign extends BaseBlox {
    wavelength: number | null;
    intensity: number | null;
    intensityUnit: Units | null;
    expTime: number | null;
    expTimeUnit: Units | null;
    expDose: number | null;
    expDoseUnit: Units | null;
    layerPattern: string | null;
    layerInvertPattern: boolean;
    // SEMIFAB
    layerLabelsToExpose: string[] | null;
    sidewallAngle: number | null;
    sidewallAngleUnit: Units | null;
}

export const maskAlignDisplay: DisplayMap = {
    wavelength: {
        fieldType: DisplayFieldTypes.Dropdown,
        label: "Lamp wavelength",
        options: ["Select Wavelength","13.5nm (EUV)","193nm (DUV)","247nm (DUV)","365 nm (i-line)", "375nm", "405 nm (h-line)", "435 nm (g-line)"],
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    expDose: {
        fieldType: DisplayFieldTypes.Input,
        label: "Exposure dose",
        placeholder: "Enter exposure dose",
        isNumber: true,
        units: [Units.MILLIJOULPERSQCM],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    intensity: {
        fieldType: DisplayFieldTypes.Input,
        label: "Lamp intensity",
        placeholder: "Enter lamp intensity",
        isNumber: true,
        units: [Units.MWPERSQCM],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    expTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Exposure time",
        placeholder: "Enter exposure time",
        isNumber: true,
        units: [Units.SEC,Units.MIN],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },

    // DISPLAY
    layerPattern: {
        fieldType: DisplayFieldTypes.PatternInput,
        label: "Exposure Pattern",
        placeholder: "e.g. 1,3,4,3,1",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        infoContent: PatternDialogContent
    },
    layerInvertPattern: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Invert Pattern",
        order: 1,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    // SEMIFAB
    layerLabelsToExpose: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Expose",
        order: 0,
        materialFilter: "ONLY_RESIST",
        tabs: [Tab.SEMIFAB],
    },
    sidewallAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Sidewall Angle",
        placeholder: "Enter etch sidewall angle",
        isNumber: true,
        order: 6,
        units: [Units.DEG],
        tabs: [Tab.SEMIFAB],
        infoContent: "In FabuBlox, 0° refers to a perfectly vertical sidewall. Use negative values to slope outwards, and positive values to slope inwards."
    },
}

export const getMaskAlign = ({stepNumber}: BloxArgs): MaskAlign => ({
        name: `Mask-Align Lithography ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.MaskAlign,
        wavelength: null,
        intensity: null,
        intensityUnit: Units.MWPERSQCM,
        expTime: null,
        expTimeUnit: Units.SEC,
        expDose: null,
        expDoseUnit: Units.MILLIJOULPERSQCM,
        commentField: null,
        layerPattern: "1,2,4,2,1",
        layerInvertPattern: false,
        customFields: {},
        //SEMIFAB
        layerLabelsToExpose: [],
        sidewallAngle: 0,
        sidewallAngleUnit: Units.DEG,
    }
);

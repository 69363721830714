import { FabuPage, PageNames } from "../components/FabuPage"
import { UnsavedChangesDialog } from "../dialogs/UnsavedChangesDialog"
import { useContext, useEffect, useState } from "react"
import { ModuleEditor } from "../components/editors/ModuleEditor"
import { ProcessInfo } from "../components/ProcessInfo"
import { useHistory, useParams } from "react-router-dom"
import { Loading } from "../components/Loading"
import { useClearProcess } from "../components/hooks/use-clear-process"
import { useSetProcess } from "../components/hooks/use-set-process"
import { useReadProcess } from "../hooks/DataFetching/use-fetch-process"
import { CreateProcessDialog } from "../dialogs/CreateProcessDialog"
import { SaveYourChangesDialog } from "../dialogs/SaveYourChangesDialog"
import { ProcessHeader } from "../components/ProcessHeader"
import { ProcessSettingsContext } from "../hooks/state/process-settings-provider"

export const ModuleEditorPage = () => {
    const { processId } = useParams<{processId: string}>();

    const [ moduleUnsavedChanges, setModuleUnsavedChanges ] = useState(false);
    const { isProcessInfoOpen } = useContext(ProcessSettingsContext);
    const { data: module, isSuccess, isLoading, isError } = useReadProcess(processId, true);

    const clearProcess = useClearProcess();    
    const setProcess = useSetProcess();
    const history = useHistory();
    

    useEffect(() => {
      if (!processId || processId === 'create') {
        clearProcess();
        return;
      }

      if (module && isSuccess) {
        setProcess(module);
        setModuleUnsavedChanges(false);
      }
      
      
    }, [clearProcess, setProcess, module, processId, isSuccess]);

    useEffect(() => {
        if (isError) {
            clearProcess();
            history.push('/module-editor/new');
        }

        return () => {
          clearProcess();
      }
    }, [isError, history, clearProcess]);

    if (isError) return <div>Failed to load module</div>;
    if (isLoading) return <Loading />;

    return (
        <>
                <UnsavedChangesDialog hasUnsavedChanges={moduleUnsavedChanges} />
                <FabuPage
                    pageName={PageNames.ModuleEditor}
                    headerContent={<ProcessHeader isModule={true} />}
                >
                    <CreateProcessDialog isModule={true}/>
                    <SaveYourChangesDialog />
                    <ProcessInfo isModule={true} isOpen={isProcessInfoOpen} />
                    <ModuleEditor />
                </FabuPage>
        </>
    );
}



import React, { useCallback, useEffect, useState } from "react";
import { Row } from "../Layout/layouts";
import { useMutation, useQuery } from 'react-query';
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Callout } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { BASE_FABUBLOX_API_URL } from "../utils/constants";

// Function to join group
const joinGroup = async (groupId: string, token: string, signature: string) => {
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/group/join`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ groupId, signature })
    });

    if (!response.ok) {
        throw new Error('Failed to join group');
    }
    return response.json();
}

export const JoinGroupPage: React.FC = () => {
    const [groupName, setGroupName] = useState<string | null>(null);
    const [alreadyInGroup, setAlreadyInGroup] = useState<boolean>(false);
    const urlParams = new URLSearchParams(window.location.search);
    const groupId = urlParams.get("groupId");
    const groupNameParam = urlParams.get("groupName");
    const signature = urlParams.get("signature") ?? '';

    const { getAccessTokenSilently } = useAuth0();
    const history = useHistory();

    const fetchMyGroups = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/groups/myGroups`, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch groups');
        }
        return response.json();
    }, [getAccessTokenSilently]);

    const { data: myGroups, isLoading: isLoadingGroups} = useQuery('myGroups', fetchMyGroups);

    useEffect(() => {
        // Check if user is already in the group
        if (myGroups && myGroups.some((group: {id: string, groupName: string}) => group.id === groupId)) {
            setAlreadyInGroup(true);
        }
    }, [myGroups, groupId]);

    const mutation = useMutation((groupId: string) => 
        getAccessTokenSilently().then(token => 
            joinGroup(groupId, token, signature)
        )
    );

    useEffect(() => {
        if (groupNameParam) {
            setGroupName(decodeURIComponent(groupNameParam));
        }
    }, [groupNameParam]);

    const handleJoinClick = useCallback(() => {
        if (groupId) {
            mutation.mutate(groupId);
        }
    }, [groupId, mutation]);

    const handleGroupProcessesClick = useCallback(() => {
        history.push(`/my-group-processes`);
    }, [history]);

    const calloutStyle = {height: '80px', marginTop: '50px'}

    return (
        <Row style={{ "height": "calc(100% - 50px)", "justifyContent": "center" }}>
            {mutation.isLoading && !isLoadingGroups && (
                <Callout style={calloutStyle} icon="refresh" intent="primary" title="Joining group...">
                    Please wait a moment.
                </Callout>
            )}
    
            {mutation.isError && !isLoadingGroups && (
                <Callout style={calloutStyle} icon="error" intent="danger" title="Error joining group">
                    {(mutation.error as Error).message}
                </Callout>
            )}
    
            {((mutation.isSuccess && !isLoadingGroups ) || alreadyInGroup) && (
                <Callout style={calloutStyle} icon="tick" intent="success" title={`You are a member of ${groupName}.`}>
                    <Button onClick={handleGroupProcessesClick}>Go to Group Processes</Button>
                </Callout>
            )}
    
            {!mutation.isLoading && !mutation.isError && !mutation.isSuccess && !isLoadingGroups && !alreadyInGroup && (
                <div style={{ margin: '10px auto auto auto' }}>
                    <Callout intent="none" title={'Join Group'}>
                        <p>You are joining <strong>{groupName}</strong></p>
                        <p>See all processes shared with <strong>{groupName}</strong> in the Group Processes list.<br/>
                        Share processes you create using the Share tab in the Process Editor.<br/>
                        Share single tool recipes you saved in "My Blox" in the Blox Library.</p>
                    <Row>
                        <Button style={{margin: 'auto 10px auto auto'}} intent="primary" text={'Join'} onClick={handleJoinClick} large={true}/>
                    </Row>
                    </Callout>
                </div>
            )}
        </Row>
    );
    
    
};



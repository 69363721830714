import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'

import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { useCallback, useRef, useEffect, useState, useMemo } from 'react'
import { ColDef, ModuleRegistry } from '@ag-grid-community/core'
import { Link } from 'react-router-dom'
import { SearchHeader } from '../components/SearchHeader'
import { AllBloxes } from '../Data/BloxSchema/base-blox'
import { Column } from '../Layout/layouts'
import { ProcessPreviewRenderer } from '../list/ProcessPreviewRenderer'
import { usePublicProcesses } from '../hooks/DataFetching/use-fetch-process'
import { Loading } from '../components/Loading'
import { searchProcessAndBlox } from '../utils/search-process-and-blox'

export interface BloxesAndThumbnail {
  bloxes: AllBloxes[]
  thumbnail?: string
}

export interface ProcessListResult {
  processName: string
  processId: string
  bloxesInfo: BloxesAndThumbnail
  createdAt: string
  lastUpdatedAt: string
}

export const SearchPage: React.FC = () => {
  const { data, error, isLoading } = usePublicProcesses();
  const rowData: ProcessListResult[] | undefined = useMemo(() => {
    if (!data) return undefined;
    return data.map((process) => {

      return {
        processName: process.processName,
        processId: process.processId,
        bloxesInfo: { bloxes: process.bloxes, thumbnail: process.thumbnail },
        username: process.username,
        createdAt: process.createdAt,
        lastUpdatedAt: process.lastUpdatedAt,
      };
    })
  }, [data]);
  const [filterBy, setFilterBy] = useState<string>()

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const keywordParam = queryParams.get('keyword')
    if (keywordParam) {
      setFilterBy(keywordParam);
    }
  }, [])

  ModuleRegistry.registerModules([ClientSideRowModelModule])

    const [columnDefs] = useState<ColDef[]>([
        {
            headerName: '',
            field: 'bloxesInfo',
            minWidth: 90,
            width: 90,
            maxWidth:90,
            cellRenderer: ProcessPreviewRenderer,
            resizable: true,
            sortable: false,
            filter: false,
        },
        {
            headerName: 'Process Name',
            field: 'processName',
            flex: 1,
            cellRenderer: (props: any) => <Link to={`/process-editor/${props?.node?.data?.processId}`}>{ props.value }</Link>
        },
        {
            headerName: 'Creator',
            field: 'username'
        },
        {
            headerName: 'No. of Steps',
            minWidth: 70,
            width: 135,
            valueGetter: (props) => {
              const bloxes = props?.node?.data?.bloxesInfo?.bloxes;
              return bloxes ? bloxes.length : 0;
            },
            type: 'numericColumn'
        },
        {
            headerName: 'Last Updated',
            field: 'lastUpdatedAt',
            valueFormatter: ({value}) => value.substring(0, 10),
            sort: 'desc',
        },
        {
            headerName: 'Created',
            field: 'createdAt',
            valueFormatter: ({value}) => value.substring(0, 10)
        },
    ]);

  const gridRef = useRef(null)

  const filterFunc = useCallback((row: ProcessListResult) => {
    return searchProcessAndBlox(row, filterBy);
  }, [filterBy]);

  if (isLoading) return <Loading />;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <Column className={'searchContainer'}>
      <SearchHeader
        placeholder={`Search the FabuBase...`}
        filterBy={filterBy}
        handleChangeFilter={(val: string) => {
            

          setFilterBy(val)
        }}
      />
      <AgGridReact
        className='ag-theme-alpine'
        ref={gridRef}
        rowData={(rowData ?? []).filter(filterFunc)}
        columnDefs={columnDefs}
        rowHeight={50}
        defaultColDef={{
          editable: false,
          sortable: true,
          minWidth: 200,
          filter: true,
          resizable: true,
        }}
      />
    </Column>
  )
}

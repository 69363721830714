import { useFabuStateProvider } from './fabu-state-provider';
import { ExtractParametersType } from './parameter-context';
import { useParameter } from './use-parameter';

export type AppParamsType = ExtractParametersType<ReturnType<typeof useFabuStateProvider>>;

export function useFabuState<K extends keyof AppParamsType>(param: K) {
    return useParameter<AppParamsType, K>(param);
}


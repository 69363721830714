import React, { useMemo, useState } from 'react';
import { Button, Classes, Dialog, FormGroup, InputGroup, Intent, MenuItem, Spinner, SpinnerSize } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { useReadProcess } from '../hooks/DataFetching/use-fetch-process';
import { Section } from '../__generated__/Process';
import { IconNames } from '@blueprintjs/icons';
import { validate } from 'uuid'
import { AllBloxes } from '../Data/BloxSchema/base-blox';
export interface ImportSectionDialogProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    insertSection: (bloxes: AllBloxes[]) => void;
}

export const ImportSectionDialog: React.FC<ImportSectionDialogProps> = (props) => {
    const { isOpen, setIsOpen, insertSection } = props;
    const [processId, setProcessId] = useState<string | undefined>(undefined);
    const [draftProcessId, setDraftProcessId] = useState('');
    const [invalidProcessId, setInvalidProcessId] = useState(false);
    const [selectedSection, setSelectedSection] = useState<Section | null>(null);
    const { data: process, isLoading } = useReadProcess(processId);

    const handleConfirm = () => {
        const bloxes: AllBloxes[] = [];
        for (const bloxId of selectedSection?.bloxIds ?? []) {
            const blox = process?.bloxes.find(blox => blox.id === bloxId);
            if (blox) bloxes.push(blox);
        }
        insertSection(bloxes);
        handleClose();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (validate(e.target.value)) {
            setInvalidProcessId(false);
            setProcessId(e.target.value);
        } else {
            setSelectedSection(null);
            setProcessId(undefined);
        }
        setDraftProcessId(e.target.value);
    }

    const handleBlur = () => {
        if (!(validate(draftProcessId) || validate(processId ?? ''))) {
            setInvalidProcessId(true);
            return;
        }
        setProcessId(draftProcessId);
    }

    const handleClose = () => {
        setProcessId(undefined);
        setDraftProcessId('');
        setInvalidProcessId(false);
        setSelectedSection(null);
        setIsOpen(false);
    };

    const isProcessShowing = useMemo(() => {
        return process && process.processId === processId;
    }, [process, processId]);
    
    let selectText = '';
    if (!processId) {
        selectText = "(No process ID entered)";
    } else if (selectedSection) {
        selectText = `${selectedSection.sectionName}`;
    } else {
        selectText = "(No section selected)";
    
    }

    return (
        <Dialog
            isOpen={isOpen}
            title="Import Process Section"
            onClose={handleClose}
            canOutsideClickClose={false}
            style={{width: '600px'}}
        >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup
                    label="Process ID"
                    labelFor="process-id-input"
                    labelInfo="(required)"
                    helperText="Find the Process ID in the info window or the URL of the process editor."
                    style={{width: '360px'}}
                >
                    <InputGroup
                        id="process-id-input"
                        intent={invalidProcessId ? Intent.DANGER : isProcessShowing ? Intent.SUCCESS : Intent.NONE}
                        placeholder="Enter the Process ID"
                        value={processId ?? draftProcessId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        leftIcon={isProcessShowing ? IconNames.Tick : undefined}
                        rightElement={isLoading ? <Spinner size={SpinnerSize.SMALL}/> : undefined }
                    />
                </FormGroup>                
                <FormGroup
                    label="Process Sections"
                    labelFor="process-section-select"
                >
                    <Select<Section>
                        items={process?.sections || []}
                        itemRenderer={(section, { handleClick, modifiers }) => (
                            <MenuItem
                                key={section.sectionId}
                                onClick={handleClick}
                                text={section.sectionName || 'Unnamed Section'}
                            />
                        )}
                        onItemSelect={setSelectedSection}
                        filterable={false}
                        disabled={isLoading || !process}
                    >
                        <Button
                            style={{width: '330px'}}
                            text={selectText}
                            rightIcon={IconNames.DoubleCaretVertical}
                            disabled={isLoading || !process}
                        />
                    </Select>
                    
                </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
            {selectedSection && (
                        <p>This will import <b>{selectedSection.sectionName ?? "Unnamed Section"}</b> which includes <b>{selectedSection.bloxIds.length}</b> bloxes.</p>
                    )}
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button intent={Intent.PRIMARY} disabled={!selectedSection} onClick={handleConfirm} text="Confirm" />
                </div>
            </div>
        </Dialog>
    );
};
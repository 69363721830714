import { useDrag } from "react-dnd";
import { AllBloxes, BloxTypes, bloxNamesMap } from "../Data/BloxSchema/base-blox";
import { Draggables } from "../Data/enums";
import { Column } from "../Layout/layouts";
import { Icon, Tooltip } from '@blueprintjs/core';
import { useProcessHandlers } from "./hooks/use-process-handlers";
import { useFabuState } from "../hooks/state/use-fabu-state";
import { useCallback, useMemo } from "react";
import { BloxSvg } from "./svg/BloxSvg";
import { IconNames } from "@blueprintjs/icons";

export interface ModuleInsertItem {   
    data: AllBloxes[];
    moduleId: string;
    moduleName: string;
}

interface ModuleDraggableProps {
    imageBloxType: BloxTypes;
    moduleName: string;
    moduleId: string;
    data: AllBloxes[];
    desc?: string;
    groupNamesToShow?: string[];
}

export const ModuleDraggable: React.FC<ModuleDraggableProps> = (props) => {
    const { imageBloxType, moduleName, moduleId, data, desc, groupNamesToShow } = props;
    const { processHandleInsertSection } = useProcessHandlers();
    const [processBloxes,] = useFabuState('processBloxes');
    const [processSections,] = useFabuState('processSections');
    const [, setScrollToSelectedBlox] = useFabuState('scrollToSelectedBlox');

    const lastSectionId = useMemo(() => {
        return processSections[processSections.length - 1].sectionId;
    }, [processSections]);

    const [{ isDragging }, dragBlox] = useDrag<ModuleInsertItem, unknown, { isDragging: boolean }>(() => ({
        type: Draggables.Module,
        item: { data, moduleId, moduleName },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        })
    }));

    const dblClickHandle = useCallback(() => {
        processHandleInsertSection(data, processBloxes.length - 1, lastSectionId, moduleId, moduleName);
        setTimeout(() => setScrollToSelectedBlox(true));
    }, [processBloxes, data, processHandleInsertSection, setScrollToSelectedBlox, lastSectionId, moduleId, moduleName]);

    const getStepsEl = () => {
        const [, ...steps] = data; // remove first element which is the start blox
       return <>Steps:
        <ul style={{ marginLeft: '20px' }}>
            {steps.map((blox, index) => (
                <li key={index}>{blox.name ?? bloxNamesMap[blox.bloxType]}</li>
            ))}
        </ul>
        </>
    };
        
    const moduleTooltip = <div>Name: {moduleName} <br/>
        {getStepsEl()}
        {desc && <>
            <span>Description: {desc}</span>
            <br/>
        </>
        }
        {groupNamesToShow && groupNamesToShow.length > 0 && `Groups: ${groupNamesToShow.join(', ')}`}
    </div>;

    return (
        <Column>
            <Tooltip content={moduleTooltip}>
                <div className="blox-library" ref={dragBlox} style={{ display: 'flex', flexDirection: 'column', opacity: isDragging ? 0.5 : 1 }} onDoubleClick={dblClickHandle}>
                    <div className="library-label top">{moduleName}</div>
                    <a href={`/module-editor/${moduleId}`} target="_blank" rel="noopener noreferrer">
                        <Icon className={'library-image-icon-button'} icon={IconNames.Share} size={13}/>
                    </a>
                    <BloxSvg bloxType={imageBloxType} className='module-library-image'/>
                    <div className="library-label bottom">
                        <strong>{data.length - 1}</strong> steps
                    </div>
                </div>
            </Tooltip>
        </Column>  
    );
};

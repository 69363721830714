import React, { useCallback, useEffect, useState } from 'react';
import {
    AnchorButton,
    Button,
    Callout,
    Classes,
    Dialog,
    FormGroup,
    Intent,
    Switch,
} from '@blueprintjs/core';
import { BASE_FABUBLOX_API_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { IconNames } from '@blueprintjs/icons';
import { Row } from '../Layout/layouts';
import { useFabuState } from '../hooks/state/use-fabu-state';
import { Loading } from '../components/Loading';
import { Tooltip2 } from '@blueprintjs/popover2';
import { showToast } from '..';

interface Group {
    id: string;
    groupName: string;
    sharedWith: boolean;
}

export const SharingProcessButton: React.FC<{ isModule?: boolean }> = ({ isModule }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [myGroups, setMyGroups] = useState<Group[]>([]);
    const { getAccessTokenSilently } = useAuth0();
    const resourceName = isModule ? 'module' : 'process';

    const [processGroups, setProcessGroups] = useFabuState('processGroups');
    const [processId] = useFabuState('processId');
    const [processName] = useFabuState('processName');

    useEffect(() => {
        const fetchGroups = async () => {
            setIsLoading(true);
            const token = await getAccessTokenSilently();
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            try {
                const response = await fetch(
                    `${BASE_FABUBLOX_API_URL}/api/groups/mygroups`,
                    { headers }
                );
                const data = await response.json();

                // Set sharedWith based on processGroups
                data.forEach((group: Group) => {
                    group.sharedWith = processGroups.some((pG) => pG.id === group.id);
                });

                setMyGroups(data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        if (isDialogOpen === true) fetchGroups();
    }, [isDialogOpen, processGroups, getAccessTokenSilently]);

    const handleClick = useCallback(() => {
        setIsDialogOpen(true);
    }, []);

    const handleCopy = useCallback(() => {
        try {
            navigator.clipboard.writeText(`${window.location.href}`);
            showToast({
                message: 'Process link copied to clipboard',
                intent: Intent.SUCCESS,
                timeout: 3000,
            });
        } catch (error) {
            console.error('Failed to copy link', error);
            showToast({
                message: 'Failed to copy link',
                intent: Intent.DANGER,
                timeout: 3000,
            });
        }
        
    }, []);

    const handleGroupToggle = useCallback(
        (groupId: string) => {
            const groupToToggle = myGroups.find((group) => group.id === groupId);
            if (groupToToggle) {
                groupToToggle.sharedWith = !groupToToggle.sharedWith;
            }
            setMyGroups([...myGroups]);
        },
        [myGroups]
    );

    const handleClose = useCallback(() => {
        setIsDialogOpen(false);
    }, []);

    const handleSave = useCallback(async () => {
        const token = await getAccessTokenSilently();

        const updatedProcessGroups = myGroups
            .filter((group) => group.sharedWith)
            .map((group) => ({ id: group.id, groupName: group.groupName }));

        const checkForUpdates = (
            processGroupIds: string[],
            updatedProcessGroupIds: string[]
        ) => {
            if (processGroupIds.length !== updatedProcessGroupIds.length) {
                return true;
            }
            return !processGroupIds.every((id) => updatedProcessGroupIds.includes(id));
        };

        if (
            !checkForUpdates(
                processGroups.map((g) => g.id),
                updatedProcessGroups.map((g) => g.id)
            )
        ) {
            setIsDialogOpen(false);
            return;
        }

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                groups: updatedProcessGroups,
            }),
        };

        try {
            const res = await fetch(
                `${BASE_FABUBLOX_API_URL}/api/${resourceName}/update/${processId}`,
                requestOptions
            );

            if (!res.ok) {
                showToast({
                    message: 'Save Failed',
                    intent: Intent.DANGER,
                    timeout: 3000,
                });
            }
        } catch (error: any) {
            console.error(`Save failed with ${error.message}`);
        }

        setProcessGroups(updatedProcessGroups);
        setIsDialogOpen(false);
    }, [
        processGroups,
        myGroups,
        processId,
        getAccessTokenSilently,
        setProcessGroups,
        resourceName,
    ]);

    const unsavedProcess = processId === 'new' || processId === '';
    const sharingTooltip = unsavedProcess
        ? `Save ${resourceName} to enable sharing.`
        : undefined;

    return (
        <>
            <Tooltip2 content={sharingTooltip}>
                <AnchorButton
                    icon={IconNames.PEOPLE}
                    text="Share"
                    onClick={handleClick}
                    minimal
                    disabled={unsavedProcess}
                />
            </Tooltip2>
            <Dialog
                isOpen={isDialogOpen}
                onClose={handleClose}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
            >
                <div className={Classes.DIALOG_HEADER}>
                    <h4 className={Classes.HEADING}>{`Share "${processName}"`}</h4>
                </div>
                <div className={Classes.DIALOG_BODY}>
                    {isLoading ? (
                        <Loading size={30} />
                    ) : (
                        <>
                            <FormGroup>
                                {myGroups.map((group) => (
                                    <div key={group.id}>
                                        <Switch
                                            onChange={handleGroupToggle.bind(this, group.id)}
                                            checked={group.sharedWith}
                                            label={group.groupName}
                                        />
                                    </div>
                                ))}
                            </FormGroup>
                            <Callout
                                intent={Intent.PRIMARY}
                                icon={IconNames.INFO_SIGN}
                                style={{ marginTop: '20px' }}
                            >
                                Please reach out to{' '}
                                <a href="mailto:hello@fabublox.com">
                                    hello@fabublox.com
                                </a>{' '}
                                if you would like us to create a private group for you to build a
                                shared process database.
                            </Callout>
                        </>
                    )}
                    <Row style={{ margin: '20px 20px 0 20px' }}>
                        <Button
                            onClick={handleCopy}
                            icon={IconNames.LINK}
                            text="Get Link"
                            minimal={true}
                            style={{ marginRight: 'auto' }}
                        />
                        <Button
                            onClick={handleSave}
                            intent={Intent.SUCCESS}
                            style={{ marginLeft: 'auto' }}
                        >
                            Done
                        </Button>
                    </Row>
                </div>
            </Dialog>
        </>
    );
};
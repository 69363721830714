import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";

export type FlipStack = BaseBlox

export const flipStackDisplay: DisplayMap = {

}

export const getFlipStack = ({stepNumber}: BloxArgs): FlipStack => ({
        name: `Flip Stack ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.FlipStack,
        commentField: null,
        customFields: {},
    }
);

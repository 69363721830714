import { useState, useMemo } from 'react';
import { MultiSelect } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core";
import { useQuery } from 'react-query';
import { BASE_FABUBLOX_API_URL } from '../../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { AdditionalOwnerUser } from '../../__generated__/Process';

function UserSearch({ onSelectionChange, selectedOwners }: { onSelectionChange: (users: AdditionalOwnerUser[]) => void, selectedOwners: AdditionalOwnerUser[] }) {
    const [inputValue, setInputValue] = useState("");
    const { getAccessTokenSilently, user: currentUser } = useAuth0();

    const { data: users = [], isLoading } = useQuery(["searchUsers", inputValue], async () => {
        const token = await getAccessTokenSilently();
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/users/search?searchTerm=${encodeURIComponent(inputValue)}`, { headers });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();

    }, {
        enabled: inputValue?.length > 2
    });

    const noResultText = useMemo(() => {
        if (isLoading)
            return 'Loading results...';
        else if (inputValue.length < 3)
            return 'Minimum three characters.'
        else return 'No results.'
    }, [inputValue, isLoading])

    const addUser = (user: AdditionalOwnerUser) => {
        if (!selectedOwners.some(selected => selected.userId === user.userId)) {
            const updatedSelectedUsers = [...selectedOwners, user];
            onSelectionChange(updatedSelectedUsers);
        }
    };

    const removeUser = (userToRemove: AdditionalOwnerUser) => {
        const updatedSelectedUsers = selectedOwners.filter(user => user.userId !== userToRemove.userId);
        onSelectionChange(updatedSelectedUsers);
    };

    return (
        <MultiSelect
            fill={true}
            openOnKeyDown={true}
            items={users}
            selectedItems={selectedOwners}
            itemPredicate={(_query, user) => !selectedOwners.includes(user) && user.userId !== currentUser?.sub}
            itemRenderer={(user, { modifiers, handleClick }) => {
                const itemText = user.displayName;

                return <MenuItem
                    active={modifiers.active}
                    key={user.userId}
                    onClick={handleClick}
                    text={itemText}
                />
            }}
            onItemSelect={addUser}
            onQueryChange={value => {
                setInputValue(value);
            }}
            tagRenderer={user => user.displayName}
            tagInputProps={{
                onRemove: (_value, index) => {
                    removeUser(selectedOwners[index]);
                },
                placeholder: "Search for users by email address",
            }}
            noResults={<MenuItem disabled={true} text={noResultText} />}
            resetOnSelect
        />
    );
}

export default UserSearch;

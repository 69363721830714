import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { Intent } from '@blueprintjs/core';
import { Process, CreateProcessRequest, UpdateProcessRequest } from '../../__generated__/Process';
import { BASE_FABUBLOX_API_URL } from '../../utils/constants';
import { showToast } from '../..';

export async function fetchProcesses(endpoint: string, getAccessTokenSilently: () => Promise<string>, isModule?: boolean): Promise<Process[]> {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/${isModule ? 'modules' : 'processes'}/${endpoint}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch ${isModule ? 'modules' : 'processes'} from endpoint: ${endpoint}`);
    }
    return response.json();
}

export function usePublicProcesses(isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Process[], Error>(isModule ? 'publicModules' : 'publicProcesses', () => fetchProcesses('public', getAccessTokenSilently, isModule));
}

export function useGroupProcesses(isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Process[], Error>(isModule ? 'groupModules' : 'groupProcesses', () => fetchProcesses('shared', getAccessTokenSilently, isModule));
}

export function useOwnedProcesses(isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Process[], Error>(isModule ? 'ownedModules' : 'ownedProcesses', () => fetchProcesses('owned', getAccessTokenSilently, isModule));
}

async function fetchCreateProcess(data: CreateProcessRequest, getAccessTokenSilently: () => Promise<string>, isModule?: boolean) {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/${isModule ? 'module' : 'process'}/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch ${isModule ? 'module' : 'process'}`);
    }
    return response.json();
}

async function fetchReadProcess(processId: string | undefined, getAccessTokenSilently: () => Promise<string>, isModule?: boolean) {
    if (!processId) throw new Error('No processId provided');
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/${isModule ? 'module' : 'process'}/read/${processId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch ${isModule ? 'module' : 'process'}`);
    }
    return response.json();
}

async function fetchUpdateProcess(processId: string, data: UpdateProcessRequest, getAccessTokenSilently: () => Promise<string>, isModule?: boolean) {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/${isModule ? 'module' : 'process'}/update/${processId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch ${isModule ? 'module' : 'process'}`);
    }
}

async function fetchDeleteProcess(processId: string, getAccessTokenSilently: () => Promise<string>, isModule?: boolean) {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/${isModule ? 'module' : 'process'}/delete/${processId}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch ${isModule ? 'module' : 'process'}`);
    }
}

export function useCreateProcess(isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    return useMutation(
        (data: CreateProcessRequest) => fetchCreateProcess(data, getAccessTokenSilently, isModule),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(isModule ? 'ownedModules' : 'ownedProcesses');
                showToast({ message: isModule ? 'Module' : 'Process', intent: Intent.SUCCESS, timeout: 3000 });
            },
            onError: (error: any) => {
                showToast({ message: error instanceof Error ? error.message : "An error occurred", intent: Intent.DANGER, timeout: 3000 });
            },
        }
    );
}

export function useUpdateProcess(isModule?: boolean, options?: {quietly: boolean}) {
    const { getAccessTokenSilently } = useAuth0();
    const {quietly} = options ?? {quietly: false};
    const queryClient = useQueryClient();
    return useMutation(
        ({ processId, data }: { processId: string; data: UpdateProcessRequest }) => fetchUpdateProcess(processId, data, getAccessTokenSilently, isModule),
        {
            onSuccess: (_data, variables) => {
                if (quietly) return;
                
                queryClient.invalidateQueries(isModule ? 'ownedModules' : 'ownedProcesses');
                queryClient.invalidateQueries([isModule ? 'module' : 'process', variables.processId])
                showToast({ message: isModule ? 'Module' : 'Process', intent: Intent.SUCCESS, timeout: 3000 });
            },
            onError: (error: any) => {
                showToast({ message: error instanceof Error ? error.message : "An error occurred", intent: Intent.DANGER, timeout: 3000 });
            },
        }
    );
}

export function useDeleteProcess(isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    return useMutation(
        ({processId}: {processId: string}) => fetchDeleteProcess(processId, getAccessTokenSilently, isModule),
        {
            onSuccess: (_data, variables) => {
                queryClient.invalidateQueries(isModule ? 'ownedModules' : 'ownedProcesses');
                queryClient.invalidateQueries([isModule ? 'module' : 'process', variables.processId]);
                showToast({ message: isModule ? 'Module' : 'Process', intent: Intent.SUCCESS, timeout: 3000 });
            },
            onError: (error: any) => {
                showToast({ message: error instanceof Error ? error.message : "An error occurred", intent: Intent.DANGER, timeout: 3000 });
            },
        }
    );
}

export function useReadProcess(processId?: string, isModule?: boolean) {
    const { getAccessTokenSilently } = useAuth0();

    const isEnabled = processId !== undefined && processId !== '' && processId !== 'new' && processId !== 'create';

    return useQuery<Process, Error>({
        queryKey: [isModule ? 'module' : 'process', processId],
        queryFn: () => fetchReadProcess(processId, getAccessTokenSilently, isModule),
            enabled: isEnabled,
            staleTime: 1000 * 5, // 5 seconds
            retry: false,
            meta: { 
                errorMessage: `Failed to fetch ${isModule ? 'module' : 'process'}: ${processId}`
            }
        }
    );
}

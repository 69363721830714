import { Intent } from "@blueprintjs/core";
import { useCallback, useMemo } from "react";
import { useCreateBlox, useDeleteBlox, useUpdateBlox } from "../../hooks/DataFetching/use-fetch-blox";
import { CreateBloxRequest } from "../../__generated__/Blox";
import { showToast } from "../..";
import { AllBloxes } from "../../Data/BloxSchema/base-blox";


export function useBloxHandlers() {
    const failToast = useMemo(() => ({
        message: "Save Failed",
        intent: Intent.DANGER,
        timeout: 3000
    }), []);

    const updateBlox = useUpdateBlox();
    const createBlox = useCreateBlox();
    const deleteBlox = useDeleteBlox();

    const bloxHandleUpdate = useCallback((id: string, name: string, desc: string, token: string) => {
        if (!id) {
            showToast(failToast);
            return;
        }
        const data = {
            bloxName: name,
            bloxDescription: desc
        };
        updateBlox.mutate({ bloxId: id, data });
    }, [updateBlox, failToast]);

    const bloxHandleDelete = useCallback((id: string, token: string) => {

    if (!id) {
        showToast(failToast);
        return;
    }

    deleteBlox.mutate(id);

    }, [deleteBlox, failToast]);

    const bloxHandleSave = useCallback(async (bloxData: AllBloxes, bloxName: string, desc: string, token: string) => {
        if (!bloxName) {
            showToast({
                message: "Save Failed: Blox name is required",
                intent: Intent.DANGER,
                timeout: 3000
            });
            return;
        }
        delete bloxData.moduleId;
        delete bloxData.moduleName;
        delete bloxData.moduleBloxId;
        
        const createBloxInfo: CreateBloxRequest = {
            bloxData,
            bloxName,
            bloxDescription: desc
        };
        createBlox.mutate(createBloxInfo);
    }, [createBlox]);

    return {
        bloxHandleSave,
        bloxHandleDelete,
        bloxHandleUpdate
    };
}
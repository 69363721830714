import {
    InputGroup, 
    Label, 
    InputGroupProps2, 
    EditableText, 
    Dialog, 
    Button,
    Classes
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Tooltip2 } from "@blueprintjs/popover2";
import {useState, useEffect, isValidElement} from 'react';
import { StateAndSetter } from '../../hooks/state/parameter-context';

export interface InputProps {
    id: string;
    inputValueDictState: StateAndSetter<{[key: string]: string}>;
    label?: string;
    inputGroupProps: InputGroupProps2;
    validation?: (val: any) => boolean;
    onChange?: (val: string) => void;
    warningText?: string;
    warningTitle?: string;
    inputButton?: JSX.Element;
    onChangeLabel?: (val: string) => void;
    infoContent?: string | JSX.Element;
    disabled?: boolean;
}

export const Input: React.FC<InputProps> = ({
    id,
    label, 
    inputGroupProps,
    inputValueDictState,
    onChange,
    validation,
    warningText,
    warningTitle,
    inputButton,
    onChangeLabel,
    infoContent,
    disabled
}) => {
    const [inputValueDict, setInputValueDict] = inputValueDictState;
    const [isValid, setIsValid] = useState(true);
    const [warnUser, setWarnUser] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    
    const tempValue = inputValueDict[id];

    useEffect(() => {
        if (validation && tempValue !== undefined && !validation(tempValue)) {
            setWarnUser(true);
        }
    }, [tempValue, validation]);

    const toggleDialog = () => setIsDialogOpen(prev => !prev);

    let infoRender: JSX.Element = <></>;
    if (typeof infoContent === 'string') {
        infoRender = (
            <Tooltip2 content={<div style={{ width: '400px', whiteSpace: 'normal' }}>{infoContent}</div>}>
                <Button minimal icon={IconNames.INFO_SIGN} className={Classes.INPUT_ACTION}/>
            </Tooltip2>
        );
    } else if (isValidElement(infoContent)) {
        infoRender = (
            <>
                <Button 
                    minimal 
                    icon={IconNames.InfoSign}
                    className={Classes.INPUT_ACTION}
                    onClick={(e) => {
                        e.stopPropagation();  // This stops the click event from propagating to parent elements
                        toggleDialog();
                    }} 
                />
                <Dialog 
                    isOpen={isDialogOpen} 
                    onClose={toggleDialog}
                    title="Information"
                >
                    {infoContent}
                </Dialog>
            </>
        );
    }

    return (
        <Label key={id} className="righPanelLabel">
            {inputButton}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    {!onChangeLabel ? label : <EditableText value={label} onChange={(val) => {onChangeLabel(val)}} />}
                    <div style={{ display: 'flex', alignItems: 'center' }}>{infoRender}</div>
                </div>
            <InputGroup
                {...inputGroupProps}
                id={id}
                intent={warnUser ? 'danger' : 'none'}
                title={warnUser ? warningTitle : inputGroupProps.placeholder}
                leftIcon={warnUser ? 'error' : inputGroupProps.leftIcon}
                value={tempValue ?? inputGroupProps.value}
                onChange={(event) => {
                    const newVal = event.target.value;
                    setInputValueDict({
                        ...inputValueDict,
                        [id]: newVal
                    });
                    
                    if (validation && !validation(newVal)) {
                        setIsValid(false);
                        return;
                    }

                    setIsValid(true);
                    setInputValueDict({
                        ...inputValueDict,
                        [id]: newVal
                    });
                    setWarnUser(false);
                    if (onChange) {
                        onChange(newVal);
                    }
                }}
                onBlur={() => {
                    if (!isValid){
                        setWarnUser(true);
                    }
                }}
                disabled={disabled}
            />
            <span className={'warning-text'}>{warnUser ? warningText : undefined}</span>
        </Label>
    );
}

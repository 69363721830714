import { useAuth0 } from "@auth0/auth0-react";
import { Button, Intent } from "@blueprintjs/core";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <Button
            style={{margin: 'auto'}}
            intent={Intent.PRIMARY}
            large={true}
            onClick={() => loginWithRedirect()}
        >
            Log In
        </Button>
    );
};

export default LoginButton;

//Firsttest1!

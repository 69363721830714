import { Collapse, FormGroup, TextArea, InputGroup, Tooltip } from "@blueprintjs/core";
import { useState, useCallback, useMemo } from "react";
import { Column, Row } from "../Layout/layouts";
import { useFabuState } from "../hooks/state/use-fabu-state";
import UserSearch from "./Fields/UserSearch";
import { AdditionalOwnerUser } from "../__generated__/Process";

export function ProcessInfo({isOpen, isModule}: {isOpen: boolean, isModule?: boolean}) {
    const resourceName = isModule ? 'Module' : 'Process';

    const [processUsername] = useFabuState('processUsername');
    const [processAdditionalOwners, setProcessAdditionalOwners] = useFabuState('processAdditionalOwners');
    const [processDesc, setProcessDesc] = useFabuState('processDesc');
    const [processId] = useFabuState('processId');
    const [processReference, setProcessReference] = useFabuState('processReference');
    const [processIsReadOnly] = useFabuState('processIsReadOnly');

    const [desc, setDesc] = useState<string | undefined>(undefined);
    const [reference, setReference] = useState<string | undefined>(undefined);
    
    const selectionChangeCallback = useCallback((owners: AdditionalOwnerUser[]) => {
        setProcessAdditionalOwners(owners);
    }, [setProcessAdditionalOwners]);

    const handleDescChange = useCallback((event: any) => {
        setDesc(event.target.value);
    }, [setDesc]);

    const handleReferenceChange = useCallback((event: any) => {
        setReference(event.target.value)
    }, [setReference]);

    const handleDescBlur = useCallback(() => {
        if (desc === undefined) return;
        setProcessDesc(desc);
    }, [setProcessDesc, desc]);

    const handleReferenceBlur = useCallback((event: any) => {
        if (reference === undefined) return;
        setProcessReference(reference);
    }, [reference, setProcessReference]);

    const ownersString = useMemo(() => {
        if (processAdditionalOwners?.length === 1) 
            return processAdditionalOwners[0].displayName
        else
            return processAdditionalOwners.map(owner => owner.displayName).join(', ');
    }, [processAdditionalOwners]);

    const readOnlyOwnersEl = <Tooltip content={ownersString} >
            <InputGroup disabled={true} value={ownersString} />
        </Tooltip>
    return <Collapse transitionDuration={0} isOpen={isOpen}>
        <Column style={{ maxHeight: '400px', maxWidth: '800px', minWidth: '300px', backgroundColor: '#e1e6ff', padding: '5px 10px', marginLeft: '15px', marginTop: '10px', marginBottom: '10px', borderRadius: '4px' }}>
            <FormGroup>
                <Column>
                <FormGroup label={"Creator:"} inline={true} style={{alignItems: 'center'}}>
                    <b>{processUsername}</b>
                </FormGroup>
                <FormGroup label={`${resourceName} ID:`} inline={true} style={{alignItems: 'center'}}>
                    <p style={{margin: 'auto'}}>{processId || 'N/A'} </p>
                </FormGroup>
                <FormGroup label={`${resourceName} Description`}>
                    <TextArea disabled={processIsReadOnly} style={{ width: '100%', height: '120px' }} value={desc === undefined ? processDesc : desc} onChange={handleDescChange} onBlur={handleDescBlur}/>
                </FormGroup>
                </Column>
                <Row>
                    <FormGroup label={'Additional Owners'} style={{ paddingRight: '10px', minWidth: '250px' }}>
                        {
                            processIsReadOnly ?
                                readOnlyOwnersEl :
                                <UserSearch onSelectionChange={selectionChangeCallback} selectedOwners={processAdditionalOwners} />
                        }
                    </FormGroup>
                    <FormGroup label={'Reference'} style={{ paddingRight: '10px', minWidth: '250px' }}>
                        <InputGroup disabled={processIsReadOnly} onChange={handleReferenceChange} value={reference === undefined ? processReference : reference} onBlur={handleReferenceBlur}/>
                    </FormGroup>
                </Row>
            </FormGroup>
        </Column>

    </Collapse>
}
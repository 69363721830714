import React, { useCallback, useState } from 'react';
import { Popover, Menu, MenuItem, Button, EditableText, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Row } from '../Layout/layouts';
import { Section } from '../__generated__/Process';
import { useSectionManager } from './hooks/use-section-manager';
import { ImportSectionDialog } from '../dialogs/ImportSectionDialog';
import { useFabuState } from '../hooks/state/use-fabu-state';
import { useProcessHandlers } from './hooks/use-process-handlers';
import { AllBloxes } from '../Data/BloxSchema/base-blox';
import { DeleteDialog } from '../dialogs/DeleteDialog';

interface SectionHeaderProps {
    section: Section;
    sectionIndex: number;
    closedSections: { [key: string]: boolean };
    setClosedSections: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
    processSections: Section[];
    setProcessSections: React.Dispatch<React.SetStateAction<Section[]>>;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
    section,
    sectionIndex,
    processSections,
    setProcessSections,
    closedSections,
    setClosedSections,
}) => {
    const [processIsReadOnly, ] = useFabuState('processIsReadOnly');
    const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

    const [processBloxes] = useFabuState('processBloxes');
    const [draftSectionName, setDraftSectionName] = useState<{ [key: string]: string | undefined }>({});
    const { createSection, rejoinSection } = useSectionManager();
    const { processHandleInsertSection, processHandleDeleteSection } = useProcessHandlers();

    // find the last bloxId in this section or previous sections
    let lastBloxId: string | null = null;
    for (let i = sectionIndex; i >= 0; i--) {
      const section = processSections[i];
      if (section && section.bloxIds.length > 0) {
        lastBloxId = section.bloxIds[section.bloxIds.length - 1];
        break; // Exit the loop once the last non-empty section's last bloxId is found.
      }
    }
    const bloxIdxToInsert = processBloxes.findIndex(blox => blox.id === lastBloxId);

    const handleInsertSection = useCallback((bloxes: AllBloxes[]) => {
        const newSectionId = createSection(section.sectionId);
        processHandleInsertSection(bloxes, bloxIdxToInsert, newSectionId);
        setTimeout(() => scrollToSection(newSectionId)); // hack, use timeout with no wait to allow new element to be added before scrolling
    }, [processHandleInsertSection, bloxIdxToInsert, section, createSection]);

    const sectionNameStyle: React.CSSProperties = {
        marginLeft: '10px',
        marginTop: '20px',
        fontWeight: 'bold',
        maxWidth: '400px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    const toggleSection = useCallback((sectionId: string) => {
        setClosedSections(prevState => ({
            ...prevState,
            [sectionId]: !prevState[sectionId]
        }));
    }, [setClosedSections]);

    const rejoinIcon = <Icon icon={IconNames.Nest} style={{ transform: 'rotate(180deg)' }} />;

    const closeDialogCallback = useCallback(() => setIsOpenDeleteDialog(false), []);
    const deleteCallback = useCallback(() => processHandleDeleteSection(section), [processHandleDeleteSection, section]);

    const deleteDialogProps = {
        name: section.sectionName ?? 'Section ' + (sectionIndex + 1),
        isOpen: isOpenDeleteDialog,
        onDelete: deleteCallback,
        closeDialog: closeDialogCallback,
        customMessage: 'This will also delete all Blox in this section'
    };

    return (
        <>
        <Row style={{ height: '40px' }}>
            <Popover content={<Menu>
                <MenuItem disabled={processIsReadOnly} icon={IconNames.Plus} text="Add Section" onClick={() => {
                    const newSectionId = createSection(section.sectionId);
                    setClosedSections(prevState => ({
                        ...prevState,
                        [newSectionId]: false
                    }));
                    setTimeout(() => scrollToSection(newSectionId)); // hack, use timeout with no wait to allow new element to be added before scrolling
                }} />
                <MenuItem disabled={processIsReadOnly} icon={IconNames.Import} text="Import Section" onClick={() => setIsOpenImportDialog(true)} />
                {sectionIndex !== 0 && <>
                    <MenuItem disabled={processIsReadOnly} icon={rejoinIcon} text="Rejoin Section" onClick={() => rejoinSection(section.sectionId)} />
                    <MenuItem disabled={processIsReadOnly} icon={IconNames.Delete} text="Delete Section" onClick={() => setIsOpenDeleteDialog(true)} />
                </>}                
            </Menu>}>
                <Button
                    style={{ marginTop: '17px' }}
                    minimal
                    icon={IconNames.Properties}
                    small={true}
                />
            </Popover>
            <div style={sectionNameStyle}>
                <EditableText
                    disabled={processIsReadOnly}
                    placeholder={processIsReadOnly ? `Section ${sectionIndex + 1}` : 'Click to add Section Name'}
                    value={draftSectionName[section.sectionId] ?? section.sectionName}
                    onChange={(value) => setDraftSectionName((prevValue) => ({
                        ...prevValue,
                        [section.sectionId]: value
                    }))}
                    onConfirm={() => {
                        setProcessSections((prevValue) => prevValue.map((s) => {
                            if (s.sectionId === section.sectionId) {
                                return { ...s, sectionName: draftSectionName[section.sectionId] ?? s.sectionName };
                            }
                            return s;
                        }));
                        setDraftSectionName((prevValue) => ({
                            ...prevValue,
                            [section.sectionId]: undefined
                        }));
                    }}
                />
            </div>
            <Button
                style={{ marginTop: '14px', paddingLeft: '0px' }}
                minimal
                icon={closedSections[section.sectionId] ? IconNames.ChevronRight : IconNames.ChevronDown}
                onClick={() => toggleSection(section.sectionId)}
            />
        </Row>
        <ImportSectionDialog insertSection={handleInsertSection} isOpen={isOpenImportDialog} setIsOpen={setIsOpenImportDialog} />
        <DeleteDialog {...deleteDialogProps} />
        </>
    );
};

export const scrollToSection = (sectionId: string) => {
    const sectionContainer = document.getElementById('sectionContainer');
    const sectionElement = document.getElementById(sectionId);

    if (sectionContainer && sectionElement) {
        const sectionTopRelativeToContainer = sectionElement.getBoundingClientRect().top - sectionContainer.getBoundingClientRect().top;
        const headerOffset = 30;
        sectionContainer.scroll({
            top: sectionTopRelativeToContainer + sectionContainer.scrollTop - headerOffset,
            behavior: "smooth"
        });
        sectionElement.scroll({ left: sectionElement.scrollWidth, behavior: "smooth" });
    }
}
import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";


export interface CMP extends BaseBlox {
    slurry: string | null;
    pad: string | null;
    slurryFlow: number | null;
    slurryFlowUnit: Units | null;
    pressure: number | null;
    pressureUnit: Units | null;
    polishTime: number | null;
    polishTimeUnit: Units | null;
    //Display
    percentToPolish: number | null;
    // SEMIFAB
    etchDepth: number | null;
    etchDepthUnit: Units | null;
}

export const CMPDisplay: DisplayMap = {
    // DISPLAY
    percentToPolish: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Abrasion Depth",
        order: 1,
        tabs: [Tab.DISPLAY],
        defaultValue: 20,
        stepSize: 2
    },

    // EXPERIMENTAL
    slurry: {
        fieldType: DisplayFieldTypes.Input,
        label: "Slurry Composition",
        placeholder: "Enter abrasive and chemical composition",
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    pad: {
        fieldType: DisplayFieldTypes.Input,
        label: "Polishing Pad",
        placeholder: "Enter pad type used",
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    slurryFlow: {
        fieldType: DisplayFieldTypes.Input,
        label: "Slurry Flow Rate",
        placeholder: "Enter flow rate",
        isNumber: true,
        order: 3,
        units: [Units.MILLILITPERMIN,Units.LITPERMIN,Units.GPM],
        tabs: [Tab.EXPERIMENTAL],
    },
    pressure: {
        fieldType: DisplayFieldTypes.Input,
        label: "Pressure/Downforce",
        placeholder: "Enter applied pressure",
        isNumber: true,
        order: 4,
        units: [Units.PSI,Units.PASCAL,Units.KILOPASCAL,Units.NEWTPERM],
        tabs: [Tab.EXPERIMENTAL],
    },
    polishTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Polishing Time",
        isNumber: true,
        placeholder: "Enter polishing time",
        order: 5,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        tabs: [Tab.EXPERIMENTAL],
    },
    // SEMIFAB
    etchDepth: {
        fieldType: DisplayFieldTypes.Input,
        label: "Etch Depth",
        placeholder: "Enter etch depth",
        isNumber: true,
        order: 6,
        units: [Units.ANG,Units.NM,Units.MICRON,Units.MM],
        tabs: [Tab.SEMIFAB],
    },
}

export const getCMP = ({stepNumber}: BloxArgs): CMP => ({
        name: `CMP ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.CMP,
        slurry: null,
        pad: null,
        slurryFlow: null,
        slurryFlowUnit: Units.MILLILITPERMIN,
        pressure: null,
        pressureUnit: Units.PSI,
        polishTime: null,
        polishTimeUnit: Units.MIN,
        percentToPolish: 20,
        etchDepth: null,
        etchDepthUnit: Units.NM,
        customFields: {},
         // to do: user needs to select up to which layer to etch
});

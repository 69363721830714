import { useMutation, useQuery, useQueryClient } from 'react-query';
import { User, UpdateUserRequest } from '../../__generated__/User';
import { useAuth0 } from '@auth0/auth0-react';
import { Intent } from '@blueprintjs/core';
import { showToast } from '../..';
import { BASE_FABUBLOX_API_URL } from '../../utils/constants';

async function readUser(userId: string | undefined, getAccessTokenSilently: () => Promise<string>): Promise<User> {
    if (!userId) {
        throw new Error('User ID is undefined');
    }
    const token = await getAccessTokenSilently();
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/user/read/${userId}`, {
        headers: { 'Authorization': `Bearer ${token}` },
    });
    if (!response.ok) {
        throw new Error(`Failed to read user: ${userId}`);
    }
    return response.json();
}

export function useReadUser(userId: string | undefined) {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery<User, Error>(
        ['user', userId],
        () => readUser(userId, getAccessTokenSilently),
        {
            enabled: !!userId, // This line ensures the query will not run if userId is undefined
        }
    );
}

async function updateUser(userId: string, data: UpdateUserRequest, getAccessTokenSilently: () => Promise<string>) {
    const token = await getAccessTokenSilently();
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify(data),
    };
    const response = await fetch(`${BASE_FABUBLOX_API_URL}/api/user/update/${userId}`, requestOptions);
    if (!response.ok) {
        throw new Error(`Failed to update user: ${userId}`);
    }
}

export function useUpdateUser() {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    return useMutation(
        async ({ userId, data }: { userId: string; data: UpdateUserRequest }) => 
            updateUser(userId, data, getAccessTokenSilently),
        {
            onSuccess: (_data, { userId }, _context) => {
                queryClient.invalidateQueries(['user', userId]);
            },
            onError: (error: any, _variables, _context) => {
                showToast({
                    message: error instanceof Error ? error.message : "An error occurred",
                    intent: Intent.DANGER,
                    timeout: 3000
                });
                console.error(`Update failed with ${error.message}`);
            },
        }
    );
}
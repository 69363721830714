import { useMemo } from "react";
import { useFabuState } from "../../hooks/state/use-fabu-state";

export function useGetProcess() {
    const [processBloxes] = useFabuState('processBloxes');
    const [processId] = useFabuState('processId');
    const [processName] = useFabuState('processName');
    const [processIsPrivate] = useFabuState('processIsPrivate');
    const [processAdditionalOwners] = useFabuState('processAdditionalOwners');
    const [processDesc] = useFabuState('processDesc');
    const [processReference] = useFabuState('processReference');
    const [processSections] = useFabuState('processSections');

    return useMemo(() => ({
            processId,
            processName,
            processBloxes,
            processIsPrivate,
            processAdditionalOwners,
            processDesc,
            processReference,
            processSections,
        }), [processId, processName, processBloxes, processIsPrivate, processAdditionalOwners, processDesc, processReference, processSections]);
}
import ProcessEditor from "../components/editors/ProcessEditor"
import { FabuPage, PageNames } from "../components/FabuPage"
import { ProcessHeader } from "../components/ProcessHeader"
import { CreateProcessDialog } from "../dialogs/CreateProcessDialog"
import { SaveYourChangesDialog } from "../dialogs/SaveYourChangesDialog"
import { UnsavedChangesDialog } from "../dialogs/UnsavedChangesDialog"
import { useContext, useEffect, useState } from "react"
import { ProcessInfo } from "../components/ProcessInfo"
import { ProcessSettingsContext } from "../hooks/state/process-settings-provider"
import { useFabuState } from "../hooks/state/use-fabu-state"

export const ProcessViewerPage = () => {
    const { isProcessInfoOpen } = useContext(ProcessSettingsContext);
    const [processSections] = useFabuState('processSections');
    const [closedSections, setClosedSections] = useState<{[key: string]: boolean}>({});

    useEffect(() => {
        const updatedClosedSections = processSections.reduce((acc, section) => {
            if (acc[section.sectionId] !== undefined) return acc;
            
            acc[section.sectionId] = false;
            return acc;
        }, closedSections);

        setClosedSections(updatedClosedSections);

    }, [processSections, closedSections]);

    return (
        <>
            <UnsavedChangesDialog hasUnsavedChanges={false} />
            <FabuPage
                pageName={PageNames.Viewer}
                headerContent={
                    <ProcessHeader closedSectionState={[closedSections, setClosedSections]} />
                }
            >
                <CreateProcessDialog />
                <SaveYourChangesDialog />
                <ProcessInfo isOpen={isProcessInfoOpen} />
                <ProcessEditor closedSectionState={[closedSections, setClosedSections]}/>
            </FabuPage>
        </>
    );
}



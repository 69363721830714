import React, { ReactNode } from 'react';
import { Column } from '../Layout/layouts';

export const RightPanel: React.FC<{children: ReactNode}> = (props) => {
    return <div className={"right-panel"}>
        <div>
            <Column style={{'height': '100%', 'margin': '40px'}}>
                {props.children}
            </Column>
        </div> 
    </div>
}
import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { DisplayFieldTypes, Tab, Units } from "../enums";

export interface PlasmaDescum extends BaseBlox {
    // to do user select number of cleaning steps
    gas: string | null;
    etchTime: number | null;
    etchTimeUnit: Units | null;
    rfPower: number | null;
    rfPowerUnit: Units | null;
}

export const plasmaDescumDisplay: DisplayMap = {
    gas: {
        fieldType: DisplayFieldTypes.Input,
        label: "Gas composition",
        placeholder: "Enter gas names and ratios",
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
      },
    rfPower: {
        fieldType: DisplayFieldTypes.Input,
        label: "RF Power",
        placeholder: "Enter RF power",
        isNumber: true,
        order: 2,
        units: [Units.WATT],
        tabs: [Tab.EXPERIMENTAL],
    },
    etchTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Time",
        placeholder: "Enter ashing time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
}

export const getPlasmaDescum = ({stepNumber}: BloxArgs): PlasmaDescum => ({
        name: `Plasma Descum ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.PlasmaDescum,
        gas: null,
        rfPower: null,
        rfPowerUnit: Units.WATT,
        etchTime: null,
        etchTimeUnit: Units.SEC,
        commentField: null,
        customFields: {},
    }
);

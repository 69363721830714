import { useState } from "react";
import { Row, Column } from "../Layout/layouts";
import { RightPanel } from "./RightPanel";
import { BloxLibrary } from "./BloxLibrary";
import { RightPanelBloxInfo } from "./RightPanelBloxInfo";

export const enum PageNames {
    ProcessEditor,
    ModuleEditor,
    Viewer
}

export interface FabuPageProps { 
    pageName: PageNames, 
    headerContent: JSX.Element,
    children: React.ReactNode
}

export const FabuPage: React.FC<FabuPageProps> = (props) => {
    const { pageName, children } = props;
    const isFooterOpenState = useState(true);

    return (
        <Row style={{"alignItems": "center", "height": "calc(100% - 50px)" }}> 
            <Column className={"leftContainer"}>
                {props.headerContent}
                <Column className="processContainer">
                    {children}
                </Column>
                {pageName !== PageNames.Viewer &&
                    <Column className={"editor-footer"}>
                        <BloxLibrary pageName={pageName} isOpenState={isFooterOpenState} />
                    </Column>
                }
            </Column>
            <RightPanel>
                <RightPanelBloxInfo/>
            </RightPanel>
        </Row>
    );
}
import React, { createContext, useReducer, ReactNode } from 'react';
import { DrawCommand, SGVEngineReturnType, SVGDisplayMode, SVGEngineService } from '../../Services/SVGEngine';
import { FillDeposit, ThermalOxidation, ThinDeposit, WaferBonding, getStackActionFromBloxes } from '../../Data/stack-change';
import { AllBloxes, BloxTypes } from '../../Data/BloxSchema/base-blox';
import { StackChange } from '../../Data/enums';
import { materialFieldMap } from '../../Data/material-mappings';
import { getProperty, hasKey } from '../../utils/ts-helpers';

// Define the shape of the context value
interface ContextValue {
  generateSvgs: (bloxes: AllBloxes[], threeDim: boolean, svgDisplayMode: SVGDisplayMode) => JSX.Element[]
}

// Define the actions for the reducer
type Action = 
  | { type: 'add', key: string, value: SGVEngineReturnType }
  | { type: 'remove', key: string };

// Create the context with a default value
export const SvgServiceContext = createContext<ContextValue>({
  generateSvgs: () => []
});

// Define the shape of the props for the provider component
interface SvgServiceProviderProps {
  children: ReactNode;
}

// Define the reducer
function cacheReducer(state: Record<string, SGVEngineReturnType>, action: Action) {
  switch (action.type) {
    case 'add':
      return { ...state, [action.key]: action.value };
    case 'remove':
      {
        const newState = { ...state };
        delete newState[action.key];
        return newState;
      }
    default:
      throw new Error();
  }
}

const useGenerateSvgs = () => {
  const [cache, dispatch] = useReducer(cacheReducer, {});
  
  const generateSvgs = (bloxes: AllBloxes[], threeDim: boolean, svgDisplayMode: SVGDisplayMode) => {
    let svgs: JSX.Element[] = [];

     // this could come from an svgServiceProvider and generateSvg could become a useCallback if we move to a singleton pattern
    const svgService = new SVGEngineService();

    try {
        let history: DrawCommand[] = [];
        const materialLabelToIds: {[label: string] : string[]} = {};

        // leaving for debugging purposes
        //// const allStackChanges = getStackActionFromBloxes(bloxes);
        svgs = bloxes.map((blox, index) => {
          const stackChanges = getStackActionFromBloxes([blox], materialLabelToIds);

          // add new materialIDs to the mapping
          stackChanges.forEach(sc => {
              let materialId = "";
              if (sc.type === StackChange.FillDeposit) {
                  materialId = (sc as FillDeposit).materialId;
              } else if (sc.type === StackChange.ThinDeposit) {
                  materialId = (sc as ThinDeposit).materialId;
              } else if (sc.type === StackChange.ThermalOxidation) {
                  materialId = (sc as ThermalOxidation).materialId;
              } else if (sc.type === StackChange.WaferBonding) {
                  materialId = (sc as WaferBonding).materialId;
              }

              let materialParams = materialFieldMap[blox.bloxType];
              if (blox.isOxidized) {
                materialParams = materialFieldMap[BloxTypes.ThermalOxide];
              }

              if (materialId && sc.id.endsWith("bonding-agent")) {
                // special case for wafer bonding bonding agent - the material label is taken from a different place
                const materialLabel = blox.bondingAgentMaterial;
                if (materialLabel) {
                  if (materialLabel in materialLabelToIds) {
                    materialLabelToIds[materialLabel].push(materialId);
                  } else {
                    materialLabelToIds[materialLabel] = [materialId];
                  }
                } else {
                  const unknownLabel = "Unknown Bonding Agent";
                  if (unknownLabel in materialLabelToIds) {
                    materialLabelToIds[unknownLabel].push(materialId);
                  } else {
                    materialLabelToIds[unknownLabel] = [materialId];
                  }
                }
              } else if (materialId && materialParams && hasKey(blox, materialParams.materialFieldName)) {
                // common case - grab material name from materialFieldName as defined in material-mappings
                const materialLabel:string = getProperty(blox, materialParams.materialFieldName) as string;
                if (materialLabel) {
                  if (materialLabel in materialLabelToIds) {
                    materialLabelToIds[materialLabel].push(materialId);
                  } else {
                    materialLabelToIds[materialLabel] = [materialId];
                  }
                } else {
                  const unknownLabel = materialParams.unknownLabel;
                  if (unknownLabel in materialLabelToIds) {
                    materialLabelToIds[unknownLabel].push(materialId);
                  } else {
                    materialLabelToIds[unknownLabel] = [materialId];
                  }
                }


              } else if (blox.bloxType === BloxTypes.StartBlox) {
                // special case for StartBlox - can deposit multiple layers
                const bloxLayers = blox.layers ?? [];
                
                bloxLayers.forEach(bloxLayer => {
                  const materialLabel = bloxLayer.layerLabel;
                  materialId = bloxLayer.materialId;
                  if (materialLabel) {
                    if (materialLabel in materialLabelToIds) {
                      materialLabelToIds[materialLabel].push(materialId);
                    } else {
                      materialLabelToIds[materialLabel] = [materialId];
                    }
                  } else {
                    const unknownLabel = "Unknown Material";
                    if (unknownLabel in materialLabelToIds) {
                      materialLabelToIds[unknownLabel].push(materialId);
                    } else {
                      materialLabelToIds[unknownLabel] = [materialId];
                    }
                  }
                })
              }
          })

          const keyA = JSON.stringify(stackChanges);
          const keyB = JSON.stringify(history);
          const keyWithFlags = `${keyA}${keyB}${threeDim ? '1' : '0'}${svgDisplayMode}`

          // uncomment this to disable cache
          //const ret = svgService.generate(stackChanges, history, threeDim, svgDisplayMode);
          let ret = cache[keyWithFlags];
          if (!ret) {
              ret = svgService.generate(stackChanges, history, threeDim, svgDisplayMode);
              dispatch({ type: 'add', key: keyWithFlags, value: ret });
          }
          history = ret.history;
          
          return ret.svg;
        })
    } catch (e: any) {
        console.error('failed to generate svg in list preview' + e)
    }

    return svgs;

  };

  return generateSvgs
}

// Create the provider component
export const SvgServiceProvider: React.FC<SvgServiceProviderProps> = ({ children }) => {

  const generateSvgs = useGenerateSvgs();

  return (
    <SvgServiceContext.Provider value={{ generateSvgs }}>
      {children}
    </SvgServiceContext.Provider>
  );
};

import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { IColor } from "../../utils/Color";
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";

export interface WetGrowth extends BaseBlox {
    //EXPERIMENTAL
    doubleSided: boolean | null;
    material: string | null;
    solvents: string | null;
    thickness: number | null;
    thicknessUnit: Units | null;
    depositTime: number | null;
    depositTimeUnit: Units | null;
    depositTemp: number | null;
    depositTempUnit: Units | null;

    
    // DISPLAY
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;
}

export const WetGrowthDisplay: DisplayMap = {
    doubleSided: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Double-Sided Deposition",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    material: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposited Material",
        placeholder: "Enter material to deposit",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    solvents: {
        fieldType: DisplayFieldTypes.Input,
        label: "Solvent(s)",
        placeholder: "Enter chemical constituents",
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Film Thickness",
        placeholder: "Enter film thickness",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON,Units.MM],
        order: 3,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    depositTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Time",
        placeholder: "Enter process time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    depositTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Temperature",
        placeholder: "Enter process temperature",
        isNumber: true,
        units: [Units.CELSIUS,Units.KELVIN],
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },

    // DISPLAY
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 4,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    sidewallThickness: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sidewall Thickness",
        order: 3,
        tabs: [Tab.DISPLAY],
        defaultValue: 100
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 0,
        tabs: [Tab.DISPLAY],
    }
}

export const getWetGrowth = ({stepNumber}: BloxArgs): WetGrowth => ({
        name: `Wet Film Growth ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.WetGrowth,
        material: null,
        doubleSided: false,
        solvents: null,
        thickness: null,
        thicknessUnit: Units.NM,
        depositTime: null,
        depositTimeUnit: Units.SEC,
        depositTemp: null,
        depositTempUnit: Units.CELSIUS,

        commentField: null,
        customFields: {},
        layerColor: {R: 100, G: 100, B: 100, A: 1},
        layerThickness: 4,
        sidewallThickness: 100,
        layerLabel: null,
});

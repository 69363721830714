import { BloxTypes } from "./BloxSchema/base-blox";
import { LibCategory } from "./enums";

export const libCategoryMap: {[key in LibCategory]: BloxTypes[]} = {
    
    "Clean": [BloxTypes.Bake,BloxTypes.CustomClean,BloxTypes.RCAClean,BloxTypes.PlasmaAsh,BloxTypes.PlasmaDescum,BloxTypes.StripResist],

    "Deposition": [BloxTypes.ALD, BloxTypes.MOCVD, BloxTypes.PECVD, BloxTypes.LPCVD, BloxTypes.APCVD, BloxTypes.EbeamEvap, BloxTypes.ThermalEvap, BloxTypes.Sputter, BloxTypes.MBE,BloxTypes.HMDSVapor,BloxTypes.SpinCoat,BloxTypes.DropCast,BloxTypes.WetGrowth, BloxTypes.ThermalOxide,BloxTypes.Electroplate,BloxTypes.ParyleneDep],

    "Lithography": [BloxTypes.HMDSVapor,BloxTypes.SpinCoatResist,BloxTypes.Bake,BloxTypes.DirectWriteLitho,BloxTypes.MaskAlign,BloxTypes.StepperLitho,BloxTypes.InterferenceLitho,BloxTypes.EbeamLitho,BloxTypes.ImprintLitho,BloxTypes.ScanningProbeLitho,BloxTypes.Develop,BloxTypes.LiftOff,BloxTypes.PlasmaAsh,BloxTypes.StripResist, BloxTypes.FloodExpose],
    
    "Etch": [BloxTypes.RIE,BloxTypes.ICPRIE,BloxTypes.PlasmaAsh,BloxTypes.IonMilling,BloxTypes.Wetetch,BloxTypes.LiftOff,BloxTypes.StripResist],
    
    "Doping": [BloxTypes.SpinDopant,BloxTypes.IonImplantation,BloxTypes.DopeDiffusion,BloxTypes.RTA],
    
    "Other": [BloxTypes.FlipStack,BloxTypes.WaferBonding,BloxTypes.CMP,BloxTypes.RTA,BloxTypes.CustomBlox]
    
}
export const findAllCategoriesForBloxType = (bloxType: BloxTypes): LibCategory[] => {
    const categories = Object.keys(libCategoryMap) as LibCategory[];
    const matchingCategories = categories.filter(category => libCategoryMap[category].includes(bloxType));
    return matchingCategories; // Return an array of all matching categories
  };
/*
    Operations on pattern arrays
*/

// maximum allowed bit array size of pattern calculation
const MAX_BITARRAY_SIZE = 2**24

const PRIMES = [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 73, 79, 83, 89, 97, 101, 103, 107, 109, 113, 127, 131, 137, 139, 149, 151, 157, 163, 167];

export function factorize(num: number): number[] {
    const factors = [];
    for (let i = 2; i <= Math.sqrt(num); i++) {
       while (num % i === 0) {
          factors.push(i);
          num /= i;
       }
    }
    if (num > 1) {
       factors.push(num);
    }

    return [...new Set(factors)];
 }

// takes an array of numbers and divides it by the greatest common divisor (ignoring zeros)
export function divideArrayByGCD(ar: number[]) : number[] {
    // find smallest number that isn't 0
    let denom = 0;
    for (let i = 0; i < ar.length; i++) {
        if (ar[i] < denom || denom === 0) {
            denom = ar[i];
        }
    }
    // TODO: factorize denom
    for (; denom > 0; denom--) {
        let divideAll = true;
        // in C, the following code (&&=) is quicker than using if's (for large numbers). 
        // I wonder if it's the same in javascript....
        for (let i = 0; i < ar.length; i++) {
            divideAll &&= (ar[i] % denom === 0);
        }
        // if alternative:
        // for (let i = 0; i < result.length; i++) {
        //     if (result[i] % x == 0) {
        //         divideAll = false;
        //         break
        //     }
        // }
        if (divideAll) {
            break;
        }
    }
    if (denom > 1) {
        for (let i = 0; i < ar.length; i++) {
            ar[i] /= denom;
        }
    } 

    return ar;
}

// create a boolean map of pattern 1 of length newTotal
export function patternToBitarray(p: number[], s: number) : boolean[] {
    // e.g. [1,2,1,2,3] => [0, 1, 1, 0, 1, 1, 0, 0, 0]
    const b : boolean[] = [];
    let notGap  = false;
    for (let i = 0; i < p.length; i++) {
        for (let j = 0; j < p[i] * s; j++) {
            b.push(notGap);
        }
        notGap = !notGap;
    }
    return b;
}

// takes a bitarray and converts it back to a pattern array
export function bitarrayToPattern(b: boolean[]) : number[] {
    const p: number[] = [0];
    let lastSeen = false;
    for (let i = 0; i < b.length; i++) {
        if (b[i] === lastSeen) {
            p[p.length - 1]++;
        } else {
            p.push(1);
        }
        lastSeen = b[i];
    }

    return p;
}

export function scaleArrays(p1: number[], p2: number[]): {scale1: number, scale2: number, newTotal: number} {
    let total1: number = p1.reduce((partialSum, a) => partialSum + a, 0);
    let total2: number = p2.reduce((partialSum, a) => partialSum + a, 0);

    if (total1 === 0) {
        total1 = 1;
    }
    if (total2 === 0) {
        total2 = 1;
    }

    let newTotal: number = total1 * total2;
    let scale1: number = total2;
    let scale2: number = total1;
    // a few simple edge cases to reduce dimensions:
    if (total1 === total2) {
        // if the numbers are the same, no need to scale
        newTotal = total1;
        scale1 = 1;
        scale2 = 1; 
    } else if (total1 > total2 && total1 % total2 === 0) {
        // if one divides the other, scale only one array
        scale1 = 1;
        scale2 = total1 / total2;
        newTotal = total1;
    } else if (total2 > total1 && total2 % total1 === 0) {
        // if one divides the other, scale only one array
        scale1 = total2 / total1;
        scale2 = 1;
        newTotal = total2;
    } else {
        // else, try to find common factors
        for (let i = 0; i < PRIMES.length; ) {
            if (scale1 % PRIMES[i] === 0 && scale2 % PRIMES[i] === 0) {
                scale1 /= PRIMES[i];
                scale2 /= PRIMES[i];
                newTotal /= PRIMES[i];
            } else {
                i++;
            }

            if (scale1 > PRIMES[i] || scale2 > PRIMES[i]) {
                break;
            }
        }

        const BIG_NUM = Math.pow(2,12);
        
        if (scale1 < BIG_NUM && scale2 > scale1) { 
            const factors = factorize(scale1);
            for (let i = 0; i < factors.length; i++) {
                if (scale2 % factors[i] === 0) {
                    scale1 /= factors[i];
                    scale2 /= factors[i];
                    newTotal /= factors[i];
                }
            }
        } else if (scale2 < BIG_NUM && scale1 > scale2) {
            const factors = factorize(scale2);
            for (let i = 0; i < factors.length; i++) {
                if (scale1 % factors[i] === 0) {
                    scale1 /= factors[i];
                    scale2 /= factors[i];
                    newTotal /= factors[i];
                }
            }
        }            
    }

    if (newTotal > MAX_BITARRAY_SIZE) {
        console.log("MAX_BITARRAY_SIZE: ", MAX_BITARRAY_SIZE);
        throw new Error(`SVG Engine Overflow ${newTotal} = ${scale1} * ${scale2}`);
    }

    return {scale1, scale2, newTotal};
}

export function bitwisePatternOp(op: string, p1: number[], p2: number[]) :  number[] {
    const {scale1, scale2, newTotal} = scaleArrays(p1, p2);

    // create a boolean map of pattern 1 of length newTotal
    // e.g. [1,2,1,2,3] => [0, 1, 1, 0, 1, 1, 0, 0, 0]
    const bit1 : boolean[] = patternToBitarray(p1, scale1);
    const bit2 : boolean[] = patternToBitarray(p2, scale2);

    // calculate the boolean intersection/subtraction
    
    const bitResult: boolean[] = new Array(newTotal).fill(false);
    if (op === "SUPPORT") {
        const bitSupportRight: boolean[] = new Array(newTotal).fill(false);
        const bitSupportLeft: boolean[] = new Array(newTotal).fill(false);
        let supported = false;
        // create a bitarray that is true for right supports
        for (let i = 0; i < newTotal; i++) {
            if (bit1[i] && bit2[i]) {
                supported = true;
            } else if (!bit1[i] && !bit2[i]) {
                supported = false;
            } 

            if (bit1[i] && supported) {
                bitSupportRight[i] = true;
            }
        }
        // create a bitarray that is true for left supports
        supported = false;
        for (let i = newTotal-1; i >= 0; i--) {
            if (bit1[i] && bit2[i]) {
                supported = true;
            } else if (!bit1[i] && !bit2[i]) {
                supported = false;
            } 
            
            if (bit1[i] && supported) {
                bitSupportLeft[i] = true;
            } 
        }
        // join the bitarrays
        for (let i = 0; i < newTotal; i++) {
            bitResult[i] = bitSupportRight[i] || bitSupportLeft[i];
        }
    } else {
        for (let i = 0; i < newTotal; i++) {
            if (op === "INTERSECT") {
                bitResult[i] = bit1[i] && bit2[i];
            } else if (op === "SUBTRACT") {
                bitResult[i] = bit1[i] && !bit2[i];
            } else if (op === "UNION") {
                bitResult[i] = bit1[i] || bit2[i];
            }
        }
    }    

    // transform back to a non binary pattern
    let result: number[] = bitarrayToPattern(bitResult);

    result = divideArrayByGCD(result);

    return result;
}

// given two patterns, returns the intersection p1 and p2
export function intersectPatterns(p1: number[], p2: number[]): number[] {
    return bitwisePatternOp("INTERSECT", p1, p2);
}

// subtract two patterns, p1 - p2
export function subtractPatterns(p1: number[], p2: number[]): number[] {
    return bitwisePatternOp("SUBTRACT", p1, p2);
}

// union two patterns, p1 + p2
export function unionPatterns(p1: number[], p2: number[]): number[] {
    return bitwisePatternOp("UNION", p1, p2);
}

// returns parts of p1 that are partly supported  by p2
export function intersectSupport(p1: number[], p2: number[]): number[] {
    return bitwisePatternOp("SUPPORT", p1, p2);
}



export function reversePattern(p: number[]): number[] {
    if (p.length > 1 && p[0] === 0) {
        return p.slice(1);
    } else {
        return [0, ...p];
    }
}

// returns the largest non-gap
export function largestNonGap(p:number[]): number {
    const total: number = p.reduce((partialSum, a) => partialSum + a, 0);

    if (total === 0) {
        return 0;
    }

    let largest = 0;
    let notGap  = false;
    for (let i = 0; i < p.length; i++) {
        if (notGap && p[i] > largest) {
            largest = p[i];
        }
        notGap = !notGap;
    }

    return largest / total;
}

//inflate the pattern
// width: total width (in pixels)
// inflate: how many pixels to add to each side of the pattern
export function inflatePattern(p: number[], width: number, inflate:number): number[] {
    const total: number = p.reduce((partialSum, a) => partialSum + a, 0);
    const d = Math.ceil(width / total);
    const scaledInflate = Math.floor(inflate * d * total / width);

    const bit : boolean[] = patternToBitarray(p, d);

    // add to the right
    let prev = false;
    let count = scaledInflate;
    for (let i = 0; i < bit.length; i++) {
        if (count > 0 && !bit[i] && prev) {
            // add inflate
            bit[i] = true;
            count -= 1;
        } else {
            count = scaledInflate;
        }

        prev = bit[i];
    }

    // add to the left
    prev = false;
    count = scaledInflate;
    for (let i = bit.length-1; i >= 0; i--) {
        if (count > 0 && !bit[i] && prev) {
            // add inflate
            bit[i] = true;
            count -= 1;
        } else {
            count = scaledInflate;
        }

        prev = bit[i];
    }

    // transform back to a non binary pattern
    let result: number[] = bitarrayToPattern(bit);

    result = divideArrayByGCD(result);

    return result;
}

export function inflatePatternWithLimits(p: number[], width: number, inflate:number, limits: number[]): number[] {
    const {scale1, scale2, newTotal} = scaleArrays(p, limits);
    const d = Math.ceil(width / newTotal);

    // create a boolean map of pattern 1 of length newTotal * d
    // e.g. [1,2,1,2,3] => [0, 1, 1, 0, 1, 1, 0, 0, 0]
    const bit : boolean[] = patternToBitarray(p, scale1 * d);
    const bitLimits : boolean[] = patternToBitarray(limits, scale2 * d);
    
    const scaledInflate = Math.ceil(inflate * d * newTotal / width);

    // add to the right
    let prev = false;
    let count = scaledInflate;
    for (let i = 0; i < bit.length; i++) {
        if (count > 0 && !bit[i] && prev && !bitLimits[i]) {
            // add inflate
            bit[i] = true;
            count -= 1;
        } else {
            count = scaledInflate;
        }

        prev = bit[i];
    }

    // add to the left
    prev = false;
    count = scaledInflate;
    for (let i = bit.length-1; i >= 0; i--) {
        if (count > 0 && !bit[i] && prev && !bitLimits[i]) {
            // add inflate
            bit[i] = true;
            count -= 1;
        } else {
            count = scaledInflate;
        }

        prev = bit[i];
    }

    // transform back to a non binary pattern
    let result: number[] = bitarrayToPattern(bit);

    result = divideArrayByGCD(result);

    return result;
}

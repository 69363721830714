import React, { useState } from 'react';
import { Button, Dialog, TextArea, Intent, FormGroup } from '@blueprintjs/core';
import { useAuth0 } from '@auth0/auth0-react';
import { AllBloxes } from '../Data/BloxSchema/base-blox';
import { v4 as uuidv4 } from 'uuid';
import { useFabuState } from '../hooks/state/use-fabu-state';
import { BASE_FABUBLOX_API_URL } from '../utils/constants';
import { showToast } from '..';
import { CreateProcessRequest, Process } from '../__generated__/Process';


interface ReportProblemProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    bloxIdx: number;
    bloxes: AllBloxes[];
}

export const ReportProblemDialog: React.FC<ReportProblemProps> = ({isOpen, setIsOpen, bloxIdx, bloxes}) => {
    const [message, setMessage] = useState('');
    const [processId] = useFabuState('processId');
    const [processSections] = useFabuState('processSections');
    const { getAccessTokenSilently, user } = useAuth0();

    const issueId = uuidv4();


    const handleClose = () => {
        setIsOpen(false);
        setMessage('');
    }

    const handleSubmit = async () => {
        const discordWebhookURL = 'https://discord.com/api/webhooks/1151202493548736624/n_D5mStgFcly5n1L52CTY4M347K8mOU4lW22a7Maa5sqikIPmBCQI-Yy59eRWLdyADWb';
        const token = await getAccessTokenSilently();

        const duplicateProcess: CreateProcessRequest = {
            bloxes: bloxes,
            sections: processSections,
            processName: `Image Issue: ${issueId}`
        };

        let duplicateProcessId: null | string = null;

        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
                body: JSON.stringify(duplicateProcess)
            };

            const res = await fetch(`${BASE_FABUBLOX_API_URL}/api/process/report/`, requestOptions);
            if (res.ok)
                showToast({
                    message: "Issue Reported",
                    intent: Intent.PRIMARY,
                    timeout: 3000
                });
            const resBody = (await res.json()) as Process;
            duplicateProcessId = resBody.processId;
        } catch (error) {
            console.error(error);
        }

        const discordMessage = {
            content: `**IMAGE ISSUE:** \n Issue ID: ${issueId} \n Original Process Id: ${processId} \n Duplicate Process Id ${duplicateProcessId} \n Step Number: ${bloxIdx + 1} \n Username: ${user?.name} \n\n**Message:** ${message}`
        };

        try {
            await fetch(discordWebhookURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(discordMessage)
            });

            handleClose();
        } catch (error) {
            console.error('Error sending message to Discord:', error);
        }

        
    };

    const defaultStyle = {
        minHeight: '200px',
        marginBottom: '10px',
    }

    return (
        <>
            <Dialog
                isOpen={isOpen}
                onClose={handleClose}
                title="Image Problem"
            >
                <div className="bp5-dialog-body">
                    <FormGroup
                    >
                    <TextArea
                        placeholder="Your message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        growVertically={true}
                        large={true}
                        fill={true}
                        style={defaultStyle}
                    />
                    </FormGroup>
                    The Fabublox Team will review a copy of your process along with the information submitted here. We appreciate your feedback!
                </div>
                <div className="bp5-dialog-footer">
                    <div className="bp5-dialog-footer-actions">
                        <Button text="Submit" intent={Intent.PRIMARY} onClick={handleSubmit} />
                    </div>
                </div>
            </Dialog>
        </>
    );
}
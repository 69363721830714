import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ButtonGroup, Button, Divider, Classes, Intent, ButtonProps, Menu, MenuItem, EditableText, Tag, Tooltip, Popover, Switch, Colors, Icon } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { Row } from '../Layout/layouts';
import { IconNames } from '@blueprintjs/icons';
import { useFabuState } from '../hooks/state/use-fabu-state';
import { useClearProcess } from './hooks/use-clear-process';
import { useAuth0 } from '@auth0/auth0-react';
import { useProcessHandlers } from './hooks/use-process-handlers';
import { DuplicateDialogButton } from "../dialogs/DuplicateDialogButton";
import { SharingPicker } from "./SharingPicker";
import { openDialogAndWaitForInput } from "../dialogs/SaveYourChangesDialog";
import { SharingProcessButton } from "../dialogs/SharingProcessDialog";
import { useProcessExporter } from "../utils/export-process";
import { ProcessSettingsContext } from "../hooks/state/process-settings-provider";
import { UnsavedChangesContext } from "../hooks/state/unsaved-changes-provider";
import { StateAndSetter } from "../hooks/state/parameter-context";
import SemifabDialog from "../dialogs/SemifabDialog";
import { useEnableStackSimulator } from "../hooks/use-enable-stacksimulator";
import { showToast } from "..";
export interface ProcessHeaderProps {
    isModule?: boolean;
    closedSectionState?: StateAndSetter<{[key: string]: boolean}>;
  }    

export const ProcessHeader: React.FC<ProcessHeaderProps> = (props) => {
    const { closedSectionState, isModule } = props;
    const allSectionsClosed = useMemo(() => {
        if (!closedSectionState) return false;
        const closedSections = closedSectionState[0];
        const values = Object.values(closedSections);
        return values.length > 0 ? values.every(val => val) : false;
    }, [closedSectionState]);
    const {isStack3D, setIsStack3D, isStackHidden, setIsStackHidden} = useContext(ProcessSettingsContext);
    const [processId] = useFabuState('processId');
    const [processName, setProcessName] = useFabuState('processName');
    const [tempProcessName, setTempProcessName] = useState<string | undefined>(undefined);
    const [processIsReadOnly] = useFabuState('processIsReadOnly');
    const { editorUnsavedChanges, setEditorUnsavedChanges, setShowSaveYourChangesDialog } = useContext(UnsavedChangesContext);
    const { isProcessInfoOpen, setIsProcessInfoOpen } = useContext(ProcessSettingsContext);
    const [isSemifabDialogOpen, setIsSemifabDialogOpen] = useState(false);
    const toggleIsProcessInfoOpen = useCallback(() => setIsProcessInfoOpen(!isProcessInfoOpen), [isProcessInfoOpen, setIsProcessInfoOpen]);
    const { exportData, isLoading } = useProcessExporter(processId, isModule);

    const { processHandleSave, processHandleDuplicate } = useProcessHandlers();

    const { user, getAccessTokenSilently } = useAuth0();


    const history = useHistory();

    const clearProcess = useClearProcess();
    const createNewProcess = useCallback(async () => {
        if (editorUnsavedChanges) {
            const shouldContinue = await openDialogAndWaitForInput(setShowSaveYourChangesDialog, setEditorUnsavedChanges);
            if (!shouldContinue) return;
        }
        
        clearProcess();
        history.push(`/${isModule ? 'module' : 'process'}-editor/create`);
    }, [editorUnsavedChanges, setEditorUnsavedChanges, setShowSaveYourChangesDialog, history, clearProcess, isModule])

    const defaultProps: ButtonProps = {
        intent: Intent.NONE,
        minimal: true,
        large: true,
    };

    const settingsMenu = (
        <Menu>
            <MenuItem onClick={() => setIsStackHidden(!isStackHidden)} icon={isStackHidden ? IconNames.EYE_OPEN : IconNames.EYE_OFF} text={`${isStackHidden ? "Show" : "Hide"} Stack`} />
            {/* {!isStackHidden && <MenuItem onClick={() => setIsStack3D(!isStack3D)} icon={isStack3D ? IconNames.LIST : IconNames.LAYERS} text={`Show ${isStack3D ? "2D" : "3D (beta)"}`} />} */}
        </Menu>
    );

    const onConfirmProcessName = useCallback(() => {
        if (tempProcessName) {
            setProcessName(tempProcessName);
            setTempProcessName(undefined);
        }
    }, [setProcessName, tempProcessName]);

    const onChangeProcessName = useCallback((value: string) => {
        setEditorUnsavedChanges(true);
        setTempProcessName(value);
    }, [setTempProcessName, setEditorUnsavedChanges]);

    const handleCollapseSections = useCallback(() => {
        if (!closedSectionState) return;
        const [, setClosedSections] = closedSectionState;
        setClosedSections(prevClosedSections => {
            const newState = {...prevClosedSections};
            for (const sectionId in newState) {
                newState[sectionId] = true;
            }
            return newState;
        
        });
    }, [closedSectionState]);

    const handleExpandSections = useCallback(() => {
        if (!closedSectionState) return;
        const [, setClosedSections] = closedSectionState;
        setClosedSections(prevClosedSections => {
            const newState = {...prevClosedSections};
            for (const sectionId in newState) {
                newState[sectionId] = false;
            }
            return newState;
        
        });
    }, [closedSectionState]);

    const { userData, userDataIsLoading, enableStackSimulator } = useEnableStackSimulator();
    const stackSimulatorEnabled = !!userData?.enabledFeatures.stackSimulator;

    const activateToast = {
    message: (
        <div>
            <strong><Icon icon={IconNames.TICK_CIRCLE} intent={Intent.SUCCESS} />  StackSimulator Activated</strong>
            <br/><br/>
            <p>
                <em>(required)</em> Fill out the <strong>'Simulate'</strong> tab for each Blox in the right panel.
            </p>
        </div>
    ),
    intent: Intent.SUCCESS,
    timeout: 10000,
};

    return <Row>
        {!processIsReadOnly && !isModule && <SharingPicker />}
        {processIsReadOnly && <div style={{ marginTop: 'auto', marginBottom: 'auto', paddingLeft: '10px' }}><Tooltip content={`Duplicate this ${isModule ? 'module' : 'process'}, or create your own to make changes.`}><Tag intent={Intent.WARNING}>Read-only</Tag></Tooltip></div>}
        <h5 className={Classes.HEADING} style={{ margin: 'auto 0px auto 10px' }}>
            <Tooltip content={processName} placement={'bottom'}>
                <div style={{ maxWidth: '400px' }}>
                    <EditableText disabled={processIsReadOnly} placeholder={`${isModule ? 'Module' : 'Process'} Name...`} value={tempProcessName ?? processName} onChange={onChangeProcessName} onConfirm={onConfirmProcessName} />
                </div>
            </Tooltip>
        </h5>
        <Tooltip content={`${isProcessInfoOpen ? 'Hide' : 'Show'} ${isModule ? 'Module' : 'Process'} Info`}>
            <Button style={{marginTop: '5px'}} minimal={true} icon={IconNames.InfoSign} onClick={toggleIsProcessInfoOpen} />
        </Tooltip>
        {!isModule && <Tooltip content={`${allSectionsClosed ? 'Expand' : 'Collapse'} Sections`}>
            <Button style={{marginTop: '5px'}} minimal={true} icon={allSectionsClosed ? IconNames.ExpandAll : IconNames.CollapseAll} onClick={allSectionsClosed ? handleExpandSections : handleCollapseSections} />
        </Tooltip>}

        <ButtonGroup style={{ marginLeft: 'auto' }}>
            <Button icon={IconNames.PLUS} onClick={createNewProcess} text={'New'} {...defaultProps} />
            <Divider />
            {!processIsReadOnly && <><Button icon={IconNames.CONFIRM} onClick={async () => {
                const token = await getAccessTokenSilently();
                processHandleSave(processId, token)
            }} text={'Save'} {...defaultProps}
            /> <Divider /></>
            }
            { !processIsReadOnly && !isModule && 
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            icon={IconNames.LAB_TEST}
                            onClick={() => setIsSemifabDialogOpen(true)}
                            text={'Simulate'}
                            disabled={userDataIsLoading || stackSimulatorEnabled === false}
                            {...defaultProps}
                        />
                        {(!userDataIsLoading && stackSimulatorEnabled === false) && <Tag
                            minimal
                            style={{ backgroundColor: Colors.BLUE3, color: Colors.WHITE, cursor: 'pointer' }}
                            onClick={() => {
                                showToast(activateToast);
                                enableStackSimulator(true);
                            }}
                        >
                            Activate!
                        </Tag>}
                        {(stackSimulatorEnabled) && <Tag
                            minimal
                            style={{ backgroundColor: Colors.BLUE3, color: Colors.WHITE }}
                        >
                            Beta
                        </Tag>}
                    </div>
                    <Divider />
                </>
            }
            <DuplicateDialogButton buttonProps={defaultProps} onDuplicate={async (newProcessName: string) => {
                if (!user) {
                    history.push(`/${isModule ? 'module' : 'process'}-editor/${processId}`);
                    return;
                }
                const token = await getAccessTokenSilently();
                processHandleDuplicate(newProcessName, token)
            }} text={'Save As...'} {...defaultProps}
            />
            <Divider />
            {!processIsReadOnly && <>
                <SharingProcessButton isModule={isModule} />
                <Divider /></>}
            {user && !isModule && <>
                <Tooltip content={'Download list view as docx'}>
                    <Button icon={IconNames.DOWNLOAD} {...defaultProps} onClick={exportData} loading={isLoading} />
                </Tooltip>
                <Divider />
            </>}           
            <Popover content={settingsMenu} placement={'bottom'} >
                <Button icon={IconNames.More} {...defaultProps} />
            </Popover> 
        </ButtonGroup>
        <SemifabDialog isOpen={isSemifabDialogOpen} setIsOpen={setIsSemifabDialogOpen} />
    </Row>
}

import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { IColor } from "../../utils/Color";
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";

export interface ALD extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    thickness: number | null;
    thicknessUnit: Units | null;
    numCycles: number | null;
    substrateTemp: number | null;
    substrateTempUnit: Units | null;
    chamberPressure: number | null;
    chamberPressureUnit: Units | null;
    precursor1: string | null;
    pulseTimePre1: number | null;
    pulseTimePre1Unit: Units | null;
    waitTimePre1: number | null;
    waitTimePre1Unit: Units | null;
    precursor2: string | null;
    pulseTimePre2: number | null;
    pulseTimePre2Unit: Units | null;
    waitTimePre2: number | null;
    waitTimePre2Unit: Units | null;
    // DISPLAY
    doubleSided: boolean | null;
    selectiveGrowth : boolean | null;
    layerLabelsToGrow: string[] | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;
}

export const ALDDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposited Material",
        placeholder: "Enter material to deposit",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Film thickness",
        placeholder: "Enter film thickness",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 2,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    numCycles: {
        fieldType: DisplayFieldTypes.Input,
        label: "Number of Deposition Cycles",
        placeholder: "Enter number of cycles run",
        isNumber: true,
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    substrateTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Substrate Temperature",
        placeholder: "Enter substrate temperature",
        isNumber: true,
        units: [Units.CELSIUS,Units.KELVIN],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    chamberPressure: {
        fieldType: DisplayFieldTypes.Input,
        label: "Chamber Base Pressure",
        placeholder: "Enter base pressure",
        isNumber: true,
        units: [Units.TORR,Units.PASCAL,Units.BAR],
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },
    precursor1: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursor 1",
        placeholder: "Enter precursor name",
        order: 6,
        tabs: [Tab.EXPERIMENTAL],
    },
    pulseTimePre1: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursor 1 Pulse Time",
        placeholder: "Enter pulse time",
        isNumber: true,
        units: [Units.MILLISEC,Units.SEC],
        order: 7,
        tabs: [Tab.EXPERIMENTAL],
    },
    waitTimePre1: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursor 1 Wait Time",
        placeholder: "Enter wait time",
        isNumber: true,
        units: [Units.MILLISEC,Units.SEC],
        order: 8,
        tabs: [Tab.EXPERIMENTAL],
    },
    precursor2: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursor 2",
        placeholder: "Enter precursor name",
        order: 9,
        tabs: [Tab.EXPERIMENTAL],
    },
    pulseTimePre2: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursor 2 Pulse Time",
        placeholder: "Enter pulse time",
        isNumber: true,
        units: [Units.MILLISEC,Units.SEC],
        order: 10,
        tabs: [Tab.EXPERIMENTAL],
    },
    waitTimePre2: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursor 2 Wait Time",
        placeholder: "Enter wait time",
        isNumber: true,
        units: [Units.MILLISEC,Units.SEC],
        order: 11,
        tabs: [Tab.EXPERIMENTAL],
    },

    // DISPLAY
    doubleSided: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Two-Sided Deposition",
        order: 0,
        tabs: [Tab.DISPLAY ,Tab.SEMIFAB],
    },
    selectiveGrowth: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Selective/Epitaxial Growth",
        order: 1,
        tabs: [Tab.DISPLAY]
    },
    layerLabelsToGrow: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Growth Substrate Material",
        order: 2,
        tabs: [Tab.DISPLAY]
    },
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 4,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Thickness",
        order: 4,
        tabs: [Tab.DISPLAY]
    },
    sidewallThickness: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sidewall Thickness",
        order: 3,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
        defaultValue: 100
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 3,
        tabs: [Tab.DISPLAY]
    }
}

export const getALD = ({stepNumber}: BloxArgs): ALD => ({
        name: `ALD ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.ALD,
        material: null,
        doubleSided: false,
        thickness: null,
        thicknessUnit: Units.ANG,
        numCycles: null,
        substrateTemp: null,
        substrateTempUnit: Units.CELSIUS,
        chamberPressure: null,
        chamberPressureUnit: Units.TORR,
        precursor1: null,
        pulseTimePre1: null,
        pulseTimePre1Unit: Units.MILLISEC,
        waitTimePre1: null,
        waitTimePre1Unit: Units.MILLISEC,
        precursor2: null,
        pulseTimePre2: null,
        pulseTimePre2Unit: Units.MILLISEC,
        waitTimePre2: null,
        waitTimePre2Unit: Units.MILLISEC,
        commentField: null,
        customFields: {},
        layerColor: {R: 75, G: 210, B: 75, A: 1},
        layerThickness: 4,
        sidewallThickness: 100,
        layerLabel: null,
        selectiveGrowth: false,
        layerLabelsToGrow: []
});

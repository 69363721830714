import { Dialog, Button, Intent, Classes, Switch, FormGroup, InputGroup, TextArea } from '@blueprintjs/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Input } from '../components/Fields/Input';
import { getStartBlox } from '../Data/BloxSchema/start-blox';
import { useFabuState } from '../hooks/state/use-fabu-state';
import UserSearch from '../components/Fields/UserSearch';
import { AdditionalOwnerUser } from '../__generated__/Process';
import { v4 as uuidv4 } from 'uuid';

export const CreateProcessDialog: React.FC<{isModule?: boolean}> = (props) => {
    const { isModule } = props;
    const history = useHistory();
    const { processId } = useParams<{ processId: string }>();
    const [, setProcessName] = useFabuState('processName');
    const [, setProcessBloxes] = useFabuState('processBloxes');
    const [, setProcessSections] = useFabuState('processSections');
    const [, setProcessId] = useFabuState('processId');
    const [, setProcessIsPrivate] = useFabuState('processIsPrivate');
    const [, setProcessAdditionalOwners] = useFabuState('processAdditionalOwners');

    const [processDesc, setProcessDesc] = useFabuState('processDesc');
    const [processReference, setProcessReference] = useFabuState('processReference');

    const [desc, setDesc] = useState<string | undefined>(undefined);
    const [reference, setReference] = useState<string | undefined>(undefined);

    const handleDescChange = useCallback((event: any) => {
        setDesc(event.target.value);
    }, [setDesc]);

    const handleReferenceChange = useCallback((event: any) => {
        setReference(event.target.value)
    }, [setReference]);

    const handleDescBlur = useCallback(() => {
        if (desc === undefined) return;
        setProcessDesc(desc);
    }, [setProcessDesc, desc]);

    const handleReferenceBlur = useCallback((event: any) => {
        if (reference === undefined) return;
        setProcessReference(reference);
    }, [reference, setProcessReference]);


    const [selectedOwners, setSelectedOwners] = useState<AdditionalOwnerUser[]>([]);
    const handleOwnersChange = useCallback((owners: AdditionalOwnerUser[]) => {
        setSelectedOwners(owners);
    }, []);
    const selectedOwnersMemo = useMemo(() => selectedOwners, [selectedOwners])

    const [isOpen, setIsOpen] = useState(false);
    const [isPrivate, setIsPrivate] = useState(true);

    useEffect(() => {
        if (processId === 'create') {
            setIsOpen(true)
            setInputValueDict({});
        } else {
            setIsOpen(false);
        }
    }, [processId])

    const [inputValueDict, setInputValueDict] = useState({} as { [key: string]: string });

    const validateCallback = useCallback((val: string) => !!(val && val.length < 100), []);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        setIsPrivate(true);
        setSelectedOwners([]);
        setReference('');
        setDesc('');
        history.push(`/${isModule ? 'module' : 'process'}-editor/new`);
    }, [setSelectedOwners, setIsOpen, setIsPrivate, history, isModule]);

    const handleCreateAndClose = useCallback(() => {
        if (!validateCallback(inputValueDict['processName']))
            return;
        const startBlox = getStartBlox();
        setIsOpen(false);
        setProcessName(inputValueDict['processName']);
        setProcessSections([{
            sectionId: uuidv4(),
            bloxIds: [startBlox.id]
        }])
        setProcessBloxes([startBlox]);
        setProcessIsPrivate(isPrivate);
        setProcessAdditionalOwners(selectedOwners);
        setProcessId('new'); // this will be set on initial save
        setProcessDesc(desc ?? '');
        setProcessReference(reference ?? '');
        handleClose();
    }, [desc, reference, selectedOwners, validateCallback, inputValueDict, isPrivate, handleClose, setProcessSections]);

    const resourceName = isModule ? 'Module' : 'Process';

    return <Dialog isOpen={isOpen} title={`Create a ${isModule ? 'Module' : 'Process'}`} canOutsideClickClose={false} onClose={handleClose}>
        <div className={Classes.DIALOG_BODY}>
            <FormGroup>
                {
                    !isModule && <Switch checked={!isPrivate} onChange={() => setIsPrivate(!isPrivate)} label={`Private/Public - Enable to share process publicly in Fabubase`}></Switch>
                }
                <FormGroup style={{marginBottom: '10px', marginTop: '20px'}}>
                    <Input
                        key={'processName'}
                        id={'processName'}
                        inputValueDictState={[inputValueDict, setInputValueDict]}
                        label={`${resourceName} Name (required)`}
                        inputGroupProps={{
                            value: inputValueDict['processName'],
                            placeholder: `Enter ${resourceName} name`
                        }}
                        warningText={`${resourceName} name is required and must be under 100 characters.`}
                        warningTitle={`${resourceName} name is required.`}
                        validation={validateCallback}
                    />
                </FormGroup>
                <FormGroup label={`Additional ${resourceName} Owners`}>
                    <UserSearch onSelectionChange={handleOwnersChange} selectedOwners={selectedOwnersMemo} />
                </FormGroup>
                <FormGroup>
                <FormGroup label={'Reference'} style={{ paddingRight: '10px', minWidth: '250px' }}>
                    <InputGroup onChange={handleReferenceChange} value={reference === undefined ? processReference : reference} onBlur={handleReferenceBlur} placeholder={`Add a literature reference or a Fabublox reference for your ${resourceName}`}/>
                </FormGroup>
                <FormGroup label={`${resourceName} Description`}>
                    <TextArea style={{ width: '100%', height: '120px' }} value={desc === undefined ? processDesc : desc} onChange={handleDescChange} onBlur={handleDescBlur} placeholder={`Enter a description of your ${resourceName}, including its goals, details and functionality of fabricated structures, and any other information`}/>
                </FormGroup>
            </FormGroup>
                <Button style={{ float: 'right' }} intent={Intent.SUCCESS} onClick={handleCreateAndClose}>Create {resourceName}</Button>
            </FormGroup>
        </div>
    </Dialog>;
}
import React, { createContext, useState, ReactNode } from 'react';
import { noOp } from '../../utils/empty-arrow-function-helper';

interface ProcessSettingsProviderProps {
    children: ReactNode;
    isModule?: boolean;
 }

interface SettingsContextValue {
    isStackHidden: boolean;
    setIsStackHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isStack3D: boolean;
    setIsStack3D: React.Dispatch<React.SetStateAction<boolean>>;
    isProcessInfoOpen: boolean;
    setIsProcessInfoOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValue = {
    isStackHidden: false,
    setIsStackHidden: noOp,
    isStack3D: false,
    setIsStack3D: noOp,
    isProcessInfoOpen: false,
    setIsProcessInfoOpen: noOp,
};

export const ProcessSettingsContext = createContext<SettingsContextValue>(defaultValue);

export const ProcessSettingsProvider: React.FC<ProcessSettingsProviderProps> = ({ children, isModule }) => {
    const [isStackHidden, setIsStackHidden] = useState(!!isModule);
    const [isStack3D, setIsStack3D] = useState(false);
    const [isProcessInfoOpen, setIsProcessInfoOpen] = useState(false);
    return (
        <ProcessSettingsContext.Provider value={{ 
            isStackHidden, setIsStackHidden, 
            isStack3D, setIsStack3D,
            isProcessInfoOpen, setIsProcessInfoOpen,
            }}>
            {children}
        </ProcessSettingsContext.Provider>
    );
};

import { Dialog, Classes, Button, Intent } from "@blueprintjs/core";
import { Row } from "../Layout/layouts";
import { BASE_FABUBLOX_API_URL } from "../utils/constants";
import { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { showToast } from "..";

interface DeleteDialogInterface {
    isOpen: boolean,
    closeDialog: () => void,
    onDelete: () => void,
    id?: string,
    name: string,
    deleteUri?: string,
    customMessage?: string
}

async function deleteResource(uri: string, name: string, id: string, getAccessTokenSilently: () => Promise<string>) {
    const token = await getAccessTokenSilently();

    const requestOptions = {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
    };

    const deleteToast = {
        message: `${name} deleted.`,
        intent: Intent.WARNING,
        timeout: 3000
    };

    fetch(`${BASE_FABUBLOX_API_URL}/api/${uri}/${id}`, requestOptions)
        .then(res => {
            if (res.ok)
                showToast(deleteToast)
            else
                showToast({
                    message: "Delete Failed",
                    intent: Intent.DANGER,
                    timeout: 3000
                });

        })
        .catch((error: any) => console.error(`Delete failed with ${error.message}`));
}

export function DeleteDialog({ isOpen, onDelete, closeDialog, id, name, deleteUri, customMessage }: DeleteDialogInterface) {

    const { getAccessTokenSilently } = useAuth0();

    const deleteCallback = useCallback(() => {
        if (deleteUri && id){
            deleteResource(deleteUri, name, id, getAccessTokenSilently);
        }
        onDelete();
        closeDialog();
    }, [name, closeDialog, deleteUri, getAccessTokenSilently, id, onDelete]);

    const cancelCallback = useCallback(() => closeDialog(), [closeDialog]);

    return <>
        <Dialog isOpen={isOpen} title='Warning' icon='warning-sign' isCloseButtonShown={false}>
            <div className={Classes.DIALOG_BODY}>
                <p>
                    <strong>
                        <i>{name}</i> will be deleted.
                    </strong>
                </p>
                {customMessage && <p>{customMessage}</p>}
                <p>
                    Do you want to continue?
                </p>
                <Row style={{ margin: "0 20px" }}>
                <Button onClick={cancelCallback} style={{ margin: 'auto 5px auto auto' }}>Cancel</Button>
                <Button onClick={deleteCallback} style={{ margin: 'auto 0px auto 0px' }} intent={Intent.DANGER}>Continue</Button>
            </Row>
            </div>
            
        </Dialog>
    </>
}
import React from "react";

export type StateAndSetter<T> = [T, React.Dispatch<React.SetStateAction<T>>];

export type ParameterProviderRegistry<T=any> = {
    [K in keyof T]: StateAndSetter<T[K]>
};

export type ExtractParameterType<T> = T extends StateAndSetter<infer U> ? U : never;

export type ExtractParametersType<T> = {
    [K in keyof T]: ExtractParameterType<T[K]>
};


export interface ParameterContextType<T=any> {
    /**
     * A map of named parameter [state, setter] pairs to set as the parameter context
     */
    providers: ParameterProviderRegistry<T>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ParameterContext = React.createContext<ParameterContextType>(null!);

export type ParameterContextProvierParams<T=any> = ParameterContextType<T>

export function ParameterContextProvider<T=any>({
    providers, 
    children
}: React.PropsWithChildren<ParameterContextProvierParams<T>>) {
    return <ParameterContext.Provider value={{providers}}>{children}</ParameterContext.Provider>;
}

import { useCallback, useContext, useState } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd"
import { Draggables } from "../Data/enums";
import { Button, Colors, Position, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Column, Row } from "../Layout/layouts";
import { SelectBlox } from "./SelectBlox";
import { AllBloxes } from "../Data/BloxSchema/base-blox";
import { ProcessSettingsContext } from "../hooks/state/process-settings-provider";
import { SelectModule } from "./SelectModule";
import { BloxInsertItem } from "./BloxDraggable";
import { ModuleInsertItem } from "./ModuleDraggable";
import { useFabuState } from "../hooks/state/use-fabu-state";

export interface BetweenBloxProps {
  copiedBlox: AllBloxes | null,
  handleInsertBlox?: (item: BloxInsertItem) => void,
  handleInsertModule?: (item: ModuleInsertItem) => void,
  dropOnly?: boolean,
  showDropTarget?: boolean,
  handleSectionClick?: () => void,
}

type InsertItem = BloxInsertItem | ModuleInsertItem;

export const BetweenBlox: React.FC<BetweenBloxProps> = (props) => {
  const [processIsReadOnly] = useFabuState('processIsReadOnly');
  const [isModuleSelectOpen, setIsModuleSelectOpen] = useState(false);
  const { handleInsertBlox, handleInsertModule, handleSectionClick, dropOnly, showDropTarget, copiedBlox } = props;
  const { isStackHidden } = useContext(ProcessSettingsContext);
  const dropCallback = useCallback((item: InsertItem, monitor: DropTargetMonitor) => {
    if (handleInsertModule && monitor.getItemType() === Draggables.Module) {
      handleInsertModule(item as ModuleInsertItem);
    } else if (handleInsertBlox && monitor.getItemType() === Draggables.Blox) {
      handleInsertBlox(item as BloxInsertItem);
    }
  }, [handleInsertBlox, handleInsertModule]);

  const [{ isOver, canDrop }, dropBlox] = useDrop<BloxInsertItem, unknown, { isOver: boolean; canDrop: boolean }>(
    () => ({
      accept: [Draggables.Blox, Draggables.Module],
      drop: dropCallback,
      canDrop: () => true,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [handleInsertBlox]
  );

  const arrowStyle = {
    width: '60px',
    margin: `${isStackHidden ? '10px' : '40px'} auto`
  }

  // depending on the presence of a module, the arrow should be centered differently
  // centering also depends on the presence of 'add' and 'section split' button above and below the arrow
  const arrowCenteringStyle = `${handleInsertModule ? '60px' : '30px'}`;

  const getDropTarget = () => {
    const rectEl = getRectangleElement(isOver, isStackHidden, dropOnly);
    if (handleInsertBlox && !processIsReadOnly) {
      return (
        <SelectBlox copiedBlox={copiedBlox} handleSelectBlox={handleInsertBlox}>
          <div style={{ cursor: 'pointer' }}>
            {rectEl}
          </div>
        </SelectBlox>
      );
    } else {
      return rectEl;
    }
  }

  return (
    <div className="betweenBloxesContainer" ref={dropBlox}>
      {
        ((canDrop && !showDropTarget) || dropOnly) ?
          getDropTarget() :
          <Row style={{ alignItems: 'center' }}>
            <Column>
              <Column className={'betweenAddContainer'} style={{ minHeight: arrowCenteringStyle }}>
                {(handleInsertModule && !processIsReadOnly) &&
                  <div className={isModuleSelectOpen ? '' : 'hidden show-on-hover'}>
                    <SelectModule setIsOpen={setIsModuleSelectOpen} handleSelectModule={(item) => {
                      if (!item?.data) return;

                      handleInsertModule(item);
                    }}>
                      <Button icon={IconNames.MultiSelect} minimal={true} />
                    </SelectModule>
                  </div>
                }
                {(handleInsertBlox && !processIsReadOnly) &&
                  <div style={{ marginTop: 'auto' }}>
                    <SelectBlox copiedBlox={copiedBlox} handleSelectBlox={handleInsertBlox}>
                      <Button icon={IconNames.ADD} minimal={true} />
                    </SelectBlox>
                  </div>
                }
              </Column>
              <svg style={{ ...arrowStyle }} viewBox="0 0 100 100">
                <polygon fill={Colors.GRAY3} points="0,25 60,25 60,0 100,50 60,100 60,75 0,75" />
              </svg>
              {(handleSectionClick && !processIsReadOnly) ? <Tooltip position={Position.TOP} content={'Split Section'}>
                <div style={{ minHeight: arrowCenteringStyle }}>
                  <Button onClick={() => handleSectionClick()} minimal={true} style={{ marginBottom: 'auto' }} icon={IconNames.Nest} color={Colors.GRAY3} />
                </div>
              </Tooltip> : <div style={{ minHeight: arrowCenteringStyle }}></div>}
            </Column>
            {showDropTarget && getDropTarget()}
          </Row>
      }
    </div>
  )
}

function getRectangleElement(isOver: boolean, isShort?: boolean, isBold?: boolean) {
  const isOverOrBold = isOver || !!isBold;
  const color = isBold ? Colors.GRAY2 : Colors.GRAY3;
  // Define the dimensions based on conditions
  const width = isOverOrBold ? 100 : 60;
  const height = isShort ? 140 : 200;

  // Define the rectangle dimensions
  const rectWidth = isOverOrBold ? 90 : isShort ? 40 : 50;
  const rectHeight = isShort ? 140 : 190;

  // Center the rectangle within the SVG
  const rectX = (width - rectWidth) / 2;
  const rectY = isShort ? (height - rectHeight) / 2 : 5;

  // Position the cross symbol, centered in the rectangle
  const crossX = width / 2;
  const crossY = height / 2;

  // Adjust opacity based on isOver
  const opacity = isOver ? 0.5 : 1;

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} style={{ opacity: opacity }} xmlns="http://www.w3.org/2000/svg">
      <rect x={rectX} y={rectY} width={rectWidth} height={rectHeight} rx={10} ry={10}
        fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeDasharray="4" />
      <g transform={`translate(${crossX},${crossY})`}>
        <path d="M -10 0 h 20 M 0 -10 v 20"
          stroke={color} strokeWidth="2" strokeLinecap="round" fill="none" />
      </g>
    </svg>
  );
}
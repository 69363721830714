import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";

export interface ThermalOxide extends BaseBlox {
    layerLabelsToOxidize: string[] | null;

    oxide: string | null;
    wetOxidation: boolean | null;
    thermalOxTemp: number | null;
    thermalOxTempUnit: Units | null;
    thermalOxTime: number | null;
    thermalOxTimeUnit: Units | null;
    gasFlows: string | null;

    // DISPLAY
    doubleSided: boolean | null;
    layerColor: IColor | null;
    oxidationDepth: number | null;
    layerLabel: string | null;
    // SEMIFAB
    oxideThickness: number | null;
    oxideThicknessUnit: Units | null;
    oxideGrowthRatio: number | null;
}

export const thermalOxideDisplay: DisplayMap = {
    
    layerLabelsToOxidize: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Oxidize",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    oxide: {
        fieldType: DisplayFieldTypes.Input,
        label: "Oxide Composition",
        placeholder: "Enter oxide composition",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    thermalOxTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Oxidation Temperature",
        placeholder: "Enter temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    thermalOxTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Oxidation Time",
        placeholder: "Enter time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    gasFlows: {
        fieldType: DisplayFieldTypes.Input,
        label: "Gas Flows",
        placeholder: "Enter gas composition and flow rates",
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    wetOxidation: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Wet Oxidation",
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
        infoContent: "Toggled off by default - indicates oxidation under dry oxygen. Toggle on to indicate oxidation reaction carried out under water vapor."
    },

    // DISPLAY
    doubleSided: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Two-Sided Oxidation",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 4,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    oxidationDepth: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Oxidation Depth",
        order: 2,
        tabs: [Tab.DISPLAY],
        defaultValue: 10,
        stepSize: 1,
        maxSliderValue: 50
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 1,
        tabs: [Tab.DISPLAY],
    },
    // SEMIFAB
    oxideThickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Oxide Thickness",
        placeholder: "Enter oxide thickness",
        units: [Units.ANG, Units.NM, Units.MICRON],
        order: 4,
        tabs: [Tab.SEMIFAB],
    },
    oxideGrowthRatio: {
        fieldType: DisplayFieldTypes.Input,
        label: "Oxide Thickness / Consumption",
        placeholder: "Enter oxide consumption ratio",
        units: [Units.PERCENT],
        order: 4,
        tabs: [Tab.SEMIFAB],
    },
}

export const getThermalOxide = ({stepNumber}: BloxArgs): ThermalOxide => ({
        name: `Thermal Oxide Growth ${stepNumber}`,
        id: uuidv4(),
        layerLabelsToOxidize: [],
        bloxType: BloxTypes.ThermalOxide,
        wetOxidation: false,
        oxide: "Thermal Oxide",
        thermalOxTemp: null,
        thermalOxTempUnit: Units.CELSIUS,
        thermalOxTime: null,
        thermalOxTimeUnit: Units.MIN,
        gasFlows: null,
        commentField: null,
        layerColor: {R: 211, G: 211, B: 211, A: 1},
        oxidationDepth: 4,
        layerLabel: null,
        doubleSided: false,
        customFields: {},
        // SEMIFAB
        oxideThickness: null,
        oxideThicknessUnit: Units.NM,
        oxideGrowthRatio: 1,
});

import { BaseBlox, BloxTypes, LayerProperties } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { DisplayMap } from "../display-mappings";

export interface StartBlox extends BaseBlox {
    layers : LayerProperties[] | null;
    // SEMIFAB
    substrateWidth: number | null;
    substrateWidthUnit: Units | null;
    substrateHeight: number | null;
    substrateHeightUnit: Units | null;
    simulate3D: boolean | null;
}

export const startBloxDisplay: DisplayMap = {
    substrateWidth: {
        fieldType: DisplayFieldTypes.Input,
        label: "Cross Section Width",
        placeholder: "Enter cross section width",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 1,
        tabs: [Tab.SEMIFAB],
        infoContent: "This is the width of the simulated cut line. Try to choose values that result in viewable aspect ratios."
    },
    substrateHeight: {
        fieldType: DisplayFieldTypes.Input,
        label: "Cross Section Height",
        placeholder: "Enter cross section height",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 2,
        tabs: [Tab.SEMIFAB],
        infoContent: "This is the maximum height of the substrate that will be simulated. Regions lower than this distance from the top of the wafer will be ignored."
    }
}

// TODO: Add view width with units for scaling 
// TODO: Add vertical stretch ratio?
export const getStartBlox = (): StartBlox => ({
        name: "Substrate Stack",
        id: uuidv4(),
        bloxType: BloxTypes.StartBlox,
        layers: [{
            layerColor: {R: 255, G: 255, B: 255, A: 1},
            layerThickness: 12,
            layerSimulationThickness: 1000,
            layerSimulationThicknessUnit: Units.NM,
            layerLabel: "Substrate",
            showLabel: true,
            materialId: uuidv4()
        }],
        commentField: null,
        customFields: {},
        // SEMIFAB
        substrateWidth: 5000,
        substrateWidthUnit: Units.NM,
        substrateHeight: 5000,
        substrateHeightUnit: Units.NM,
        simulate3D: false,
    }
);

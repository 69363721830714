import { Spinner } from "@blueprintjs/core"
import { Column } from "../Layout/layouts"

export const Loading: React.FC<{size?: number}> = ({size}) => {
  
    return (
        <Column style={{height: '100%', width: '100%'}}>
          <div style={{margin: 'auto'}}>
            <Spinner size={size ?? 60} />
          </div>
        </Column>
    )
  }
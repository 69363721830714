import { useAuth0 } from '@auth0/auth0-react';
import {Dialog, Button, Intent, Classes, FormGroup, InputGroup, Label, TextArea} from '@blueprintjs/core';
import React, { useCallback, useState } from 'react';
import { AllBloxes } from '../Data/BloxSchema/base-blox';
import { useBloxHandlers } from '../components/hooks/use-blox-handlers';
import { Blox } from '../__generated__/Blox';

export const BloxDialog: React.FC<{isOpen: boolean, setIsOpen: (isOpen: boolean) => void, bloxData: AllBloxes, blox?: Blox}> = ({isOpen, setIsOpen, bloxData, blox}) => {
    const isEditMode = !!blox
    const title = isEditMode ? 'Edit Blox' : 'Save to Blox Library';
    const btnText = isEditMode ? 'Update Blox' : 'Save to Blox Library';

    const savedName = isEditMode ? blox.bloxName : bloxData.name;
    const savedDesc = isEditMode ? blox.bloxDescription : bloxData.commentField ?? '';
    
    const [name, setName] = useState<string | null>(null);
    const [desc, setDesc] = useState<string | null>(null);

    const handleClose = useCallback(() => { 
        setName(null);
        setDesc(null);
        setIsOpen(false); 
    }, [setIsOpen, setName, setDesc]);

    const { bloxHandleSave, bloxHandleUpdate } = useBloxHandlers();

    const {getAccessTokenSilently} = useAuth0();

    const handleSaveBloxClick = useCallback(async () => {
        const token = await getAccessTokenSilently();
        bloxHandleSave(bloxData, name ?? savedName, desc ?? savedDesc, token);

    }, [getAccessTokenSilently, bloxHandleSave, desc, savedDesc, name, savedName, bloxData]);

    const handleUpdateBloxClick = useCallback(async () => {
        const token = await getAccessTokenSilently();
        if (isEditMode)
            bloxHandleUpdate(blox.bloxId, name ?? savedName, desc ?? savedDesc, token);

    }, [getAccessTokenSilently, bloxHandleUpdate, isEditMode, desc, savedDesc, name, savedName, blox]);

    const handlePersistAndClose = useCallback(() => {
        if (isEditMode)
            handleUpdateBloxClick();
        else
            handleSaveBloxClick();

        handleClose();
    }, [handleClose, handleSaveBloxClick, handleUpdateBloxClick, isEditMode]);

    const handleNameChange = useCallback((event: any) => {
        setName(event.target.value)
    }, [setName]);

    const handleDescChange = useCallback((event: any) => {
        setDesc(event.target.value)
    }, [setDesc]);

    return <Dialog isOpen={isOpen} title={title} canOutsideClickClose={false} onClose={handleClose}>
        <div className={Classes.DIALOG_BODY}>
            <FormGroup label='Blox name' labelInfo='(required)'>
            <InputGroup onChange={handleNameChange} value={name ?? savedName}/>
            </FormGroup>
            <Label>
                Description
                <TextArea className={"rightPanelComments"} fill={true} value={desc ?? savedDesc} onChange={handleDescChange} title={desc as string} />

            </Label>

            <Button disabled={!name && !savedName} style={{float: 'right'}} intent={Intent.SUCCESS} onClick={handlePersistAndClose}>{btnText}</Button>
        </div>
        
    </Dialog>;
}
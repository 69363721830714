import { Stack } from "../components/Stack";
import { BloxTypes } from "./BloxSchema/base-blox";
import { StackChange } from "./enums";

export const stackChangeMap: {[key in BloxTypes]: StackChange[]} = {
    "CUSTOMBLOX": [],
    "BAKE": [],
    "RTA":[],

    "PLASMA-DESCUM": [],
    
    "CUSTOMCLEAN": [StackChange.Clean],
    "RCACLEAN": [StackChange.Clean],

    "SUBSTACK": [StackChange.ThinDeposit],
    "MOCVD": [StackChange.ThinDeposit],
    "MBE": [StackChange.ThinDeposit],
    "ALD": [StackChange.ThinDeposit],
    "EBEAMEVAP": [StackChange.ThinDeposit],
    "HMDSVAPOR": [StackChange.ThinDeposit],
    "PECVD": [StackChange.ThinDeposit],
    "LPCVD": [StackChange.ThinDeposit],
    "APCVD": [StackChange.ThinDeposit],
    "SPUTTER": [StackChange.ThinDeposit],
    "THERMALEVAP": [StackChange.ThinDeposit],
    "WETGROWTH": [StackChange.ThinDeposit],
    "ELECTROPLATE": [StackChange.ThinDeposit],
    "PARYLENEDEP": [StackChange.ThinDeposit],
    
    "THERMALOXIDATION": [StackChange.ThermalOxidation],

    "SPINCOATRESIST": [StackChange.FillDeposit],
    "SPINCOAT": [StackChange.FillDeposit],
    "SPINDOPANT": [StackChange.FillDeposit],
    "DROPCAST": [StackChange.FillDeposit],

    "WAFERBONDING": [StackChange.WaferBonding],

    "IONIMPLANT": [StackChange.IonImplantation],
    "DOPEDIFFUSION": [StackChange.DopingDiffusion],

    "DEVELOP": [StackChange.Develop],

    "DIRECTWRITELITHO": [StackChange.Pattern],
    "EBL": [StackChange.Pattern],
    "MASKALIGN": [StackChange.Pattern],
    "STEPPERLITHO": [StackChange.Pattern],
    "INTERFERENCE-LITHO": [StackChange.Pattern],
    "FLOODEXPOSE": [StackChange.Pattern],
    
    "IMPRINTLITHO": [StackChange.Imprint],
    "SCANNINGPROBELITHO": [StackChange.Imprint],

    "ICPRIE": [StackChange.Etch],
    "RIE": [StackChange.Etch],
    "PLASMA-ASH": [StackChange.Etch],
    "IONMILL": [StackChange.Etch],
    "WETETCH": [StackChange.Etch],

    "FLIPSTACK": [StackChange.Flip],

    "CMP": [StackChange.Polish],
    
    "LIFTOFF": [StackChange.LiftOff],

    "STRIPRESIST": [StackChange.StripResist],

    "STARTBLOX": [StackChange.MultiDeposit],

}
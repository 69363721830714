import { escapeRegExp } from "lodash";
import { AllBloxes } from "../Data/BloxSchema/base-blox";
import { displayMap } from "../Data/display-mappings";
import { DisplayFieldTypes } from "../Data/enums";
import { getValue } from "./ts-helpers";
import { ProcessListResult } from "../pages/SearchPage";


// Function to create a case-insensitive RegExp from a given string
export const createCaseInsensitiveRegex = (searchKeyword: string) => {
    const escapedSearchKeyword = escapeRegExp(searchKeyword);
    return new RegExp(escapedSearchKeyword, 'i');
};

// Function to search within a blox object based on a search keyword
export const searchInBlox = (blox: AllBloxes, searchKeyword: string) => {
    const regex = createCaseInsensitiveRegex(searchKeyword);

    if (regex.test(blox.name.toLowerCase())) {
        return true;
    }

    if (blox.toolName && regex.test(blox.toolName.toLowerCase())) {
        return true;
    }

    let foundProperty = false;
    Object.keys(blox)
        .filter((property) => displayMap[blox.bloxType] && displayMap[blox.bloxType][property])
        .filter((property) =>
            [DisplayFieldTypes.Input, DisplayFieldTypes.Dropdown, DisplayFieldTypes.Multiline, DisplayFieldTypes.EditableText].includes(
                displayMap[blox.bloxType][property].fieldType
            )
        )
        .forEach((property) => {
            const value = getValue(property, blox);
            if (value && regex.test(value.toString())) {
                foundProperty = true;
            }
        });

    if (foundProperty) {
        return true;
    }

    if (blox.commentField && regex.test(blox.commentField.toLowerCase())) {
        return true;
    }

    return false;
};

export const searchProcessAndBlox = (row: ProcessListResult, filterBy?: string) => {
    if (!filterBy) return true;

    if (!row.processName) return false;

    const allKeywordsExist = filterBy.trim().toLowerCase().split(' ').every((filterVal) => {
        const keywordRegex = createCaseInsensitiveRegex(filterVal);
        let keywordExists = keywordRegex.test(row.processName.toLowerCase());

        // check all bloxes and their properties
        if (!row.bloxesInfo.bloxes.length) return keywordExists;

        row.bloxesInfo.bloxes.forEach((blox) => {
            keywordExists = keywordExists || searchInBlox(blox, filterVal);
        });

        return keywordExists;
    });

    return allKeywordsExist;
};
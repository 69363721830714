import React, { useCallback, useState } from 'react';
import { Button, Classes, Dialog, FormGroup, Intent, Switch } from '@blueprintjs/core';
import { Row } from '../Layout/layouts';
import { Blox } from '../__generated__/Blox';
import { useUpdateBlox } from '../hooks/DataFetching/use-fetch-blox';

export interface GroupSharingInfo {
    id: string;
    groupName: string;
    sharedWith: boolean;
}

interface SharingBloxProps {
    blox: Blox;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    groupSharingInfo: GroupSharingInfo[];
}

export const SharingBloxDialog: React.FC<SharingBloxProps> = (props) => {
    const {blox, isOpen, setIsOpen, groupSharingInfo} = props;
    const [hasChanges, setHasChanges] = useState(false);
    const {bloxId, bloxName} = blox;
    const [myGroups, setMyGroups] = useState<GroupSharingInfo[]>(groupSharingInfo);

    const handleGroupToggle = useCallback((groupId: string) => {
        const groupToToggle = myGroups.find(group => group.id === groupId);
        if (groupToToggle) {
            groupToToggle.sharedWith = !groupToToggle.sharedWith
        }
        setMyGroups([...myGroups]);
        setHasChanges(true);
    }, [myGroups]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const updateBlox = useUpdateBlox();

    const handleSave = useCallback(async () => {
        if (!hasChanges) {
            setIsOpen(false);
            return;
        }
        
        const updatedBloxGroups = myGroups.filter(group => group.sharedWith).map(group => ({id: group.id, groupName: group.groupName}));

        updateBlox.mutate({
            bloxId,
            data: {
                groups: updatedBloxGroups
            }
        });
        setHasChanges(false);
        setIsOpen(false);

    }, [updateBlox, myGroups, bloxId, setIsOpen, hasChanges]);

    return (
        <>
            <Dialog isOpen={isOpen} onClose={handleClose} canEscapeKeyClose={false} canOutsideClickClose={false}>
                <div className={Classes.DIALOG_HEADER}>
                    <h4 className={Classes.HEADING}>{`Share "${bloxName}"`}</h4>
                </div>
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup>
                        {myGroups.map(group => (
                            <div key={group.id}>
                                <Switch onChange={handleGroupToggle.bind(this, group.id)} checked={group.sharedWith} label={group.groupName} />
                            </div>
                        ))}
                    </FormGroup>
                    <Row style={{ margin: "0 20px" }}>
                        <Button onClick={handleSave} style={{ margin: 'auto 0px auto 0px' }} intent={Intent.SUCCESS}>Done</Button>
                    </Row>
                </div>
            </Dialog>
        </>
    );
};








import { useContext, useRef } from 'react';
import { ParameterContext, StateAndSetter } from "./parameter-context";
import { isEqual } from 'lodash';
import { noOp } from '../../utils/empty-arrow-function-helper';

/**
 * Memoize a result using deep equality. This hook has two advantages over
 * React.useMemo: it uses deep equality to compare memo keys, and it guarantees
 * that the memo function will only be called if the keys are unequal.
 * React.useMemo cannot be relied on to do this, since it is only a performance
 * optimization (see https://reactjs.org/docs/hooks-reference.html#usememo).
 */
export function useDeepMemo<TKey, TValue>(
    memoFn: () => TValue,
    key: TKey
): TValue {
    const ref = useRef<{ key: TKey; value: TValue }>();

    if (!ref.current || !isEqual(key, ref.current.key)) {
        ref.current = { key, value: memoFn() };
    }

    return ref.current.value;
}

export function useParameter<T, K extends keyof T>(name: K) {
    const { providers } = useContext(ParameterContext);
    if (!(name in providers)) {
        console.warn(`Parameter ${String(name)} was not registered! Add it to the ParameterContext`);
    }
    const [value, setValue] = providers[name] ?? [undefined, noOp];
    const memoValue = useDeepMemo(() => value, [value]);
    return [memoValue, setValue] as StateAndSetter<T[K]>;
}

import { Classes, Tag, Text } from "@blueprintjs/core";
import { ReactComponent as PatternExampleOne } from '../components/svg/pattern-example-one-min.svg';
import { ReactComponent as PatternExampleTwo } from '../components/svg/pattern-example-two-min.svg';
import { Row } from "../Layout/layouts";


export const PatternDialogContent: JSX.Element = (
    <div className={Classes.DIALOG_BODY}>
        <><strong>Example input:</strong> <Tag>1, 2, 4, 2, 1</Tag></>
        <br/><br/>
        <Text>
            In Fabublox, the exposure pattern is generated by dividing the full width of the substrate into a grid equaling the sum of the numbers used in the <strong>"Define Pattern" </strong>
            text box. Each number represents the width of a section. Our StackGenerator algorithm then starts with an exposed section and alternates between exposed and unexposed sections until the end of the substrate is reached.
        </Text>

        <Row>
            <PatternExampleOne style={{width: '350px', margin: 'auto'}} />
        </Row>

        <Text>
            Toggling on <strong>"Invert"</strong>, switches the pattern to begin with an unexposed section.
        </Text>

        <Row>
            <PatternExampleTwo style={{width: '350px', margin: 'auto'}} />
        </Row>

        <Text>
            The pattern will develop exposed/unexposed sections according to your choice of positive/negative resist spin-coated in a previous step.
        </Text>
    </div>
);
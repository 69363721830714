import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";

export interface HMDSVapor extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    doubleSided: boolean | null;

    // DISPLAY
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;

    // SEMIFAB
    thickness: number | null;
    thicknessUnit: Units | null;
}

export const HMDSVaporDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposited Monolayer",
        placeholder: "Enter deposited material",
        order: 1,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    
    // DISPLAY
    doubleSided: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Two-Sided Deposition",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 4,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    sidewallThickness: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Side Wall Thickness",
        order: 3,
        tabs: [Tab.DISPLAY],
        defaultValue: 0
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 1,
        tabs: [Tab.DISPLAY],
    },

    // SEMIFAB
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Display Thickness",
        units: [Units.ANG,Units.NM,Units.MICRON,Units.MM],
        isNumber: true,
        order: 2,
        tabs: [Tab.SEMIFAB],
    },
}

export const getHMDSVapor = ({stepNumber}: BloxArgs): HMDSVapor => ({
        name: `HMDS Vapor Prime ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.HMDSVapor,
        doubleSided: false,
        material: "HMDS",
        commentField: null,
        layerColor: {R: 50, G: 50, B: 100, A: 1},
        layerThickness: 2,
        sidewallThickness: 100,
        layerLabel: null,
        customFields: {},
        thickness: null,
        thicknessUnit: Units.ANG
});

import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";

export interface RTA extends BaseBlox {
    annealTemp1: number | null;
    annealTemp1Unit: Units | null;
    annealTime1: number | null;
    annealTime1Unit: Units | null;
    annealTemp2: number | null;
    annealTemp2Unit: Units | null;
    annealTime2: number | null;
    annealTime2Unit: Units | null;
    annealTemp3: number | null;
    annealTemp3Unit: Units | null;
    annealTime3: number | null;
    annealTime3Unit: Units | null;
}

export const RTADisplay: DisplayMap = {
    
    annealTemp1: {
        fieldType: DisplayFieldTypes.Input,
        label: "Temperature 1",
        placeholder: "Enter target temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    annealTime1: {
        fieldType: DisplayFieldTypes.Input,
        label: "Time 1",
        placeholder: "Enter step time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    annealTemp2: {
        fieldType: DisplayFieldTypes.Input,
        label: "Temperature 2",
        placeholder: "Enter target temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    annealTime2: {
        fieldType: DisplayFieldTypes.Input,
        label: "Time 2",
        placeholder: "Enter step time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    annealTemp3: {
        fieldType: DisplayFieldTypes.Input,
        label: "Temperature 3",
        placeholder: "Enter target temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 5,
        tabs: [Tab.EXPERIMENTAL],
    },
    annealTime3: {
        fieldType: DisplayFieldTypes.Input,
        label: "Time 3",
        placeholder: "Enter step time",
        isNumber: true,
        units: [Units.SEC,Units.MIN,Units.HOUR],
        order: 6,
        tabs: [Tab.EXPERIMENTAL],
    },
}

export const getRTA = ({stepNumber}: BloxArgs): RTA => ({
        name: `Rapid Thermal Annealing ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.RTA,
        annealTemp1: null,
        annealTemp1Unit: Units.CELSIUS,
        annealTime1: null,
        annealTime1Unit: Units.SEC,
        annealTemp2: null,
        annealTemp2Unit: Units.CELSIUS,
        annealTime2: null,
        annealTime2Unit: Units.SEC,
        annealTemp3: null,
        annealTemp3Unit: Units.CELSIUS,
        annealTime3: null,
        annealTime3Unit: Units.SEC,
        commentField: null,
        customFields: {}
    }
);

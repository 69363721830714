import {
  ButtonGroup,
  Button,
  Divider,
  Menu,
  MenuItem,
  Tag,
  Icon,
  Colors,
  Popover,
  Tooltip,
  H5,
} from '@blueprintjs/core'
import { useHistory, useLocation } from 'react-router-dom'
import { Row } from '../Layout/layouts'
import { IconNames } from '@blueprintjs/icons'
import { ContactDialog } from '../dialogs/ContactDialog'
import { ReactComponent as LogoIcon } from '../components/svg/STARTSTACK.svg';
import { useClearProcess } from './hooks/use-clear-process'

export const PageHeader: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;
  let pageHeader = undefined;
  if (pathname.includes('process-editor')) {
    pageHeader = 'Process Editor'
  } else if (pathname.includes('module-editor')) {
    pageHeader = 'Module Editor'
  } else if (pathname.includes('fabubase')) {
    pageHeader = 'Fabubase'
  } else if (pathname.includes('my-processes')) {
    pageHeader = 'My Processes'
  } else if (pathname.includes('my-group-processes')) {
    pageHeader = 'Group Processes'
  } else if (pathname.includes('my-modules')) {
    pageHeader = 'My Modules'
  } else if (pathname.includes('my-group-modules')) {
    pageHeader = 'Group Modules'
  }

  const clearProcess = useClearProcess();

  const history = useHistory()
  const mainMenu = (
    <Menu>
      <MenuItem
        onClick={() => history.push('/')}
        icon={IconNames.HOME}
        text={`Home`}
      />
      {/* TODO removing till we have a blox viewer or additional functionality */}
      {/* <Divider />
      <MenuItem
        text={'My Blox'}
        onClick={() => history.push(`/my-blox/`)}
      /> */}
      <Divider />
      <MenuItem
        text={'Process Editor'}
        onClick={() => { 
          clearProcess();
          history.push(`/process-editor/new`)
        }}
      />
      <MenuItem
        text={'My Processes'}
        onClick={() => history.push(`/my-processes/`)}
      />
      <MenuItem
        text={'Group Processes'}
        onClick={() => history.push(`/my-group-processes/`)}
      />
      <Divider />
      <MenuItem
        text={'Module Editor'}
        onClick={() => { 
          clearProcess();
          history.push(`/module-editor/new`)
        }}
      />
      <MenuItem
        text={'My Modules'}
        onClick={() => history.push(`/my-modules/`)}
      />
      <MenuItem
        text={'Group Modules'}
        onClick={() => history.push(`/my-group-modules/`)}
      />
      <Divider />
      <MenuItem
        text={'Fabubase'}
        onClick={() => history.push(`/fabubase/`)}
      />
    </Menu>
  )
  
  const pixNo = 30;
  const iconStyle = {
    width: `${pixNo}px`,
    height: `${pixNo}px`,
  };

  const betaStyle = {
    backgroundColor: Colors.LIGHT_GRAY2,
    color: 'black', 
    fontSize: "16px", 
    height: '40px',
    marginLeft: '5px',
  };

  return (
    <Row style={{margin: 'auto'}}>
      <ButtonGroup>
        <Popover content={mainMenu} placement={'bottom'}>
          <Button icon={IconNames.MENU} minimal={true} large={true}/>
        </Popover>
        {pageHeader && <H5 style={{margin: 'auto 10px'}}>{pageHeader}</H5>}
        <Tooltip content={<>
    <p>Fabublox is in beta testing. We appreciate your feedback as we add more features.<br/>Please use the mail button to share your thoughts with us.</p>
    <p>Thank you!</p>
</>
        }>
          <Tag icon={<LogoIcon style={iconStyle} />} style={betaStyle} large={true}>Beta</Tag>
        </Tooltip>
      </ButtonGroup>
     
      <ContactDialog>
                {(handleOpen: () => void) => (
                  <Button large={true} icon={envelopeIcon} onClick={handleOpen} style={{ margin: 'auto 5px' }}/>
                )}
      </ContactDialog>
    </Row>
  )
}

export const envelopeIcon = <Icon size={25} color={Colors.BLUE3} icon={IconNames.ENVELOPE} />;

import { Intent } from "@blueprintjs/core";
import { renderToStaticMarkup } from "react-dom/server";
import { useCallback, useContext } from "react";
import { AllBloxes, BloxTypes } from "../../Data/BloxSchema/base-blox";
import { useFabuState } from "../../hooks/state/use-fabu-state";
import { useGetProcess } from "./use-get-process";
import { HandlersArgs, useHandlers } from "./use-handlers";
import { UnsavedChangesContext } from "../../hooks/state/unsaved-changes-provider";
import { useSectionManager } from "./use-section-manager";
import { showToast } from "../..";
import { v4 as uuidv4 } from 'uuid';
import { CreateProcessRequest, Section } from "../../__generated__/Process";
import { useLocation } from "react-router-dom";
import { SvgServiceContext } from "../../hooks/state/svg-service-provider";
import { SVGDisplayMode } from "../../Services/SVGEngine";
import { useUpdateProcess } from "../../hooks/DataFetching/use-fetch-process";

export function useProcessHandlers() {
  const location = useLocation();
  const {pathname} = location;
  const isModule = pathname.includes('module-editor');
  const resourceName = isModule ? 'module' : 'process';
  const upperResourceName = resourceName.charAt(0).toUpperCase() + resourceName.slice(1);
  const [bloxes, setBloxes] = useFabuState('processBloxes');
  const [selectedBloxId, setSelectedBloxId] = useFabuState('selectedBloxIdState');
  const handlerProps: HandlersArgs = {
    selectedBloxIdState: [selectedBloxId, setSelectedBloxId],
    bloxesState: [bloxes, setBloxes],
  };

  const { setEditorUnsavedChanges } = useContext(UnsavedChangesContext);

  const [processIsReadOnly] = useFabuState('processIsReadOnly');
  const { addBloxToSection, deleteBloxFromSection, insertBloxIdsToSection, deleteSection} = useSectionManager();

  const {
    handleClickBlox,
    handleDelete,
    handleDrop,
    handleSave,
    handleCreate,
  } = useHandlers(handlerProps);

  const processHandleDrop = useCallback((item: {bloxType: BloxTypes, id?: string, data?: AllBloxes}, stepNumber: number, emptySectionId?: string) => {
    if (processIsReadOnly) {
      showToast({
        message: `Duplicate this ${resourceName}, or create your own to make changes.`,
        intent: Intent.WARNING,
        timeout: 3000
      });
      return;
    }

    setEditorUnsavedChanges(true);
    const bloxId = handleDrop(item, stepNumber);
    addBloxToSection(stepNumber, bloxId, emptySectionId);

  }, [handleDrop, processIsReadOnly, setEditorUnsavedChanges, addBloxToSection, resourceName]); 

  const processHandleDelete = useCallback((id: string) => {
    if (processIsReadOnly) {
      showToast({
        message: `Duplicate this ${resourceName}, or create your own to make changes.`,
        intent: Intent.WARNING,
        timeout: 3000
      });
      return;
    }
    
    setEditorUnsavedChanges(true);
    deleteBloxFromSection(id);
    handleDelete(id);
  }, [handleDelete, processIsReadOnly, setEditorUnsavedChanges, deleteBloxFromSection, resourceName]);

  const processHandleInsertSection = useCallback((insertBloxes: AllBloxes[], insertIdx: number, sectionId: string, moduleId?: string, moduleName?: string) => {
    const updateBloxes = [...bloxes];
    const newBloxes: AllBloxes[] = [];
    for (const blox of insertBloxes) {
      if (blox.bloxType === BloxTypes.StartBlox) continue;
      const newBlox = {...blox};
      newBlox.id = uuidv4();
      if (moduleId && moduleName) {
        newBlox.moduleId = moduleId;
        newBlox.moduleBloxId = blox.id;
        newBlox.moduleName = moduleName;
      }
      newBloxes.push(newBlox);
    }

    updateBloxes.splice(insertIdx + 1, 0, ...newBloxes);
    insertBloxIdsToSection(sectionId, insertIdx, newBloxes.map(blox => blox.id));

    setEditorUnsavedChanges(true);
    setBloxes([...updateBloxes]);


    if (newBloxes.length > 0) {
        setSelectedBloxId(newBloxes[0].id);
    }
}, [bloxes, insertBloxIdsToSection, setBloxes, setSelectedBloxId]);

const processHandleDeleteSection = useCallback((section: Section) => {
  const bloxIds = section.bloxIds;
  const updateBloxes = [...bloxes];
  for (const bloxId of bloxIds) {
    const bloxIdx = updateBloxes.findIndex(blox => blox.id === bloxId);
    if (bloxIdx > -1) {
      updateBloxes.splice(bloxIdx, 1);
    }
  }
  deleteSection(section.sectionId);
  setBloxes([...updateBloxes]);
}, [deleteSection, bloxes, setBloxes]);

 
  const processHandleClickBlox = useCallback((bloxId: string) => {
    handleClickBlox(bloxId);
  }, [handleClickBlox]);

  const process = useGetProcess();
  const { generateSvgs } = useContext(SvgServiceContext);
  const updateProcess = useUpdateProcess(false, {quietly: true});
  
  const processHandleSave = useCallback(async (id: string, token?: string) => {
    const saveToast = {
      message: `${upperResourceName} Saved`,
      intent: Intent.SUCCESS,
      timeout: 3000
    };

    
    if (process.processId !== id)
      throw new Error('IDs during save do not match');

    const body = {
      bloxes: process.processBloxes,
      processName: process.processName,
      isPrivate: process.processIsPrivate,
      additionalOwners: process.processAdditionalOwners,
      desc: process.processDesc,
      reference: process.processReference,
      sections: process.processSections,
    };
    
    setEditorUnsavedChanges(false);

    if (!id || id === 'new'){
      
      if (!process.processName) {
        showToast({
          message: `Save Failed: ${upperResourceName} name is required`,
          intent: Intent.DANGER,
          timeout: 3000
        });
        return;
      }

      handleCreate(`/api/${resourceName}/create/`, `/${resourceName}-editor`, saveToast, body, token);
      return;
    }

    handleSave(id, `/api/${resourceName}/update/`, saveToast, body, token, isModule);

    const generateSVGs = async () => {
      const bloxSVGs = generateSvgs(process.processBloxes, false, SVGDisplayMode.Thumbnail);
      const svgString = renderToStaticMarkup(bloxSVGs[bloxSVGs.length - 1]);
      if (svgString)
        updateProcess.mutate({processId: id, data: {thumbnail: svgString}});
    };

    generateSVGs();

  }, [generateSvgs, updateProcess, handleSave, process, setEditorUnsavedChanges, handleCreate, resourceName, upperResourceName, isModule]);

  const processHandleDuplicate = useCallback(async (newProcessName: string, token?: string) => {
    const saveToast = {
      message: `${upperResourceName} Saved`,
      intent: Intent.SUCCESS,
      timeout: 3000
    };

    const body: CreateProcessRequest = {
      bloxes: process.processBloxes,
      sections: process.processSections,
      reference: process.processReference,
      desc: process.processDesc,
      processName: newProcessName,
      isPrivate: true,
    };

    handleCreate(`/api/${resourceName}/create/`, `/${resourceName}-editor`, saveToast, body, token);
  }, [process, handleCreate, resourceName, upperResourceName]);

  return {
    processHandleClickBlox,
    processHandleDelete,
    processHandleDrop,
    processHandleSave,
    processHandleDuplicate,
    processHandleInsertSection,
    processHandleDeleteSection
  };
}
export function polyfillArrayAt() {
    // Polyfill for Array.prototype.at, prevent crashes in safari
    if (!Array.prototype.at) {
        Array.prototype.at = function (position) {
            // Normalize position
            if (position < 0) position = this.length + position;
            // Return undefined if index is out of bounds
            if (position < 0 || position >= this.length) return undefined;
            // Return the element at the given position
            return this[position];
        };
    }
}
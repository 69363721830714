import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";


export interface FloodExpose extends BaseBlox {
    wavelength: number | null;
    intensity: number | null;
    intensityUnit: Units | null;
    expTime: number | null;
    expTimeUnit: Units | null;
    expDose: number | null;
    expDoseUnit: Units | null;
    // SEMIFAB
    layerLabelsToExpose: string[] | null;
}

export const floodExposeDisplay: DisplayMap = {
    wavelength: {
        fieldType: DisplayFieldTypes.Dropdown,
        label: "Lamp wavelength",
        options: ["Select Wavelength","13.5nm (EUV)","193nm (DUV)","247nm (DUV)","365 nm (i-line)", "375nm", "405 nm (h-line)", "435 nm (g-line)"],
        order: 1,
        tabs: [Tab.EXPERIMENTAL],
    },
    expDose: {
        fieldType: DisplayFieldTypes.Input,
        label: "Exposure dose",
        placeholder: "Enter exposure dose",
        isNumber: true,
        units: [Units.MILLIJOULPERSQCM],
        order: 2,
        tabs: [Tab.EXPERIMENTAL],
    },
    intensity: {
        fieldType: DisplayFieldTypes.Input,
        label: "Lamp intensity",
        placeholder: "Enter lamp intensity",
        isNumber: true,
        units: [Units.MWPERSQCM],
        order: 3,
        tabs: [Tab.EXPERIMENTAL],
    },
    expTime: {
        fieldType: DisplayFieldTypes.Input,
        label: "Exposure time",
        placeholder: "Enter exposure time",
        isNumber: true,
        units: [Units.SEC,Units.MIN],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    layerLabelsToExpose: {
        fieldType: DisplayFieldTypes.MultiMaterialSelect,
        label: "Layer(s) to Expose",
        order: 0,
        materialFilter: "ONLY_RESIST",
        tabs: [Tab.SEMIFAB],
        infoContent: "Select which resists to expose. Only resists deposited using a 'Spin-Resist' step can be exposed. Leaving this empty will expose all resists that are present."
    },
    // DISPLAY
    // SEMIFAB
    
}

export const getFloodExpose = ({stepNumber}: BloxArgs): FloodExpose => ({
        name: `Flood Exposure ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.FloodExpose,
        wavelength: null,
        intensity: null,
        intensityUnit: Units.MWPERSQCM,
        expTime: null,
        expTimeUnit: Units.SEC,
        expDose: null,
        expDoseUnit: Units.MILLIJOULPERSQCM,
        commentField: null,
        customFields: {},
        //SEMIFAB
        layerLabelsToExpose: [],
    }
);

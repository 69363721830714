import { BaseBlox, BloxTypes,BloxArgs } from "./base-blox";
import { v4 as uuidv4 } from 'uuid';
import { DisplayMap } from "../display-mappings";
import { Tab, Units } from "../enums";
import { DisplayFieldTypes } from "../enums";
import { IColor } from "../../utils/Color";

export interface LPCVD extends BaseBlox {
    //EXPERIMENTAL
    material: string | null;
    thickness: number | null;
    thicknessUnit: Units | null;
    depRate: number | null;
    depRateUnit: Units | null;
    subTemp: number | null;
    subTempUnit: Units | null;
    showerTemp: number | null;
    showerTempUnit: Units | null;
    precursors: string | null;
    precursorFlow: string | null;
    chamberPressure: number | null;
    chamberPressureUnit: Units | null;
    // DISPLAY
    doubleSided: boolean | null;
    layerColor: IColor | null;
    layerThickness: number | null;
    sidewallThickness: number | null;
    layerLabel: string | null;
    // SEMIFAB
    isConformal: boolean | null;
    angularDist: number | null;
    angularDistUnit: Units | null;
    stepSize: number | null;
    stepSizeUnit: Units | null;
    surfaceInterp: number | null;
    surfaceInterpUnit: Units | null;
    targetAngle: number | null;
    targetAngleUnit: Units | null;
    isotropyRatio: number | null;
    maxGap: number | null;
    maxGapUnit: Units | null;
}

export const lpCVDDisplay: DisplayMap = {
    material: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposited Material",
        placeholder: "Enter material to deposit",
        order: 2,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    thickness: {
        fieldType: DisplayFieldTypes.Input,
        label: "Film thickness",
        placeholder: "Enter film thickness",
        isNumber: true,
        units: [Units.ANG,Units.NM,Units.MICRON],
        order: 3,
        tabs: [Tab.EXPERIMENTAL, Tab.SEMIFAB],
    },
    depRate: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Rate",
        placeholder: "Enter deposition rate",
        isNumber: true,
        units: [Units.ANGPERSEC,Units.NMPERSEC,Units.UMPERMIN],
        order: 4,
        tabs: [Tab.EXPERIMENTAL],
    },
    subTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Substrate Temperature",
        placeholder: "Enter substrate temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 7,
        tabs: [Tab.EXPERIMENTAL],
    },
    showerTemp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Showerhead Temperature",
        placeholder: "Enter showerhead temperature",
        isNumber: true,
        units: [Units.CELSIUS],
        order: 8,
        tabs: [Tab.EXPERIMENTAL],
    },
    chamberPressure: {
        fieldType: DisplayFieldTypes.Input,
        label: "Chamber Base Pressure",
        placeholder: "Enter chamber pressure",
        isNumber: true,
        units: [Units.TORR,Units.PASCAL,Units.MILLIBAR,Units.BAR],
        order: 9,
        tabs: [Tab.EXPERIMENTAL],
    },
    precursors: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursors",
        placeholder: "Enter precursor names",
        order: 10,
        tabs: [Tab.EXPERIMENTAL],
    },
    precursorFlow: {
        fieldType: DisplayFieldTypes.Input,
        label: "Precursor Flows",
        placeholder: "Enter all precursor flows",
        order: 11,
        tabs: [Tab.EXPERIMENTAL],
    },

    
    // DISPLAY
    doubleSided: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Two-Sided Deposition",
        order: 0,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerColor: {
        fieldType: DisplayFieldTypes.Color,
        label: "Layer Color",
        order: 4,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    layerThickness: {
        fieldType: DisplayFieldTypes.Slider,
        label: "Layer Display Thickness",
        order: 2,
        tabs: [Tab.DISPLAY],
    },
    sidewallThickness: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Sidewall Thickness",
        order: 3,
        tabs: [Tab.DISPLAY],
        defaultValue: 100
    },
    layerLabel: {
        fieldType: DisplayFieldTypes.Input,
        label: "Layer Label",
        order: 1,
        tabs: [Tab.DISPLAY],
    },
    // SEMIFAB
    isConformal: {
        fieldType: DisplayFieldTypes.Switch,
        label: "Conformal Deposition",
        order: 3,
        tabs: [Tab.DISPLAY, Tab.SEMIFAB],
    },
    angularDist: {
        fieldType: DisplayFieldTypes.Input,
        label: "Maximum Deposition Angle",
        placeholder: "Enter maximum deposition angle",
        isNumber: true,
        units: [Units.DEG],
        order: 7,
        tabs: [Tab.SEMIFAB],
    },
    stepSize: {
        fieldType: DisplayFieldTypes.Input,
        label: "Deposition Step Size",
        placeholder: "Enter deposition step size",
        isNumber: true,
        units: [Units.ANG, Units.NM, Units.MICRON],
        order: 7,
        tabs: [Tab.SEMIFAB],
    },
    surfaceInterp: {
        fieldType: DisplayFieldTypes.Input,
        label: "Surface Interpolation",
        placeholder: "Enter surface interpolation distance",
        isNumber: true,
        units: [Units.ANG, Units.NM, Units.MICRON],
        order: 7,
        tabs: [Tab.SEMIFAB],
    },
    targetAngle: {
        fieldType: DisplayFieldTypes.Input,
        label: "Target Angle",
        placeholder: "Enter target angle",
        isNumber: true,
        units: [Units.DEG],
        order: 7,
        tabs: [Tab.SEMIFAB],
    },
    isotropyRatio: {
        fieldType: DisplayFieldTypes.PercentageSlider,
        label: "Isotropy Ratio",
        order: 6,
        tabs: [Tab.SEMIFAB],
        defaultValue: 0
    },
    maxGap: {
        fieldType: DisplayFieldTypes.Input,
        label: "Maximum Gap to Fill",
        placeholder: "Enter the maximum gap to fill",
        isNumber: true,
        units: [Units.ANG, Units.NM, Units.MICRON],
        order: 7,
        tabs: [Tab.SEMIFAB],
    },
    // TODO: If conformal, use sidewall thickness slider
    // TODO: If not conformal, get angle, iso_ratio, max_gap, and target angle
    // TODO: Advanced tab: step, interp, target_angle,
}

export const getLPCVD = ({stepNumber}: BloxArgs): LPCVD => ({
        name: `LP-CVD ${stepNumber}`,
        id: uuidv4(),
        bloxType: BloxTypes.LPCVD,
        material: null,
        doubleSided: false,
        thickness: null,
        thicknessUnit: Units.ANG,
        depRate: null,
        depRateUnit: Units.ANGPERSEC,
        precursors: null,
        precursorFlow: null,
        subTemp: null,
        subTempUnit: Units.CELSIUS,
        showerTemp: null,
        showerTempUnit: Units.CELSIUS,
        chamberPressure: null,
        chamberPressureUnit: Units.TORR,
        commentField: null,
        customFields: {},
        layerColor: {R: 60, G: 75, B: 192, A: 1},
        layerThickness: 8,
        sidewallThickness: 60,
        layerLabel: null,
        isConformal: false,
        // SEMIFAB
        angularDist: 80,
        angularDistUnit: Units.DEG,
        stepSize: null,
        stepSizeUnit: Units.NM,
        surfaceInterp: null,
        surfaceInterpUnit: Units.NM,
        targetAngle: 0,
        targetAngleUnit: Units.DEG,
        isotropyRatio: 0,
        maxGap: null,
        maxGapUnit: Units.NM,
});

import React, { useMemo } from 'react';
import { ItemPredicate, ItemRenderer, ItemRendererProps, Select } from "@blueprintjs/select";
import { AllBloxes, BloxTypes, bloxNamesMap } from '../Data/BloxSchema/base-blox';
import { findAllCategoriesForBloxType } from '../Data/lib-category-mappings';
import { MenuItem } from '@blueprintjs/core';
import { useOwnedBloxes, useSharedBloxes } from '../hooks/DataFetching/use-fetch-blox';
import { Blox } from '../__generated__/Blox';
import { IconNames } from '@blueprintjs/icons';
import { useMyGroups } from '../hooks/DataFetching/use-fetch-groups';
import { Group } from '../__generated__/Group';
import { BloxInsertItem } from './BloxDraggable';

interface BloxItem {
  bloxType: BloxTypes;
  bloxName: string;
  categories: string;
  label?: string
  data?: any;
  bloxId?: string;
  source: 'template' | 'owned' | 'group' | 'special';
}

const getBloxItems = (): BloxItem[] => {
    const disallowedTypes = [
        BloxTypes.StartBlox,
        BloxTypes.SubstrateStack,
        // ... any other BloxTypes you want to exclude
    ];

    const filteredTypes = Object.values(BloxTypes).filter(type => !disallowedTypes.includes(type));
    const bloxItems: BloxItem[] = filteredTypes.map(type => {
        const categories = findAllCategoriesForBloxType(type);
        const bloxName = bloxNamesMap[type];
        return {bloxType: type, bloxName, categories: categories.join(', '), source: 'template' }
    });

    return bloxItems
}

const sourceOrder: {[key in BloxItem['source']]: number} = {
  'special': 1,
  'template': 2,
  'owned': 3,
  'group': 4,
};

const sortBloxItems = (a: BloxItem, b: BloxItem): number => {
  if (sourceOrder[a.source] !== sourceOrder[b.source]) {
    return sourceOrder[a.source] - sourceOrder[b.source];
  }
  return a.bloxName.localeCompare(b.bloxName);
};

const renderBloxItem: ItemRenderer<BloxItem> = (item: BloxItem, { handleClick, modifiers }: ItemRendererProps) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  let icon = undefined;
  if (item.source === 'owned') icon = IconNames.Person;
  if (item.source === 'group') icon = IconNames.People;

  let key = item.bloxId ?? item.bloxType;
  if (item.source === 'special') key = 'paste-key';

  return (

      <MenuItem
      className='popover-menu-item'
      icon={icon}
      key={key}
      onClick={handleClick}
      text={item.bloxName}
      label={item.label ?? item.categories}
      active={modifiers.active}
      disabled={modifiers.disabled}
      />    
  );
};

const filterBloxItem: ItemPredicate<BloxItem> = (query: string, item: BloxItem) => {
  const lowerQuery = query.toLowerCase();
  return (
    item.bloxType.toLowerCase().indexOf(lowerQuery) >= 0 ||
    item.categories.toLowerCase().indexOf(lowerQuery) >= 0 ||
    item.bloxName.toLowerCase().indexOf(lowerQuery) >= 0 ||
    !!(item.label && item.label.toLowerCase().indexOf(lowerQuery) >= 0)
  );
};

const toBloxItem = (source: 'owned' | 'group', myGroups?: Group[]) => (blox: Blox): BloxItem => {
  const bloxData = blox.bloxData;
  const bloxType = bloxData.bloxType as BloxTypes;
  const categories = findAllCategoriesForBloxType(bloxType).join(', ');
  const bloxName = blox.bloxName;
  bloxData.name = bloxName ?? ''; // TODO typing to prevent this
  let label;
  if (source === 'group' && myGroups) {
    const groupsToShow = blox.groups.filter(g => myGroups.some(myGroup => myGroup.id === g.id));
    const groupNames = groupsToShow.map(g => g.groupName);
    if (groupNames?.length > 0) {
      label = `(${groupNames.join(', ')})`;
    }
  }
  return { bloxType, bloxName, categories, data: bloxData, source, bloxId: blox.bloxId, label};
};

interface SelectBloxProps {
  handleSelectBlox: (item: BloxInsertItem) => void;
  children: React.ReactNode;
  copiedBlox: AllBloxes | null;
}

export const SelectBlox: React.FC<SelectBloxProps> = ({ children, handleSelectBlox, copiedBlox }) => {
  const { data: owned } = useOwnedBloxes();
  const { data: groupBlox } = useSharedBloxes();
  const { data: myGroups } = useMyGroups();

  const bloxItems = useMemo(() => {
    const dynamicItems: BloxItem[] = [
      ...(owned ? owned.map(toBloxItem('owned')) : []),
      ...(groupBlox ? groupBlox.map(toBloxItem('group', myGroups)) : []),
    ];
    const staticItems: BloxItem[] = getBloxItems();
    let copiedBloxItem;
    if (copiedBlox) {
      copiedBloxItem = { 
        bloxType: copiedBlox.bloxType, 
        bloxName: `Paste: ${copiedBlox.name}`, 
        categories: '', 
        source: 'special', 
        data: copiedBlox 
      } as BloxItem;
    } else {
      copiedBloxItem = { 
        bloxType: BloxTypes.StartBlox, 
        bloxName: '...right-click a blox to copy', 
        categories: '', 
        source: 'special' 
      } as BloxItem;
    }
    return [copiedBloxItem, ...staticItems, ...dynamicItems].sort(sortBloxItems);
  }, [owned, groupBlox, copiedBlox, myGroups]);

  const itemDisabledChecker = (item: BloxItem) => {
    return item.source === 'special' && !copiedBlox;
  }

  return (
      <Select<BloxItem>
        items={bloxItems}
        itemRenderer={renderBloxItem}
        itemPredicate={filterBloxItem}
        onItemSelect={(item: BloxItem) => handleSelectBlox({ data: item.data, bloxType: item.bloxType })}
        itemDisabled={itemDisabledChecker}
        filterable={true}
        popoverProps={{ minimal: true
         }}
        inputProps={{ placeholder: 'Filter owned, shared, and template Blox...' }}
      >
        {children}
      </Select>
  );
};
